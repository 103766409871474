import React, { useEffect, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import axios from "axios";

const PLACE_HOLDER_TEXT = "Content has not been defined.";

function HelpText({
  accessLevel,
  contentModule,
  contentType,
  contentArea,
  contentItem,
  className = "float-none blue",
}) {
  if (accessLevel === "Public") {
    return (
      <PublicHelpText
        contentArea={contentArea}
        contentItem={contentItem}
        contentModule={contentModule}
        className={className}
      />
    );
  } else {
    return (
      <PrivateHelpText
        contentModule={contentModule}
        contentArea={contentArea}
        contentItem={contentItem}
        className={className}
      />
    );
  }
}

function PublicHelpText({
  contentModule,
  contentArea,
  contentItem,
  className,
}) {
  const { t } = useTranslation();
  const [contentValue, setContentValue] = useState(PLACE_HOLDER_TEXT);

  useEffect(() => {
    if (contentModule && contentArea && contentItem) {
      axios
        .get(
          `/api/portal-content/${contentModule}/${contentArea}/${contentItem}/content-public`
        )
        .then(({ data: { contentValue } }) =>
          setContentValue(contentValue || PLACE_HOLDER_TEXT)
        )
        .catch(console.error);
    }
  }, [contentModule && contentArea, contentItem]);

  return (
    <Popup
      trigger={
        <Icon
          name="info circle"
          className={className}
          style={{ marginLeft: "5px", fontSize: "15px" }}
        />
      }
      content={t(contentValue)}
    />
  );
}

function PrivateHelpText({
  contentModule,
  contentArea,
  contentItem,
  className,
}) {
  const { t } = useTranslation();
  const [contentValue, setContentValue] = useState(PLACE_HOLDER_TEXT);

  useEffect(() => {
    if (contentModule && contentArea && contentItem) {
      axios
        .get(
          `/api/portal-content/${contentModule}/${contentArea}/${contentItem}/content`
        )
        .then(({ data: { contentValue } }) =>
          setContentValue(contentValue || PLACE_HOLDER_TEXT)
        )
        .catch(console.error);
    }
  }, [contentModule, contentArea, contentItem]);

  return (
    <Popup
      trigger={
        <Icon
          name="info circle"
          className={className}
          style={{ margin: "0 0.25rem", fontSize: "1.1rem" }}
        />
      }
      content={t(contentValue)}
    />
  );
}

const MemorisedHelpText = React.memo(HelpText);

export default MemorisedHelpText;
