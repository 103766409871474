import React, { useState, useEffect } from "react";
import { Button, Dropdown, Form, Icon, Modal } from "semantic-ui-react";
import { FormProvider, useForm, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DateInput, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { lookupToOptionsTranslation, yesNoOptions } from "../../utils";
import useLookup from "../../consumer/LookupConsumer";

function EstimateBulkEditModal(props) {
  const { t } = useTranslation();
  const configValues = props.configValues;
  const [formChanged, setFormChanged] = useState(false);
  const { lookups: stretchedClaim } = useLookup(6237);

  const [validationSchema, setValidationSchema] = useState(getValidationSchema(null, configValues));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    reset,
    control,
  } = methods;

  const stretchedInd = useWatch({
    control,
    name: "stretchedInd",
  });

  useEffect(() => {
    const updatedSchema = getValidationSchema(stretchedInd, configValues);
    setValidationSchema(updatedSchema);
    methods.reset({ ...methods.getValues() }); // Reset form to apply new schema
  }, [stretchedInd, configValues]);

  const onSubmit = (data) => {
    props.onUpdate(data);
    methods.reset();
    props.closeBulkEditModal();
  };

  const handleBackClick = () => {
    reset();
    props.closeBulkEditModal();
  };

  const handleFormChange = () => {
    if (!formChanged) {
      setFormChanged(true);
    }
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={props.closeBulkEditModal}
      className="dark-modal"
      size="tiny"
    >
      <Modal.Header>{t("Bulk Edit")}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>{t("Please enter values to be updated for selected children.")}</p>
        </Modal.Description>
        <br />
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Field>
              <label>{t("Stretched Funding")}</label>
              <Controller
                name="stretchedInd"
                control={methods.control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={stretchedClaim.map(lookupToOptionsTranslation(t))}
                    selection
                    onChange={(e, { value }) => {
                      field.onChange(value);
                      handleFormChange();
                    }}
                  />
                )}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("Expanded Hours")}</label>
              <Input name="expHrsPerWeek" {...register("expHrsPerWeek", { onChange: handleFormChange })} />
            </Form.Field>
            <Form.Field>
              <label>{t("Funded Hours")}</label>
              <Input name="uniHrsPerWeek" {...register("uniHrsPerWeek", { onChange: handleFormChange })} />
            </Form.Field>
            <Form.Field>
              <label>{t("Extended Hours")}</label>
              <Input name="extHrsPerWeek" {...register("extHrsPerWeek", { onChange: handleFormChange })} />
            </Form.Field>
            <Form.Field>
              <label>{t("Funding Start Date")}</label>
              <DateInput name="childFundStart" {...register("childFundStart", { onChange: handleFormChange })} />
            </Form.Field>
            <Form.Field>
              <label>{t("Funding End Date")}</label>
              <DateInput name="childFundEnd" {...register("childFundEnd", { onChange: handleFormChange })} />
            </Form.Field>
            <Form.Field>
              <label>{t("Include")}</label>
              <Controller
                name="includeChild"
                control={methods.control}
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    options={yesNoOptions}
                    selection
                    onChange={(e, { value }) => {
                      field.onChange(value);
                      handleFormChange();
                    }}
                  />
                )}
              />
            </Form.Field>

            <div style={{ textAlign: "left" }}>
              <Button onClick={handleBackClick} className="orange">
                <Icon name="arrow left circle" /> {t("Back")}
              </Button>
              &nbsp;&nbsp;
              <Button type="submit" className="green" disabled={!isDirty || !formChanged}>
                <Icon name="check circle" />
                {t("Update")}
              </Button>
            </div>
          </Form>
        </FormProvider>
      </Modal.Content>
    </Modal>
  );
}

function getValidationSchema(stretchedInd, configValues) {
  const stretchFundHours = configValues?.stretchFundList?.find(stretchFund => stretchFund.stretchedFund === stretchedInd)?.hours;
  const configExpHrsPerWeek = stretchFundHours ? stretchFundHours : configValues?.expHrsPerWeek;
  const configExtHrsPerWeek = stretchFundHours ? stretchFundHours : configValues?.extHrsPerWeek;
  const configUniHrsPerWeek = stretchFundHours ? stretchFundHours : configValues?.uniHrsPerWeek;

  return yup.object().shape({
    expHrsPerWeek: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Please enter a positive number")
      .max(
        configExpHrsPerWeek,
        `Value must not exceed ${configExpHrsPerWeek}`
      )
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      ), // Convert empty strings to undefined
    uniHrsPerWeek: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Please enter a positive number")
      .max(
        configUniHrsPerWeek,
        `Value must not exceed ${configUniHrsPerWeek}`
      )
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      ), // Convert empty strings to undefined
    extHrsPerWeek: yup
      .number()
      .typeError("Please enter a valid number")
      .min(0, "Please enter a positive number")
      .max(
        configExtHrsPerWeek,
        `Value must not exceed ${configExtHrsPerWeek}`
      )
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value
      ), // Convert empty strings to undefined
    childFundStart: yup
      .date()
      .nullable()
      .transform((value, originalValue) => originalValue === "" ? null : value),
    childFundEnd: yup
      .date()
      .nullable()
      .transform((value, originalValue) => originalValue === "" ? null : value)
      .max(new Date(), "Funding End Date cannot be in the future.")
      .test(
        "is-greater",
        "Funding Start Date must be prior to Funding End Date",
        function(value) {
          const { childFundStart } = this.parent;
          return !childFundStart || !value || new Date(value) > new Date(childFundStart);
        }
      ),
  });
}


export default EstimateBulkEditModal;
