import { useEffect, useState } from "react";
import { Button, Form, Icon, Menu, Segment } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import {
  useHistory,
  useParams,
  withRouter,
} from "react-router-dom";
import { lookupToOptionsTranslation } from "../../utils";
import { useTranslation } from "react-i18next";
import useLookup from "../../consumer/LookupConsumer";
import { DateInput, Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "../../../src/components/UserAccessComponent";
import moment from "moment";

const searchAddressValidations = yup.object({
  postcode: yup
    .string()
    .required("Please enter a valid postcode")
    .test("postcode", "Please enter a valid postcode", (value) =>
      /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/.test(value)
    ),
});

function SearchAddress({ setAddresses }) {
  const { t } = useTranslation();
  const searchCAVForm = useForm({
    resolver: yupResolver(searchAddressValidations),
  });

  const [isSearchingCAV, setIsSearchingCAV] = useState(false);

  const searchCAV = ({ postcode }) => {
    setIsSearchingCAV(true);
    axios
      .get("/api/cav", { params: { postcode } })
      .then(({ data }) => {
        setIsSearchingCAV(false);
        setAddresses(data);
      })
      .catch((error) => {
        setIsSearchingCAV(false);
        console.error(error);
      });
  };

  return (
    <FormProvider {...searchCAVForm}>
      <Form
        onSubmit={searchCAVForm.handleSubmit(searchCAV)}
        style={{ marginBottom: 14 }}
      >
        <Input name="postcode" label={t("Postcode")} />
        <Button
          style={{
            backgroundColor: "#00AEEF",
            color: "#fff",
            textShadow: "none",
          }}
          disabled={isSearchingCAV}
          loading={isSearchingCAV}
        >
          <Icon name="search" />
          {t("Find Address")}
        </Button>
      </Form>
    </FormProvider>
  );
}

function EYFChildDetails({setChildDob,setEySenCode,activeTab}) {
  const { t } = useTranslation();
  const { lookups: eyDateofBirthProof } = useLookup(6199);
  const { lookups: genders } = useLookup(6187);
  const { lookups: languages } = useLookup(102);
  const { lookups: ethnicities } = useLookup(101);
  const { lookups: ethnicitySources } = useLookup(125);
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();
  const history = useHistory();
  const { eypChildId } = useParams();
  const { providerId } = useParams();

  useEffect(() => {
    if (eypChildId === "new" || eypChildId.includes("sib")) {
      methods.setValue("childNeverAttendInd", "N");
      if (!methods.getValues()?.ethnicity)
        methods.setValue("ethnicity", "NOBT");
    }
  },[]);

  const [addresses, setAddresses] = useState([]);

  const yesNoOptions = [
    { value: "Y", text: t("Yes") },
    { value: "N", text: t("No") },
  ];

  const schema = yup.object({
    forename: yup
      .string()
      .required(t("Please enter a value"))
      .max(40, "Character limit of 40 exceeded"),
    middleName: yup
      .string()
      .nullable()
      .max(40, "Character limit of 40 exceeded"),
    surname: yup
      .string()
      .required(t("Please enter a value"))
      .max(30, "Character limit of 30 exceeded"),
    dob: yup.date().required(t("Please enter a value")),
    dobConfInd: yup.string().required(t("Please enter a value")),
    docSeenCode: yup.string().when("dobConfInd", {
      is: (dobConfInd) => {
        return "N" !== dobConfInd;
      },
      then: yup.string().required(t("Please enter a value")),
    }),
    proofSeenDate: yup.string().when("dobConfInd", {
      is: (dobConfInd) => {
        return "N" !== dobConfInd;
      },
      then: yup.string().required(t("Please enter a value")),
    }),
    address: yup.string().required(t("Please enter a value")),
    sex: yup.string().required(t("Please enter a value")),
    ethnicity: yup.string().required(t("Please enter a value")),
    ethnicitySource: yup.string().when("ethnicity", {
      is: (ethnicity) => {
        return "NOBT" !== ethnicity;
      },
      then: yup.string().required(t("Please enter a value")),
    }),
    childStartDate: yup
      .date()
      .test(
        "date_before_test",
        "Provider start date cannot be before birthdate.",
        function (value) {
          const { dob } = this.parent;
          return dob
            ? moment(value).isSameOrAfter(moment(dob).format("YYYY-MM-DD"))
            : true;
        }
      )
      .required(t("Please enter a value")),
    childEndDate: yup
      .date()
      .nullable()
      .test(
        "date_before_test",
        "Provider Leave date cannot be before Start Date.",
        function (value) {
          const { childStartDate } = this.parent;
          return (
            !value ||
            (childStartDate && moment(value).isSameOrAfter(moment(childStartDate)))
          );
        }
      ),
    childNeverAttendInd: yup.string().required(t("Please enter a value")),
    childFundStart: yup
        .date()
        .nullable()
        .default(undefined)
        .notRequired(),
    childFundEnd: yup
        .date()
        .nullable()
        .default(undefined)
        .notRequired()
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (eypChildId !== "new" || eypChildId.includes("sib")) {
      getEypChild(eypChildId);
    }
  }, [eypChildId]);

  const getEypChild = () => {
    const cleanedChildId = eypChildId.replace("sib", "");
    axios
      .get(`/api/eyp-child/${cleanedChildId}`)
      .then((response) => {
        setChildDob(response.data.dob);
        setEySenCode(response.data.eySenCode);
        if (eypChildId.includes("sib")) {
          methods.setValue(`address`, response.data.uprn);
          setAddresses([
            {
              uprn: response.data.uprn,
              formatted: response.data.formattedAddress,
            },
          ]);
        } else {
          methods.setValue(`forename`, response.data.forename);
          methods.setValue(`middleName`, response.data.middleName);
          methods.setValue(`surname`, response.data.surname);
          methods.setValue(`dob`, response.data.dob);
          methods.setValue(
            `docSeenCode`,
            response.data.docSeenCode ? response.data.docSeenCode : ""
          );
          methods.setValue(
            `proofSeenDate`,
            response.data.proofSeenDate ? response.data.proofSeenDate : ""
          );
          methods.setValue(`dobConfInd`, response.data.dobConfInd);
          methods.setValue(`sex`, response.data.sex);
          methods.setValue(`ethnicity`, response.data.ethnicity);
          methods.setValue(
            `ethnicitySource`,
            response.data.ethnicity !== "NOBT"
              ? response.data.ethnicitySource
              : "O"
          );
          methods.setValue(`language`, response.data.language);
          methods.setValue(`eySenCode`, response.data.eySenCode);
          methods.setValue(`childStartDate`, response.data.childStartDate);
          methods.setValue(
            `childEndDate`,
            response.data.childEndDate ? response.data.childEndDate : undefined
          );
          methods.setValue(
              `childFundStart`,
              response.data.childFundStart ? response.data.childFundStart : undefined
          );
          methods.setValue(
              `childFundEnd`,
              response.data.childFundEnd ? response.data.childFundEnd : undefined
          );
          methods.setValue(`address`, response.data.uprn);
          methods.setValue(`childMatchStatus`, response.data.childMatchStatus);
          methods.setValue(
            `childNeverAttendInd`,
            response.data.childNeverAttendInd
          );
          setAddresses([
            {
              uprn: response.data.uprn,
              formatted: response.data.formattedAddress,
            },
          ]);
        }
      })
      .catch(console.log);
  };

  const onSubmit = ({
    forename,
    middleName,
    surname,
    dob,
    dobConfInd,
    docSeenCode,
    proofSeenDate,
    address,
    sex,
    language,
    ethnicity,
    ethnicitySource,
    eySenCode,
    childStartDate,
    childEndDate,
    childFundStart,
    childFundEnd,
    childNeverAttendInd,
    childMatchStatus,
  }) => {
    const postData = {
      forename,
      middleName,
      surname,
      dob,
      dobConfInd,
      docSeenCode: dobConfInd !== "N" ? docSeenCode : null,
      proofSeenDate: dobConfInd !== "N" ? proofSeenDate : null,
      uprn: address,
      sex,
      language,
      ethnicity,
      ethnicitySource,
      eySenCode,
      childStartDate,
      childEndDate,
      childFundStart,
      childFundEnd,
      childNeverAttendInd,
      providerId,
      childMatchStatus,
    };

    const putData = [
      { op: "add", path: "/forename", value: forename },
      { op: "add", path: "/middleName", value: middleName },
      { op: "add", path: "/surname", value: surname },
      { op: "add", path: "/dob", value: dob },
      { op: "add", path: "/dobConfInd", value: dobConfInd },
      {
        op: "add",
        path: "/docSeenCode",
        value: dobConfInd !== "N" ? docSeenCode : null,
      },
      {
        op: "add",
        path: "/proofSeenDate",
        value: dobConfInd !== "N" ? proofSeenDate : null,
      },
      { op: "add", path: "/uprn", value: address },
      { op: "add", path: "/sex", value: sex },
      { op: "add", path: "/language", value: language },
      { op: "add", path: "/ethnicity", value: ethnicity },
      { op: "add", path: "/ethnicitySource", value: ethnicitySource },
      { op: "add", path: "/eySenCode", value: eySenCode },
      { op: "add", path: "/childStartDate", value: childStartDate },
      {
        op: "add",
        path: "/childEndDate",
        value: childEndDate ? childEndDate : null,
      },
      { op: "add", path: "/childFundStart", value: childFundStart ? childFundStart : null },
      {
        op: "add",
        path: "/childFundEnd",
        value: childFundEnd ? childFundEnd : null,
      },
      { op: "add", path: "/childNeverAttendInd", value: childNeverAttendInd },
      {
        op: "add",
        path: "/childMatchStatus",
        value: childMatchStatus !== "PEND" ? childMatchStatus : "AMD",
      },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    setSaving(true);

    if (eypChildId === "new") {
      axios
        .post(`/api/eyp-child`, postData)
        .then(({ data }) => {
          history.push(
            `/portal/${providerId}/provider/provider-funding/${activeTab}/${data.id}`
          );
          setSaving(false);
          addToast(t("Child has been successfully created"), {
            appearance: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          addToast(t("Child has not been created"), { appearance: "error" });
          setSaving(false);
        });
    } else if (eypChildId.includes("sib")) {
      const cleanedChildId = eypChildId.replace("sib", "");
      axios
        .post(`/api/eyp-child/sibling/${cleanedChildId}`, postData)
        .then(({ data }) => {
          history.push(
            `/portal/${providerId}/provider/provider-funding/${activeTab}/${data.id}`
          );
          setSaving(false);
          addToast(t("Child has been successfully created"), {
            appearance: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          addToast(t("Child has not been created"), { appearance: "error" });
          setSaving(false);
        });
    } else {
      axios
        .put(`/api/eyp-child/${eypChildId}`, putData, putObject)
        .then(({ data }) => {
          addToast(t("Child has been successfully updated"), {
            appearance: "success",
          });
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
          addToast(t("Child has not been updated"), { appearance: "error" });
          setSaving(false);
        });
    }
  };

  return (
    <div>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          <Icon name="child" />
          {t("Child Details")}
        </Menu.Item>
      </Menu>
      <Segment attached="bottom">
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Input
              id="eyfChildForename"
              label={t("First Name")}
              name="forename"
            />
            <Input
              id="eyfChildMiddleName"
              label={t("Middle Name (s)")}
              name="middleName"
            />
            <Input id="eyfChildSurname" label={t("Surname")} name="surname" />
            <DateInput id="eyfChildDob" name="dob" label={t("Child DOB")} />
            <Dropdown
              id="eyfChildDobConfInd"
              label={t("Proof of DOB seen?")}
              name="dobConfInd"
              options={yesNoOptions}
            />
            {methods.watch("dobConfInd") &&
              methods.watch("dobConfInd") !== "N" && (
                <Dropdown
                  id="eyfChildDocSeenCode"
                  label={t("Document Seen")}
                  name="docSeenCode"
                  options={eyDateofBirthProof.map(
                    lookupToOptionsTranslation(t)
                  )}
                />
              )}
            {methods.watch("dobConfInd") &&
              methods.watch("dobConfInd") !== "N" && (
                <DateInput
                  id="eyfChildProofSeenDate"
                  name="proofSeenDate"
                  label={t("Date proof of DOB seen")}
                />
              )}
            <Dropdown
              id="eyfChildSex"
              label={t("Sex")}
              name="sex"
              options={genders.map(lookupToOptionsTranslation(t))}
            />
          </Form>
        </FormProvider>
        <br />
        <SearchAddress setAddresses={setAddresses} />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Dropdown
              id="eyfChildAddress"
              name="address"
              label={t("Address")}
              options={addresses.map(({ formatted, uprn }) => ({
                value: uprn,
                text: formatted,
              }))}
            />
            <button type="submit" hidden />
          </Form>
        </FormProvider>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Dropdown
              id="eyfChildEthnicity"
              label={t("Ethnicity")}
              name="ethnicity"
              options={ethnicities.map(lookupToOptionsTranslation(t))}
            />
            {methods.watch("ethnicity") &&
              methods.watch("ethnicity") !== "NOBT" && (
                <Dropdown
                  id="eyfChildEthnicitySource"
                  label={t("Ethnicity Source")}
                  name="ethnicitySource"
                  options={ethnicitySources.map(lookupToOptionsTranslation(t))}
                />
              )}
            <Dropdown
              id="eyfChildLanguage"
              label={t("Language")}
              name="language"
              options={languages.map(lookupToOptionsTranslation(t))}
            />
            <Dropdown
              id="eyfChildEySenCode"
              label={t("SEN Support")}
              name="eySenCode"
              options={yesNoOptions}
              cb={(val) => {
                setEySenCode(val);
              }}
            />
            <DateInput
              id="eyfChildChildStartDate"
              name="childStartDate"
              label={t("Provider Start Date")}
            />
            <DateInput
              id="eyfChildChildEndate"
              name="childEndDate"
              label={t("Provider Leave Date")}
            />
            <DateInput
                id="eyfChildChildFundStart"
                name="childFundStart"
                label={t("Funding Start Date")}
            />
            <DateInput
                id="eyfChildChildFundEnd"
                name="childFundEnd"
                label={t("Funding End Date")}
            />
            <Dropdown
              id="eyfChildChildNeverAttendInd"
              label={t("Never Attended")}
              name="childNeverAttendInd"
              options={yesNoOptions}
              defaultValue={"N"}
            />
            <br />
            <Button
              id="eyfChildBackBtn"
              type="button"
              onClick={() => {
                history.push(`/portal/${providerId}/provider/provider-funding/${activeTab}/`);
              }}
              className={"orange"}
            >
              <Icon name="arrow circle left" />
              {t("Back")}
            </Button>
            <UserAccessComponent requires="PPORT_EYF_CHILD_D_SUB">
              <Button
                id="eyfChildSubmitBtn"
                className={"green"}
                type="submit"
                disabled={saving}
              >
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </div>
  );
}

export default withRouter(EYFChildDetails);
