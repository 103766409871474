export const requestProviders = "REQUEST_PROVIDERS";
export const requestPerson = "REQUEST_PERSON";
export const requestUserDetails = "REQUEST_USER_DETAILS";
export const receiveProviders = "RECEIVE_PROVIDERS";
export const receivePerson = "RECEIVE_PERSON";
export const receiveSelectedProvider = "RECEIVE_SELECTED_PROVIDER";
export const receiveUserDetails = "RECEIVE_USER_DETAILS";
export const updateProvider = "UPDATE_PROVIDER";
export const requestSyncTrackRecords = "REQUEST_SYNC_TRACK_RECORDS";
export const receiveSyncTrackRecords = "RECEIVE_SYNC_TRACK_RECORDS";

export const initialValue = {
  loadingProviders: false,
  loadingPerson: false,
  loadingUserDetails: false,
  loadingSyncTrackRecords: false,
  providers: [],
  selectedProvider: {},
  person: {},
  userDetails: {},
  syncTrackRecords: [],
};

function sessionReducer(state, action) {
  switch (action.type) {
    case requestProviders:
      return { ...state, loadingProviders: true };
    case requestPerson:
      return { ...state, loadingPerson: true };
    case requestUserDetails:
      return { ...state, loadingUserDetails: true };
    case receiveProviders:
      return { ...state, providers: action.providers, loadingProviders: false };
    case receivePerson:
      return { ...state, person: action.person, loadingPerson: false };
    case receiveSelectedProvider:
      return { ...state, selectedProvider: action.provider };
    case receiveUserDetails:
      return { ...state, userDetails: action.userDetails, loadingUserDetails: false };
    case requestSyncTrackRecords:
      return { ...state, loadingSyncTrackRecords: true };
    case receiveSyncTrackRecords:
      return { ...state, syncTrackRecords: action.syncTrackRecords, loadingSyncTrackRecords: false };
    case updateProvider:
      const { providers } = state;
      let providerIndex = providers.findIndex((p) => p.providerId === action.provider.providerId);
      providers.splice(providerIndex, 1, action.provider);
      return { ...state, providers: [...providers], selectedProvider: action.provider };
    default:
      return state;
  }
}

export default sessionReducer;
