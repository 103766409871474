import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Form, Grid, Icon, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Dropdown, Input } from "@caci/react-form-fields";
import { useToasts } from "react-toast-notifications";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import RejectCommentsModal from "./RejectCommentsModal";
import UserAccessComponent from "./UserAccessComponent";
import { createSyncTrackPutObject, getValidationFlag, setValueHelper } from "../utils";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import useGridRows from "../hooks/useGridRows";

moment.locale("en-gb");

const copyData = [
  {
    startAm: "tueStartAM",
    endAm: "tueEndAM",
    startPm: "tueStartPM",
    endPm: "tueEndPM"
  },
  {
    startAm: "wedStartAM",
    endAm: "wedEndAM",
    startPm: "wedStartPM",
    endPm: "wedEndPM"
  },
  {
    startAm: "thuStartAM",
    endAm: "thuEndAM",
    startPm: "thuStartPM",
    endPm: "thuEndPM"
  },
  {
    startAm: "friStartAM",
    endAm: "friEndAM",
    startPm: "friStartPM",
    endPm: "friEndPM"
  },
  {
    startAm: "satStartAM",
    endAm: "satEndAM",
    startPm: "satStartPM",
    endPm: "satEndPM"
  },
  {
    startAm: "sunStartAM",
    endAm: "sunEndAM",
    startPm: "sunStartPM",
    endPm: "sunEndPM"
  }
];

const timeDifference = (start, end) => {
  // set second and millisecond to zero to avoid 1 min drop.
  const startDate = moment().set({
    hour: start.split(":")[0],
    minute: start.split(":")[1],
    second: 0,
    millisecond: 0
  });
  const endDate = moment().set({
    hour: end.split(":")[0],
    minute: end.split(":")[1],
    second: 0,
    millisecond: 0
  });
  return Math.abs(startDate.diff(endDate, "minute"));
};

const validationFlagsList = ["1", "2", "3"];

function Hours({ holidayId, setValidationFlag, urlSlug }) {
  const { row, setRow } = useGridRows();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const [isCalculate, setCalculate] = useState(false);
  const methods = useForm();

  const handleDelete = id => {
    const data = [
      { op: "add", path: "/validationFlag", value: "D" },
      { op: "add", path: "/hoursOpen", value: 0 },
    ];
    const putObject = createSyncTrackPutObject(row.trackId, getValidationFlag(row.validationFlag, "D"));
    axios
      .put(`/api/pport-early-hours/${id}`, data, putObject)
      .then(({ data }) => {
        addToast(`${t("Hours")} ${t("has been successfully removed")}`, {
          appearance: "success"
        });
        setRow({})
        setValueHelper(
          Object.entries(data).reduce(
            (acc, [key, value]) => ({
              ...acc,
              [key]: key === "hoursId" ? value : ""
            }),
            {}
          ),
          methods.setValue
        );
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Hours")} ${t("has been successfully removed")}`, {
          appearance: "error"
        });
      });
  };

  const dateFormat = (hour, minute) => {
    return moment().set({
      hour: hour,
      minute: minute,
      second: 0,
      millisecond: 0
    });
  };

  const onSubmit = ({
    hoursId,
    hoursOpen,
    weeksOpen,
    fundedWeeks,
    censusWeek,
    monStartAM,
    monEndAM,
    monStartPM,
    monEndPM,
    tueStartAM,
    tueEndAM,
    tueStartPM,
    tueEndPM,
    wedStartAM,
    wedEndAM,
    wedStartPM,
    wedEndPM,
    thuStartAM,
    thuEndAM,
    thuStartPM,
    thuEndPM,
    friStartAM,
    friEndAM,
    friStartPM,
    friEndPM,
    satStartAM,
    satEndAM,
    satStartPM,
    satEndPM,
    sunStartAM,
    sunEndAM,
    sunStartPM,
    sunEndPM,
    trackId,
    validationFlag
  }) => {
    const data = {
      hoursId: hoursId,
      holidayId: holidayId.holidayId,
      hoursOpen: parseFloat(hoursOpen) || "0.00",
      weeksOpen: weeksOpen || "",
      fundedWeeks: fundedWeeks || "",
      censusWeek: censusWeek || "",
      monStartAM: monStartAM
        ? dateFormat(monStartAM.split(":")[0], monStartAM.split(":")[1])
        : null,
      monEndAM: monEndAM
        ? dateFormat(monEndAM.split(":")[0], monEndAM.split(":")[1])
        : null,
      monStartPM: monStartPM
        ? dateFormat(monStartPM.split(":")[0], monStartPM.split(":")[1])
        : null,
      monEndPM: monEndPM
        ? dateFormat(monEndPM.split(":")[0], monEndPM.split(":")[1])
        : null,
      tueStartAM: tueStartAM
        ? dateFormat(tueStartAM.split(":")[0], tueStartAM.split(":")[1])
        : null,
      tueEndAM: tueEndAM
        ? dateFormat(tueEndAM.split(":")[0], tueEndAM.split(":")[1])
        : null,
      tueStartPM: tueStartPM
        ? dateFormat(tueStartPM.split(":")[0], tueStartPM.split(":")[1])
        : null,
      tueEndPM: tueEndPM
        ? dateFormat(tueEndPM.split(":")[0], tueEndPM.split(":")[1])
        : null,
      wedStartAM: wedStartAM
        ? dateFormat(wedStartAM.split(":")[0], wedStartAM.split(":")[1])
        : null,
      wedEndAM: wedEndAM
        ? dateFormat(wedEndAM.split(":")[0], wedEndAM.split(":")[1])
        : null,
      wedStartPM: wedStartPM
        ? dateFormat(wedStartPM.split(":")[0], wedStartPM.split(":")[1])
        : null,
      wedEndPM: wedEndPM
        ? dateFormat(wedEndPM.split(":")[0], wedEndPM.split(":")[1])
        : null,
      thuStartAM: thuStartAM
        ? dateFormat(thuStartAM.split(":")[0], thuStartAM.split(":")[1])
        : null,
      thuEndAM: thuEndAM
        ? dateFormat(thuEndAM.split(":")[0], thuEndAM.split(":")[1])
        : null,
      thuStartPM: thuStartPM
        ? dateFormat(thuStartPM.split(":")[0], thuStartPM.split(":")[1])
        : null,
      thuEndPM: thuEndPM
        ? dateFormat(thuEndPM.split(":")[0], thuEndPM.split(":")[1])
        : null,
      friStartAM: friStartAM
        ? dateFormat(friStartAM.split(":")[0], friStartAM.split(":")[1])
        : null,
      friEndAM: friEndAM
        ? dateFormat(friEndAM.split(":")[0], friEndAM.split(":")[1])
        : null,
      friStartPM: friStartPM
        ? dateFormat(friStartPM.split(":")[0], friStartPM.split(":")[1])
        : null,
      friEndPM: friEndPM
        ? dateFormat(friEndPM.split(":")[0], friEndPM.split(":")[1])
        : null,
      satStartAM: satStartAM
        ? dateFormat(satStartAM.split(":")[0], satStartAM.split(":")[1])
        : null,
      satEndAM: satEndAM
        ? dateFormat(satEndAM.split(":")[0], satEndAM.split(":")[1])
        : null,
      satStartPM: satStartPM
        ? dateFormat(satStartPM.split(":")[0], satStartPM.split(":")[1])
        : null,
      satEndPM: satEndPM
        ? dateFormat(satEndPM.split(":")[0], satEndPM.split(":")[1])
        : null,
      sunStartAM: sunStartAM
        ? dateFormat(sunStartAM.split(":")[0], sunStartAM.split(":")[1])
        : null,
      sunEndAM: sunEndAM
        ? dateFormat(sunEndAM.split(":")[0], sunEndAM.split(":")[1])
        : null,
      sunStartPM: sunStartPM
        ? dateFormat(sunStartPM.split(":")[0], sunStartPM.split(":")[1])
        : null,
      sunEndPM: sunEndPM
        ? dateFormat(sunEndPM.split(":")[0], sunEndPM.split(":")[1])
        : null,
      validationFlag: "I"
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      {
        op: "add",
        path: "/monStartAM",
        value: monStartAM
          ? dateFormat(monStartAM.split(":")[0], monStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/monEndAM",
        value: monEndAM
          ? dateFormat(monEndAM.split(":")[0], monEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/monStartPM",
        value: monStartPM
          ? dateFormat(monStartPM.split(":")[0], monStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/monEndPM",
        value: monEndPM
          ? dateFormat(monEndPM.split(":")[0], monEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/tueStartAM",
        value: tueStartAM
          ? dateFormat(tueStartAM.split(":")[0], tueStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/tueEndAM",
        value: tueEndAM
          ? dateFormat(tueEndAM.split(":")[0], tueEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/tueStartPM",
        value: tueStartPM
          ? dateFormat(tueStartPM.split(":")[0], tueStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/tueEndPM",
        value: tueEndPM
          ? dateFormat(tueEndPM.split(":")[0], tueEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/wedStartAM",
        value: wedStartAM
          ? dateFormat(wedStartAM.split(":")[0], wedStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/wedEndAM",
        value: wedEndAM
          ? dateFormat(wedEndAM.split(":")[0], wedEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/wedStartPM",
        value: wedStartPM
          ? dateFormat(wedStartPM.split(":")[0], wedStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/wedEndPM",
        value: wedEndPM
          ? dateFormat(wedEndPM.split(":")[0], wedEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/thuStartAM",
        value: thuStartAM
          ? dateFormat(thuStartAM.split(":")[0], thuStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/thuEndAM",
        value: thuEndAM
          ? dateFormat(thuEndAM.split(":")[0], thuEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/thuStartPM",
        value: thuStartPM
          ? dateFormat(thuStartPM.split(":")[0], thuStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/thuEndPM",
        value: thuEndPM
          ? dateFormat(thuEndPM.split(":")[0], thuEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/friStartAM",
        value: friStartAM
          ? dateFormat(friStartAM.split(":")[0], friStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/friEndAM",
        value: friEndAM
          ? dateFormat(friEndAM.split(":")[0], friEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/friStartPM",
        value: friStartPM
          ? dateFormat(friStartPM.split(":")[0], friStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/friEndPM",
        value: friEndPM
          ? dateFormat(friEndPM.split(":")[0], friEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/satStartAM",
        value: satStartAM
          ? dateFormat(satStartAM.split(":")[0], satStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/satEndAM",
        value: satEndAM
          ? dateFormat(satEndAM.split(":")[0], satEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/satStartPM",
        value: satStartPM
          ? dateFormat(satStartPM.split(":")[0], satStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/satEndPM",
        value: satEndPM
          ? dateFormat(satEndPM.split(":")[0], satEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/sunStartAM",
        value: sunStartAM
          ? dateFormat(sunStartAM.split(":")[0], sunStartAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/sunEndAM",
        value: sunEndAM
          ? dateFormat(sunEndAM.split(":")[0], sunEndAM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/sunStartPM",
        value: sunStartPM
          ? dateFormat(sunStartPM.split(":")[0], sunStartPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/sunEndPM",
        value: sunEndPM
          ? dateFormat(sunEndPM.split(":")[0], sunEndPM.split(":")[1])
          : null
      },
      {
        op: "add",
        path: "/hoursOpen",
        value: parseFloat(hoursOpen) || 0
      },
      {
        op: "add",
        path: "/weeksOpen",
        value: weeksOpen
      },
      {
        op: "add",
        path: "/fundedWeeks",
        value: fundedWeeks
      },
      {
        op: "add",
        path: "/censusWeek",
        value: censusWeek
      }
    ];
    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));
    if (hoursId) {
      axios
        .put(`/api/pport-early-hours/${hoursId}`, put, putObject)
        .then(() => {
          addToast(`${t("Hours")} ${t("has been successfully updated")}`, {
            appearance: "success"
          });
          methods.setValue("hoursId", data.hoursId);
          setRow(data);
          fetchEarlyHour();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-hours`, data)
        .then(({ data }) => {
          addToast(`${t("Hours")} ${t("has been successfully created")}`, {
            appearance: "success"
          });
          methods.setValue("hoursId", data.hoursId);
          setRow(data);
          fetchEarlyHour();
        })
        .catch(console.log);
    }
  };

  const calculateHoursOpen = () => {
    let totalTimeInMinutes = 0;
    const {
      monStartAM,
      monEndAM,
      monStartPM,
      monEndPM,
      tueStartAM,
      tueEndAM,
      tueStartPM,
      tueEndPM,
      wedStartAM,
      wedEndAM,
      wedStartPM,
      wedEndPM,
      thuStartAM,
      thuEndAM,
      thuStartPM,
      thuEndPM,
      friStartAM,
      friEndAM,
      friStartPM,
      friEndPM,
      satStartAM,
      satEndAM,
      satStartPM,
      satEndPM,
      sunStartAM,
      sunEndAM,
      sunStartPM,
      sunEndPM,
      hoursOpen
    } = methods.getValues();
    if (monStartAM && monEndAM) {
      totalTimeInMinutes += timeDifference(monStartAM, monEndAM);
    }
    if (monStartPM && monEndPM) {
      totalTimeInMinutes += timeDifference(monStartPM, monEndPM);
    }
    if (tueStartAM && tueEndAM) {
      totalTimeInMinutes += timeDifference(tueStartAM, tueEndAM);
    }
    if (tueStartPM && tueEndPM) {
      totalTimeInMinutes += timeDifference(tueStartPM, tueEndPM);
    }
    if (wedStartAM && wedEndAM) {
      totalTimeInMinutes += timeDifference(wedStartAM, wedEndAM);
    }
    if (wedStartPM && wedEndPM) {
      totalTimeInMinutes += timeDifference(wedStartPM, wedEndPM);
    }
    if (thuStartAM && thuEndAM) {
      totalTimeInMinutes += timeDifference(thuStartAM, thuEndAM);
    }
    if (thuStartPM && thuEndPM) {
      totalTimeInMinutes += timeDifference(thuStartPM, thuEndPM);
    }
    if (friStartAM && friEndAM) {
      totalTimeInMinutes += timeDifference(friStartAM, friEndAM);
    }
    if (friStartPM && friEndPM) {
      totalTimeInMinutes += timeDifference(friStartPM, friEndPM);
    }
    if (satStartAM && satEndAM) {
      totalTimeInMinutes += timeDifference(satStartAM, satEndAM);
    }
    if (satStartPM && satEndPM) {
      totalTimeInMinutes += timeDifference(satStartPM, satEndPM);
    }
    if (sunStartAM && sunEndAM) {
      totalTimeInMinutes += timeDifference(sunStartAM, sunEndAM);
    }
    if (sunStartPM && sunEndPM) {
      totalTimeInMinutes += timeDifference(sunStartPM, sunEndPM);
    }

    const totalTimeInHours = (totalTimeInMinutes / 60).toFixed(2);
    if (hoursOpen !== totalTimeInHours) {
      methods.setValue("hoursOpen", totalTimeInHours);
    }
  };

  const copyDay = () => {
    const { monStartAM, monEndAM, monStartPM, monEndPM } = methods.getValues();

    if (monStartAM || monEndAM || monStartPM || monEndPM) {
      const copyIndex = copyData.findIndex(
        cd =>
          methods.getValues()[cd.startAm] !== monStartAM ||
          methods.getValues()[cd.endAm] !== monEndAM ||
          methods.getValues()[cd.startPm] !== monStartPM ||
          methods.getValues()[cd.endPm] !== monEndPM
      );
      if (copyIndex >= 0 && copyData[copyIndex])
        setValueHelper(
          {
            [copyData[copyIndex].startAm]: monStartAM,
            [copyData[copyIndex].endAm]: monEndAM,
            [copyData[copyIndex].startPm]: monStartPM,
            [copyData[copyIndex].endPm]: monEndPM
          },
          methods.setValue
        );
      setCalculate(!isCalculate);
    }
  };

  const fetchEarlyHour = () => {
    if (holidayId) {
      axios
        .get(`/api/pport-early-hours/${holidayId.holidayId}`)
        .then(({ data: rows }) => {
            methods.setValue(`hoursId`, rows.hoursId);
          if (rows.validationFlag !== 'D' && rows.validationFlag !== 'R') {
            methods.setValue(`hoursOpen`, rows.hoursOpen ?? "");
            methods.setValue(`weeksOpen`, rows.weeksOpen ?? "");
            methods.setValue(`fundedWeeks`, rows.fundedWeeks ?? "");
            methods.setValue(`censusWeek`, rows.censusWeek ?? "");

            methods.setValue(
              `monStartAM`,
              rows.monStartAM ? moment(rows.monStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `monEndAM`,
              rows.monEndAM ? moment(rows.monEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `monStartPM`,
              rows.monStartPM ? moment(rows.monStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `monEndPM`,
              rows.monEndPM ? moment(rows.monEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `tueStartAM`,
              rows.tueStartAM ? moment(rows.tueStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `tueEndAM`,
              rows.tueEndAM ? moment(rows.tueEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `tueStartPM`,
              rows.tueStartPM ? moment(rows.tueStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `tueEndPM`,
              rows.tueEndPM ? moment(rows.tueEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `wedStartAM`,
              rows.wedStartAM ? moment(rows.wedStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `wedEndAM`,
              rows.wedEndAM ? moment(rows.wedEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `wedStartPM`,
              rows.wedStartPM ? moment(rows.wedStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `wedEndPM`,
              rows.wedEndPM ? moment(rows.wedEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `thuStartAM`,
              rows.thuStartAM ? moment(rows.thuStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `thuEndAM`,
              rows.thuEndAM ? moment(rows.thuEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `thuStartPM`,
              rows.thuStartPM ? moment(rows.thuStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `thuEndPM`,
              rows.thuEndPM ? moment(rows.thuEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `friStartAM`,
              rows.friStartAM ? moment(rows.friStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `friEndAM`,
              rows.friEndAM ? moment(rows.friEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `friStartPM`,
              rows.friStartPM ? moment(rows.friStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `friEndPM`,
              rows.friEndPM ? moment(rows.friEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `satStartAM`,
              rows.satStartAM ? moment(rows.satStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `satEndAM`,
              rows.satEndAM ? moment(rows.satEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `satStartPM`,
              rows.satStartPM ? moment(rows.satStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `satEndPM`,
              rows.satEndPM ? moment(rows.satEndPM).format("HH:mm") : ""
            );

            methods.setValue(
              `sunStartAM`,
              rows.sunStartAM ? moment(rows.sunStartAM).format("HH:mm") : ""
            );
            methods.setValue(
              `sunEndAM`,
              rows.sunEndAM ? moment(rows.sunEndAM).format("HH:mm") : ""
            );
            methods.setValue(
              `sunStartPM`,
              rows.sunStartPM ? moment(rows.sunStartPM).format("HH:mm") : ""
            );
            methods.setValue(
              `sunEndPM`,
              rows.sunStartPM ? moment(rows.sunEndPM).format("HH:mm") : ""
            );
            methods.setValue(`trackId`, rows.trackId);

            calculateHoursOpen();
            setRow(rows);
            const validationFlagIndicator = validationFlagsList.includes(
              rows.validationFlag
            );
            setValidationFlag(urlSlug, {
              name: "hours",
              value: validationFlagIndicator,
            });
            setShowExclamation(validationFlagIndicator);
          }
        })
        .catch(console.log);
    }
  };

  const weekOptions = [
    { value: "MON", text: t("Monday") },
    { value: "TUE", text: t("Tuesday") },
    { value: "WED", text: t("Wednesday") },
    { value: "THU", text: t("Thursday") },
    { value: "FRI", text: t("Friday") }
  ];

  useEffect(() => {
    fetchEarlyHour();
  }, [holidayId]);

  useEffect(() => {
    calculateHoursOpen();
  }, [isCalculate]);

  return (
    <>
      <Menu className={"border-bottom"} attached={"top"}>
        <Menu.Item header className={"active"}>
          {t("Hours")}
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <UserAccessComponent requires="PPORT_PM_HCP_HOURS_D_EDIT">
              <RejectCommentsModal
                rejectComments={row.rejectComments}
                rejectedDate={row.rejectedDate}
                rejectedBy={row.rejectedBy}
                disabled={!validationFlagsList.includes(row.validationFlag)}
                color={"orange"}
              />
            </UserAccessComponent>
          </Menu.Item>
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column
                  width={2}
                  className={"padding-left-0"}
                ></Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <label>
                    <b className={"text-white-color"}>{t("Start AM")}</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <label>
                    <b className={"text-white-color"}>{t("Finish AM")}</b>
                  </label>
                </Grid.Column>
                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <label>
                    <b className={"text-white-color"}>{t("Start PM")}</b>
                  </label>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <label>
                    <b className={"text-white-color"}>{t("Finish PM")}</b>
                  </label>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Monday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="monStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="monEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="monStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="monEndPM"
                  />
                </Grid.Column>
                <Grid.Column width={1} className={"padding-left-0"}>
                  <UserAccessComponent requires="PPORT_PM_HCP_ENT_L_EDIT">
                    <Icon
                      onClick={() => copyDay()}
                      circular
                      style={{
                        backgroundColor: "#0054A4",
                        color: "#fff",
                        cursor: "pointer"
                      }}
                    >
                      <i className="far fa-clock" />
                    </Icon>
                  </UserAccessComponent>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Tuesday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="tueStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="tueEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="tueStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="tueEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Wednesday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="wedStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="wedEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="wedStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="wedEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Thursday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="thuStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="thuEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="thuStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="thuEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Friday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="friStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="friEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="friStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="friEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Saturday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="satStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="satEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="satStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="satEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column width={2} className={"padding-left-0"}>
                  <p style={{ paddingTop: "0.75rem" }}>
                    <b className={"text-white-color"}>{t("Sunday")}</b>
                  </p>
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="sunStartAM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="sunEndAM"
                  />
                </Grid.Column>

                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>

                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="sunStartPM"
                  />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input
                    type="time"
                    cb={val => setCalculate(!isCalculate)}
                    name="sunEndPM"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column
                  width={2}
                  className={"padding-left-0"}
                ></Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input label={t("Total Hours")} name="hoursOpen" readOnly />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input name="fundedWeeks" label={t("Funded Weeks")} />
                </Grid.Column>
                <Grid.Column
                  width={1}
                  className={"padding-left-0"}
                ></Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Input name="weeksOpen" label={t("Weeks Open")} />
                </Grid.Column>
                <Grid.Column width={3} className={"padding-left-0"}>
                  <Dropdown
                    fluid
                    search
                    selection
                    label={t("Census Day")}
                    name="censusWeek"
                    options={weekOptions}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <br />
            <UserAccessComponent requires="PPORT_PM_HCP_HOURS_D_SUB">
              <Button className={"green"} type="submit">
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
            <UserAccessComponent requires="PPORT_PM_HCP_HOURS_D_DEL">
              <DeleteConfirmationModal
                message={t("Are you sure you want to delete this Hours?")}
                onDelete={() => handleDelete(row.hoursId)}
                buttonTitle={t("Delete")}
                buttonColor="red"
                color="white"
                iconName="trash alternate outline"
                confirmTitle={t("Confirm Delete")}
                disabled={!row.hoursId}
              />
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default Hours;
