import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button, Form, Icon } from "semantic-ui-react";
import ProviderSearch from "./ProviderSearch";
import UserAccessComponent from "./UserAccessComponent";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { useToasts } from "react-toast-notifications";
import { createSyncTrackPutObject, getValidationFlag, setValueHelper } from "../utils";

function PickupDropOffDetails({ row = {}, onBack, providerValid = [] }) {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { addToast } = useToasts();
  let searchSource = {};

  const schema = yup.object({
    providerNameWithId: yup
      .string()
      .required(t("Please enter a value"))
      .unique({
        message: t("Already in use"),
        existing: providerValid
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ linkedProviderId, id, validationFlag, trackId }) => {
    const postData = {
      validationFlag: "I",
      providerId: provider.providerId,
      linkedProviderId
    };

    const putData = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag)
      },
      { op: "add", path: "/linkedProviderId", value: linkedProviderId }
    ];

    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));
    setSaving(true);
    if (id) {
      axios
        .put(`/api/pport-early-pickup-drop-prov/${id}`, putData, putObject)
        .then(() => {
          addToast(
            `${t("Provider Pickup & Drop Off")} ${t(
              "has been successfully updated"
            )}`,
            {
              appearance: "success"
            }
          );
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Provider Pickup & Drop Off")} ${t("has not been updated")}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-pickup-drop-prov`, postData)
        .then(({ data: { id } }) => {
          addToast(
            `${t("Provider Pickup & Drop Off")} ${t(
              "has been successfully created"
            )}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("id", id);
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Provider Pickup & Drop Off")} ${t("has not been created")}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    }
  };

  const handleSearchChange = query => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if(Object.keys(searchSource).length > 0) {
      searchSource.cancel();
    }
    searchSource = source;
    return axios.get(`/api/search/early-provider?query=${query}`, { cancelToken: source.token });
  }

  useEffect(() => {
    return () => {
      methods.reset({
        linkedProviderId: "",
        providerId: "",
        providerNameWithId: "",
        id: ""
      });
    };
  }, []);

  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <ProviderSearch
          disabled={!!row.id}
          name="providerNameWithId"
          onSearchChange={handleSearchChange}
          label={t("Provider")}
          onResultSelect={({ providerId }) =>
            methods.setValue("linkedProviderId", providerId)
          }
          onValueChange={value => {
            if (!value) {
              methods.setValue("linkedProviderId", "");
              methods.setValue("providerNameWithId", "");
            }
          }}
        />
        <br />
        <Button onClick={() => onBack(false)} color="orange">
          <Icon name="arrow circle left" />
          {t("Back")}
        </Button>
        <UserAccessComponent requires="PPORT_PM_DT_PUD_P_D_SUB">
          <Button className={"green"} type="submit" disabled={saving || !!row.id}>
            <Icon name="check circle" />
            {t("Submit")}
          </Button>
        </UserAccessComponent>
      </Form>
    </FormProvider>
  );
}

export default PickupDropOffDetails;
