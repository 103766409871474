import React, { useState, useEffect } from 'react';
import { Icon, Label, Menu } from 'semantic-ui-react';
import EySendDetailsScreen from "./EySendDetailsScreen";
import moment from 'moment/moment';
import { useTranslation } from "react-i18next";
import EarlySendTypeListScreen from "./EarlySendTypeListScreen";
import EarlyPlanTypeListScreen from "./EarlyPlanTypeListScreen";
import EarlyAgenciesInvolvedListScreen from "./EarlyAgenciesInvolvedListScreen";

const EarlyYearsSendScreen = (props) => {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState('eySendDetails');
  const [restrictForm, setRestrictForm] = useState(false);
  const [earlySenId, setEarlySenId] = useState();


  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const setRestriction = () => {
    const months = moment().diff(props.birthdate, 'months');
    if (months > 60) setRestrictForm(true);
  };


  useEffect(() => {
    setRestriction();
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item
          id={'eySendDetailsScreen'}
          name="eySendDetails"
          activeClassName="active"
          active={activeItem === 'eySendDetails'}
          onClick={handleItemClick}
        >
          <Icon>
            <i className="fa-regular fa-universal-access"/>
          </Icon>
          {t('Early Years SEND')}
        </Menu.Item>
        <Menu.Item
          id={'eySendTypeList'}
          name="eySendType"
          activeClassName="active"
          active={activeItem === 'eySendType'}
          onClick={handleItemClick}
          disabled={!Boolean(earlySenId)}
        >
          <Icon>
            <i className="fa-regular fa-list"/>
          </Icon>
          {t('SEND Type')}
        </Menu.Item>
        <Menu.Item
          id={'eySendPlanList'}
          name="eySendPlan"
          activeClassName="active"
          active={activeItem === 'eySendPlan'}
          onClick={handleItemClick}
          disabled={!Boolean(earlySenId)}
        >
          <Icon>
            <i className="fa-light fa-file-invoice"/>
          </Icon>
          {t('SEND Plan')}
        </Menu.Item>
        <Menu.Item
          id={'eyAgenciesInvolvedList'}
          name="eyAgenciesInvolved"
          activeClassName="active"
          active={activeItem === 'eyAgenciesInvolved'}
          onClick={handleItemClick}
          disabled={!Boolean(earlySenId)}
        >
          <Icon>
            <i className="fa-solid fa-building-user"></i>
          </Icon>
          {t('Agencies Involved')}
        </Menu.Item>
      </Menu>
      {restrictForm && (
        <>
          <br/>
          <Label color={'red'} size={'medium'} style={{ width: '100%' }}>
            {t('This pupil is over five years of age so is no longer eligible for Early Years SEND assistance')}
          </Label>
        </>
      )}
      {activeItem === 'eySendDetails' && (
        <EySendDetailsScreen
          setEarlySenId={setEarlySenId}
          restrictForm={restrictForm}
        />
      )}
      {activeItem === 'eySendType' && (
        <EarlySendTypeListScreen
          earlySenId={earlySenId}
          restrictForm={restrictForm}
        />
      )}
      {activeItem === 'eySendPlan' && (
        <EarlyPlanTypeListScreen
          earlySenId={earlySenId}
          restrictForm={restrictForm}
        />
      )}
      {activeItem === 'eyAgenciesInvolved' && (
        <EarlyAgenciesInvolvedListScreen
          earlySenId={earlySenId}
          restrictForm={restrictForm}
        />
      )}
    </div>
  );
};

export default EarlyYearsSendScreen;
