import React from 'react';
import { Modal, Button, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ApplyConfirmationModal = ({ isModalOpen, onClose, modalHeader, message, onConfirm, confirmText, continueMsg }) => {
  const { t } = useTranslation();
  return (
    <Modal open={isModalOpen} onClose={() => onClose()} className={'dark-modal'}>
      <Modal.Header className={'modal-header'}>{!modalHeader ? t('Confirm') : t(modalHeader)}</Modal.Header>
      <Modal.Content image className="modal-content">
        <Modal.Description>
          <p className={'text-white-color'} style={{ whiteSpace: 'pre-line' }}>{t(message)}</p>
          {continueMsg && <> <br /> <p className={'text-white-color'}>{t(continueMsg)}</p></> }
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modal-actions" style={{ textAlign: 'left' }}>
        <Button color="green" onClick={onConfirm}>
          <Icon name="checkmark" />{!confirmText ? t('OK') : confirmText}
        </Button>
        <Button color="orange" onClick={onClose} >
          <Icon name="arrow circle left" />{t('Back')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ApplyConfirmationModal;
