import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
  Grid as FilesGrid,
} from "@caci/react-grid";
import {
  ButtonSubmit,
  DateInput,
  Dropdown,
  Input,
} from "@caci/react-form-fields";
import * as yup from "yup";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import {
  lookupCodeToValue,
  lookupToOptionsTranslation,
  processFileUploadError,
  yesNoOptions,
  yesNoOptionsLookUp,
  calculateDateToOneYear,
} from "../../utils";
import useLookup from "../../consumer/LookupConsumer";
import moment from "moment/moment";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "../../components/UserAccessComponent";
import fileDownload from "react-file-download";
import { isEmpty } from "ramda";

function EYFFundingEligibility({ carer, childDob, childId }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { eypChildId, providerId } = useParams();
  let { lookups: eYFundingClaimBasis } = useLookup(6213);
  const { lookups: ecsErrorCode } = useLookup(6223);

  const [rows, setRows] = useState([]);
  const [isFundingEligibilityOpen, setFundingEligibilityOpen] = useState(false);
  const [enableMessageBox, setEnableMessageBox] = useState(false);
  const [claimBasis, setClaimBasis] = useState("");
  const [lastEcsCheckDate, setLastEcsCheckDate] = useState("");
  const [parConsentReq, setParConsentReq] = useState("N");
  const [origParConsentReq, setOrigParConsentReq] = useState("N");
  const [messageBoxColour, setMessageBoxColour] = useState("red");
  const [dlaLetter, setDlaLetter] = useState("N");
  const [extendedHours, setExtendedHours] = useState("N");
  const [saving, setSaving] = useState(false);
  const [uploadError, setUploadError] = useState();
  const [eligibilityHeader, setEligibilityHeader] = useState("");
  const [eligibilityDescription1, setEligibilityDescription1] = useState("");
  const [eligibilityDescription2, setEligibilityDescription2] = useState("");
  const [eligibilitySubText, setEligibilitySubText] = useState("");
  const [iconClass, setIconClass] = useState("");
  const [FilesList, setFilesList] = useState([]);
  const [disableBack, setDisableBack] = useState(false);
  const EYPP = 'EYPP';
  const CODE = 'CODE';
  const DIS = 'DIS';
  const DAF = 'DAF';
  const THIRTYH = 'THIRTYH';
  const EYP_PARENT = 'EY_PERSON';
  const _2YO = '2YO';
  const RE = 'RE';

  const filterClaimBasis = (eYFundingClaimBasis, rows) => {
    const count = {}
    rows.forEach(row => {
      if (row.claimBasis === EYPP && carer?.eypParentId === row.eypParentId) {
        count[row.claimBasis] = (count[row.claimBasis] || 0) + 1;
      } else if (row.claimBasis === CODE && carer?.eypParentId === row.eypParentId) {
        count[row.claimBasis] = (count[row.claimBasis] || 0) + 1;
      } else if (row.claimBasis === DIS) {
        count[row.claimBasis] = (count[row.claimBasis] || 0) + 1;
      }
    })

    if (count[EYPP] >= 1) {
      eYFundingClaimBasis = eYFundingClaimBasis.filter(cb => cb.codeValue !== EYPP)
    }

    if (count[CODE] >= 2) {
      eYFundingClaimBasis = eYFundingClaimBasis.filter(cb => cb.codeValue !== CODE)
    }

    if (count[DIS] >= 1) {
      eYFundingClaimBasis = eYFundingClaimBasis.filter(cb => cb.codeValue !== DIS)
    }

    return eYFundingClaimBasis;
  }

  const schemaDAF = yup.object({
    dlaLetter: yup
        .string()
        .oneOf(["Y"], 'Has the child got DLA letter must be Yes'),
    extendedHours: yup
        .string()
        .oneOf(["Y"], "Confirm child claiming funded/extended hours must be Yes"),
    file: yup.mixed().required("Please upload a file"),
    eligStartDate: yup
        .date()
        .typeError("Please enter a value")
        .required("Please enter a value"),
    parConsentReq: yup
        .string()
        .required("Please enter a value")
        .oneOf(
            ["Y"],
            "Has a Parental consented to eligibility check must be Yes"
        ),

  });

  const schemaDIS = yup.object({
    claimRefNo: yup
        .string()
        .required("Please enter a value")
        .test(
            "is-integer",
            "No spaces allowed",
            (value) => /^[^\s]+$/.test(value)
        )
        .max(20, "20 character limit exceeded"),
    parConsentReq: yup
        .string()
        .required("Please enter a value")
        .oneOf(
            ["Y"],
            "Has a Parental consented to eligibility check must be Yes"
        ),
  });

  const schemaCode = yup.object({
    parConsentReq: yup
        .string()
        .required("Please enter a value")
        .oneOf(
            ["Y"],
            "Has a Parental consented to eligibility check must be Yes"
        ),

    eligibilityCode: yup
        .string()
        .required("Please enter a value")
        .test(
            "is-integer",
            "Digits only, no spaces or special characters",
            (value) => /^\d+$/.test(value)
        )
        .max(11, "11 character limit exceeded"),
  });

  const schemaEYPP = yup.object({
    parConsentReq: yup
      .string()
      .required("Please enter a value")
      .oneOf(
        ["Y"],
        "Has a Parental consented to eligibility check must be Yes"
      )
  });

  let resolver;

  switch (claimBasis) {
    case DAF:
      resolver = yupResolver(schemaDAF);
      break;
    case DIS:
      resolver = yupResolver(schemaDIS);
      break;
    case CODE:
      resolver = yupResolver(schemaCode);
      break;
    case EYPP:
      resolver = yupResolver(schemaEYPP);
      break;
    default:
      resolver = null; // Handle the case when none of the conditions match
  }

  const methods = useForm({
    resolver: resolver,
  });

  const getFundingEligibility = () => {
    axios
        .get(`/api/eyp-eligibility/${eypChildId}`)
        .then(({ data: rows }) => setRows(rows))
        .catch(console.log);
  };

  const handleCancel = (FundingModal) => {
    setFilesList([]);
    setClaimBasis("");
    methods.setValue("id", "");
    methods.setValue("claimBasis", "");
    methods.setValue("eligibilityCode", "");
    methods.setValue("eyfConfigEligId", "");
    methods.setValue("claimRefCodeInd", "");
    methods.setValue("eligStartDate", undefined);
    methods.setValue("eligEndDate", undefined);
    methods.setValue("gracePeriodDate", undefined);
    methods.setValue("claimRefNo", "");
    methods.setValue("laApproved", "");
    methods.setValue("laApprovedDate", undefined);
    methods.setValue("proofSeenDate", undefined);
    methods.setValue("claimCodeChecked", "");
    methods.setValue("claimCodeDateLastChecked", undefined);
    methods.setValue("claimCodeValid", "");
    methods.setValue("parConsentReq", "");
    methods.setValue("dlaLetter", "N");
    methods.setValue("extendedHours", "N");
    methods.setValue("file", []);
    methods.setValue("eypParentId", "");
    methods.setValue("ecsHistoryId", "");
    methods.setValue("eyppReason", null);
    setEnableMessageBox(false);
    setFundingEligibilityOpen(FundingModal);
    setDisableBack(false);
  };

  const handleEdit = (row) => {
    setClaimBasis(row.claimBasis);
    setFundingEligibilityOpen(true);
    methods.setValue("id", row.id);
    methods.setValue("claimBasis", row.claimBasis);
    methods.setValue("eyfConfigEligId", row.eyfConfigEligId);
    methods.setValue("claimRefCodeInd", row.claimRefCodeInd);
    methods.setValue(
        "eligStartDate",
        row.eligStartDate ? moment(row.eligStartDate).format("YYYY-MM-DD") : null
    );
    methods.setValue(
        "eligEndDate",
        row.eligEndDate ? moment(row.eligEndDate).format("YYYY-MM-DD") : null
    );
    methods.setValue(
        "gracePeriodDate",
        row.gracePeriodDate
            ? moment(row.gracePeriodDate).format("YYYY-MM-DD")
            : null
    );
    methods.setValue("claimRefNo", row.claimRefNo);
    if (row.claimBasis === CODE) methods.setValue("eligibilityCode", row.claimRefNo)
    methods.setValue("laApprovedDate", row.laApprovedDate);
    methods.setValue(
        "laApprovedDate",
        row.laApprovedDate
            ? moment(row.laApprovedDate).format("YYYY-MM-DD")
            : null
    );
    methods.setValue(
        "proofSeenDate",
        row.proofSeenDate ? moment(row.proofSeenDate).format("YYYY-MM-DD") : null
    );
    methods.setValue("claimCodeChecked", row.claimCodeChecked);
    methods.setValue(
        "claimCodeDateLastChecked",
        row.claimCodeDateLastChecked
            ? moment(row.claimCodeDateLastChecked).format("YYYY-MM-DD")
            : null
    );
    methods.setValue("claimCodeValid", row.claimCodeValid);
    methods.setValue("parConsentReq", "Y");
    methods.setValue("dlaLetter", "Y");
    methods.setValue("extendedHours", "Y");
    setParConsentReq('Y');
    methods.setValue("laApproved", row.laApproved);
    methods.setValue("eypParentId", carer?.eypParentId);
    methods.setValue("ecsHistoryId", row.ecsHistoryId);
    methods.setValue("eyppReason", row.eyppReason);
    getEligibilityDocument(methods.getValues()?.id);
  };

  const onSubmit = async () => {
    if (!carer) {
      addToast(t("No primary carer/parent exist."), {
        appearance: "error",
      });
      return;
    }

    const postData = {
      id: methods.getValues()?.id,
      claimBasis: claimBasis,
      eyfConfigEligId: methods.getValues()?.eyfConfigEligId,
      claimRefCodeInd: methods.getValues()?.claimRefCodeInd || "N",
      claimRefNo: claimBasis === CODE ? methods.getValues()?.eligibilityCode : methods.getValues()?.claimRefNo,
      laApproved: methods.getValues()?.laApproved || null,
      laApprovedDate: methods.getValues()?.laApprovedDate
          ? moment(methods.getValues()?.laApprovedDate).format("YYYY-MM-DD")
          : null,
      proofSeenDate: methods.getValues()?.proofSeenDate
          ? moment(methods.getValues()?.proofSeenDate).format("YYYY-MM-DD")
          : null,
      eligStartDate: methods.getValues()?.eligStartDate
          ? moment(methods.getValues()?.eligStartDate).format("YYYY-MM-DD")
          : null,
      eligEndDate: methods.getValues()?.eligEndDate
          ? moment(methods.getValues()?.eligEndDate).format("YYYY-MM-DD")
          : null,
      gracePeriodDate: methods.getValues()?.gracePeriodDate
          ? moment(methods.getValues()?.gracePeriodDate).format("YYYY-MM-DD")
          : null,
      claimCodeChecked: methods.getValues()?.claimCodeChecked,
      claimCodeDateLastChecked: methods.getValues()?.claimCodeDateLastChecked
          ? moment(methods.getValues()?.claimCodeDateLastChecked).format(
              "YYYY-MM-DD"
          )
          : null,
      claimCodeValid: methods.getValues()?.claimCodeValid,
      eypChildId: eypChildId,
      eypParentId: carer?.eypParentId,
      ecsHistoryId: methods.getValues()?.ecsHistoryId,
      eyppReason: methods.getValues()?.eyppReason,
    };

    const putEligibilityData = [
      { op: "add", path: "/claimBasis", value: claimBasis },
      {
        op: "add",
        path: "/eyfConfigEligId",
        value: methods.getValues()?.eyfConfigEligId,
      },
      {
        op: "add",
        path: "/eligStartDate",
        value: methods.getValues()?.eligStartDate
            ? moment(methods.getValues()?.eligStartDate).format("YYYY-MM-DD")
            : null,
      },
      {
        op: "add",
        path: "/claimRefCodeInd",
        value: methods.getValues()?.claimRefCodeInd
            ? methods.getValues()?.claimRefCodeInd
            : "N",
      },
      {
        op: "add",
        path: "/claimRefNo",
        value: claimBasis === CODE ? methods.getValues()?.eligibilityCode : methods.getValues()?.claimRefNo,
      },
      {
        op: "add",
        path: "/laApproved",
        value: methods.getValues()?.laApproved
            ? methods.getValues()?.laApproved
            : null,
      },
      {
        op: "add",
        path: "/laApprovedDate",
        value: methods.getValues()?.laApprovedDate
            ? moment(methods.getValues()?.laApprovedDate).format("YYYY-MM-DD")
            : null,
      },
      {
        op: "add",
        path: "/claimCodeChecked",
        value: methods.getValues()?.claimCodeChecked,
      },
      {
        op: "add",
        path: "/proofSeenDate",
        value: methods.getValues()?.proofSeenDate
            ? moment(methods.getValues()?.proofSeenDate).format("YYYY-MM-DD")
            : null,
      },
      {
        op: "add",
        path: "/eligEndDate",
        value: methods.getValues()?.eligEndDate
            ? moment(methods.getValues()?.eligEndDate).format("YYYY-MM-DD")
            : null,
      },
      {
        op: "add",
        path: "/gracePeriodDate",
        value: methods.getValues()?.gracePeriodDate
            ? moment(methods.getValues()?.gracePeriodDate).format("YYYY-MM-DD")
            : null,
      },
      {
        op: "add",
        path: "/claimCodeDateLastChecked",
        value: methods.getValues()?.claimCodeDateLastChecked
            ? moment(methods.getValues()?.claimCodeDateLastChecked).format(
                "YYYY-MM-DD"
            )
            : null,
      },
      {
        op: "add",
        path: "/claimCodeValid",
        value: methods.getValues()?.claimCodeValid,
      },
      {
        op: "add",
        path: "/eypParentId",
        value: methods.getValues()?.eypParentId,
      },
      {
        op: "add",
        path: "/ecsHistoryId",
        value: methods.getValues()?.ecsHistoryId,
      },
      {
        op: "add",
        path: "/eyppReason",
        value: methods.getValues()?.eyppReason,
      }
    ];

    if(claimBasis === DIS) {
      postData.eligibilityType = _2YO;
      putEligibilityData.push({ op: 'replace', path: '/eligibilityType', value: _2YO });
    } else{
      postData.eligibilityType = claimBasis;
      putEligibilityData.push({ op: 'replace', path: '/eligibilityType', value: claimBasis });
    }

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    setSaving(true);

    try {
      if (!methods.getValues()?.id) {
        const { data } = await axios.post(`/api/eyp-eligibility?providerId=${providerId}`, postData);
        setSaving(false);
        methods.setValue("id", data.id);

        if (claimBasis === "DAF") await uploadFile();

        addToast(t("Funding Eligibility has been successfully created"), {
          appearance: "success",
        });

        setDisableBack(false);
        handleCancel(false);
        getFundingEligibility();
      } else {
        await axios.put(
            `/api/eyp-eligibility/${methods.getValues()?.id}`,
            putEligibilityData,
            putObject
        );

        addToast(t("Funding Eligibility has been successfully updated"), {
          appearance: "success",
        });

        setSaving(false);
        setDisableBack(false);
        handleCancel(false);
        getFundingEligibility();
      }
    } catch (error) {
      console.log(error);
      addToast(t("An error occurred"), {
        appearance: "error",
      });
      setSaving(false);
      setDisableBack(false);
    }
  };

  const buildFormData = () => {
    if (!methods.getValues()?.file) {
      return null;
    }

    const formData = new FormData();

    formData.append("file", methods.getValues()?.file);
    formData.append("eligibilityId", methods.getValues()?.id);
    formData.append("providerId", providerId);

    return formData;
  };

  const uploadFile = async () => {
    try {
      const formData = buildFormData();

      if (!formData) {
        return;
      }

      setSaving(true);

      await axios.post(`/api/eyp-eligibility-doc/upload`, formData);
      getEligibilityDocument(methods.getValues()?.id);

      setSaving(false);
    } catch (error) {
      uploadError(processFileUploadError(error));
      console.error(error);
      setSaving(false);
    }
  };

  const getEligibilityDocument = (id) => {
    axios
        .get(`/api/eyp-eligibility-doc/${id}`)
        .then((response) => {
          if (response.data)
            setFilesList((prevFilesList) => [...prevFilesList, response.data]);
          methods.setValue("file", response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
  };

  const handleFormDataUpdate = (response, ecsEligibilityCheckType, newCheck= false) => {
    const eligibilityCode = response?.data?.eligibilityCode;

    if(eligibilityCode === 1) {
      methods.setValue("claimCodeChecked", 'Y');
      methods.setValue("claimCodeValid", 'Y');
      methods.setValue("ecsHistoryId", response?.data?.id);
      setEligibilityHeader("Eligible");

      if(ecsEligibilityCheckType === THIRTYH) {
        setEligibilityDescription1(`Eligibility Start Date: ${response?.data?.validityStartDate ?? ''}`);
        setEligibilityDescription2(`Eligibility End Date: ${response?.data?.validityEndDate ?? ''}`);
        setEligibilitySubText(`Grace Period End Date: ${response?.data?.gracePeriodEndDate ?? ''}`);

        methods.setValue("eligStartDate", response?.data?.validityStartDate);
        methods.setValue("eligEndDate", response?.data?.validityEndDate);
        methods.setValue("gracePeriodDate", response?.data?.gracePeriodEndDate);
      }
      setLastEcsCheckDate(response?.data?.checkDate);
      setIconClass("fa-regular fa-circle-check")
      setEnableMessageBox(true);
      setMessageBoxColour("#21ba45");
      if (newCheck) setDisableBack(true);
      if (claimBasis === EYPP) methods.setValue("eyppReason", RE);
    }
    else if([0, -1].includes(eligibilityCode)) {
      methods.setValue("claimCodeChecked", eligibilityCode === 0 ? 'Y': 'N');
      methods.setValue("claimCodeValid", eligibilityCode === 0 ? 'N' : null);
      methods.setValue("ecsHistoryId", response?.data?.id);
      setEligibilityHeader("Not Eligible");
      setEligibilityDescription1(response?.data?.qualifier ? response?.data?.qualifier : ecsErrorCode.find(x=>parseInt?.(x?.codeValue) === response?.data?.returnCode)?.codeDescription);
      setEligibilityDescription2("");
      setEligibilitySubText("");
      setEnableMessageBox(true);
      setMessageBoxColour("red");
      setIconClass(response?.data?.eligibilityCode === -1 ? "fa-regular fa-circle-xmark" : "fa-regular fa-ban")
      setLastEcsCheckDate(response?.data?.checkDate);

      if (newCheck && (eligibilityCode === 0 || response?.data?.returnCode >= 0)) setDisableBack(true);
    }
  }

  const lastEligibilityCheck = () => {
    const ecsEligibilityCheckType = claimBasis === CODE ? THIRTYH : claimBasis;
    const claimantId = carer?.personId ? carer.personId : carer?.eypParentId;

    if (!claimantId) {
      addToast(t("No primary carer/parent exist."), {
        appearance: "error",
      });
      return;
    }

    if(claimBasis) {
      axios
          .get(`/api/claims/ecs-last-check?claimantId=${claimantId}&eligibilityCheckType=${ecsEligibilityCheckType}`)
          .then((response) => {
            handleFormDataUpdate(response, ecsEligibilityCheckType);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
    }
  };

  const checkEcs = () => {

    setEligibilityDescription1("");
    setEligibilityDescription2("");
    setEligibilitySubText("");
    setEnableMessageBox(false);

    if( !carer) {
      setEnableMessageBox(true);
      setMessageBoxColour("orange");
      setEligibilityHeader("No Carer Found");
      setIconClass("fa-regular fa-circle-xmark")

      setEligibilityDescription1("Please add a primary parent/carer in order to run the ECS check");
      return;
    }

    if(!carer.birthdate && !carer.niNo && !carer.nassNo) {
      setEnableMessageBox(true);
      setMessageBoxColour("orange");
      setEligibilityHeader("No Carer Found");
      setIconClass("fa-regular fa-circle-xmark")

      setEligibilityDescription1("Please update the primary parent/carer with child DOB and NI number/NASS number in order to run the ECS check");
      return;
    }

    if(!carer.birthdate) {
      setEnableMessageBox(true);
      setMessageBoxColour("orange");
      setEligibilityHeader("No Carer Found");
      setIconClass("fa-regular fa-circle-xmark")

      setEligibilityDescription1("Please update the primary parent/carer with child DOB in order to run the ECS check");
      return;
    }

    if(!carer.niNo && !carer.nassNo) {
      setEnableMessageBox(true);
      setMessageBoxColour("orange");
      setEligibilityHeader("No Carer Found");
      setIconClass("fa-regular fa-circle-xmark")

      setEligibilityDescription1("Please update the primary parent/carer with NI number/NASS number in order to run the ECS check");
      return;
    }

    let birthDate, ecsEligibilityCheckType;
    switch (claimBasis) {
      case CODE:
        birthDate = childDob; // For Eligibility Code (CODE) birthdate should be childBirthdate
        ecsEligibilityCheckType = THIRTYH; // For Eligibility Code (CODE) ecsEligibilityCheckType should be THIRTYH
        break;
      case EYPP:
        birthDate = carer?.birthdate;
        ecsEligibilityCheckType = EYPP;
        break;
      default:
        ecsEligibilityCheckType = claimBasis;
    }

    const postData ={
      eligibilityCode: methods.watch("eligibilityCode"),
      dateOfBirth: birthDate,
      nino: carer?.niNo,
      nass: carer?.nassNo,
      surname: carer?.surname,
      claimantId: carer?.personId ? carer.personId : carer?.eypParentId,
      claimantContext: carer?.personId ? 'PERSON' : EYP_PARENT
    }

    setEnableMessageBox(false);
    setSaving(true);

    axios.post(`/api/claims/ecs-check-single?ecsEligibilityCheckType=${ecsEligibilityCheckType}`, postData)
        .then((response) => {
          handleFormDataUpdate(response, ecsEligibilityCheckType, true);
          setSaving(false);

        })
        .catch((e)=>{
          console.log(e)
          setSaving(false);
        });
  };

  const eyfConfigElig = () => {
    axios
        .get(`/api/eyp-config-elig?childId=${childId}&claimBasis=${claimBasis}`)
        .then((response) => {
          methods.setValue("eyfConfigEligId", response.data);
          setOrigParConsentReq(response.data.parConsentReq);
        })
        .catch((error) => {
          const errMsg = error.response?.data;
          if (errMsg?.toString().includes(childId)) {
            methods.setValue("eyfConfigEligId", null);
          }
          console.log(error);
        });
  };

  const isSaving = saving;
  let validations;
  switch (claimBasis) {
    case DAF:
      validations = (
          methods.getValues()?.dlaLetter === "N" ||
          methods.getValues()?.extendedHours === "N" ||
          parConsentReq === "N" || isSaving
      );
      break;
    case DIS:
      validations = (
          isSaving || parConsentReq !== 'Y'
      );
      break;
    default:
      validations = (isSaving || parConsentReq !== 'Y') || methods.watch("claimCodeChecked") !== 'Y';
      break;
  }
  const isDisabled = validations ;

  useEffect(() => {
    if (eypChildId !== "new") {
      methods.setValue("extendedHours", "N");
      methods.setValue("dlaLetter", "N");
    }
  }, [eypChildId]);

  useEffect(() => {
    if(!!methods.getValues()?.id && isFundingEligibilityOpen)
      lastEligibilityCheck();
  }, [methods.getValues()?.id, isFundingEligibilityOpen]);

  useEffect(() => {
    if(claimBasis) eyfConfigElig();
  }, [claimBasis]);

  useEffect(() => {
    if (eypChildId !== "new") {
      getFundingEligibility(eypChildId);
    }
  }, [
    eypChildId,
  ]);

  useEffect(() => {
    methods.register("file", { required: "Please upload a file" });
  }, [methods]);

  const handleDropdownChange = (event, { value, name }) => {
    if (name === "claimBasis") {
      setClaimBasis(value);
    }
  };

  const handleDownload = (id, filename) => {
    axios
      .get(`/api/files/download-file/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        fileDownload(response.data, filename, response.headers["content-type"]);
      })
      .catch((err) => console.log(err));
  };

  const renderUserAccess = () => {
    switch (claimBasis) {
      case "":
        return " ";
      case CODE:
        return "PPORT_EYF_CHILD_ELIG_CODE_SUB";
      case EYPP:
        return "PPORT_EYF_CHILD_ELIG_EYPP_SUB";
      case DAF:
        return "PPORT_EYF_CHILD_ELIG_DAF_SUB";
      case DIS:
        return "PPORT_EYF_CHILD_ELIG_DIS_SUB";
      default:
        return "";
    }
  };

  const renderEligibilityFormHeader = () => {
    switch (claimBasis) {
      case "":
        return t("Funding Eligibility");
      case CODE:
        return t("Eligibility Code");
      case EYPP:
        return t("EYPP");
      case DAF:
        return t("DAF Claim");
      case DIS:
        return t("Disadvantaged 2YO");
      default:
        return "";
    }
  };

  const renderEligibilityForm = (eYFundingClaimBasis) => {
    switch (claimBasis) {
      case CODE:
        return renderCodeEligibilityForm();
      case EYPP:
        return renderEYPPForm();
      case DAF:
        return renderDAFForm();
      case DIS:
        return renderDISForm();
      default:
        return renderDefaultForm(eYFundingClaimBasis);
    }
  };

  const renderActionButton = (content, onClick, iconClassName, disabled, buttonClassName) => (
      <ButtonSubmit
          id="moduleLEdit"
          useForm={false}
          content={t(content)}
          disabled={disabled}
          loading={saving}
          onClick={onClick}
          iconClassName={iconClassName}
          buttonClassName={buttonClassName}
      />
  );

  const renderDefaultForm = (eYFundingClaimBasis) => (
      <Grid stackable>
        <Grid.Row className={"padding-top-0"}>
          <Grid.Column width={8} className={"padding-left-0 padding-right-10"}>
            <Form>
              <Form.Group widths={"equal"}>
                <Form.Dropdown
                    fluid
                    selection
                    search
                    label={t("Eligibility Type")}
                    onChange={handleDropdownChange}
                    value={claimBasis}
                    name="claimBasis"
                    options={filterClaimBasis(eYFundingClaimBasis, rows)
                        .map(lookupToOptionsTranslation(t))
                        .concat({ text: "Clear Value", value: "" })
                        .sort((a, b) => (a.value < b.value ? -1 : 1))}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  );

  const showMessageBox = (enableMessageBox, messageBoxColour,eligibilityHeader,eligibilityDescription1,eligibilityDescription2,eligibilitySubText, iconClass) => {
    return enableMessageBox && (
        <p>
          <div id="messageBox" style={{
            backgroundColor: messageBoxColour,
            padding: '10px',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
            display: 'flex',  // Use flexbox
            justifyContent: 'space-between',  // Align items with space between them
            alignItems: 'center',  // Align items vertically in the center
            paddingRight: '20px'  // Add padding to the right
          }}>
            <div style={{marginBottom: '10px', color: 'white'}}>
              <h5>{eligibilityHeader}</h5>
              <p>{eligibilityDescription1}</p>
              <p>{eligibilityDescription2}</p>
              <p>{eligibilitySubText}</p>
            </div>
            <div><Icon><i className={iconClass + " fa-2x text-white"}></i></Icon></div>
          </div>
        </p>
    );
  };

  const renderCodeEligibilityForm = () => (
      <>
        <FormProvider {...methods}>
          <Form>
            <Form.Group widths="equal">
              <Input
                  id="eligibilityCode"
                  label={t("Eligibility Code")}
                  name="eligibilityCode"
                  cb={(val) => {
                    methods.setValue('eligibilityCode', val, { shouldValidate: true });
                  }}
              />
              <Dropdown
                  id="eyfParentDecInd"
                  label={t("Parent Consent check")}
                  name="parConsentReq"
                  options={yesNoOptions}
                  cb={(val) => {
                    setParConsentReq(val);
                  }}
              />
            </Form.Group>
            <Header as="h4">
              {t(
                  'By clicking "Submit" you confirm you have consent to validate the code. The system will run a check on the eligibility code and will display the result below.'
              )}
            </Header>
            {carer?.forename && (
                <p style={{color: "gray"}}>
                  Consenting Parent or Carer: {carer.forename} {carer.surname} DOB: {carer.birthdate ? `${new Date(carer.birthdate).getDate()}/${new Date(carer.birthdate).getMonth() + 1}/${new Date(carer.birthdate).getFullYear()}` : '-'} {carer.NINAS ? 'NI/NaS: ' + carer.NINAS : ''},

                  {carer.niNo ? `NI: ${carer.niNo}` : carer.nassNo ? `NASS: ${carer.nassNo}` : "NI/NASS: - "}
                </p>
            )}
            <UserAccessComponent requires="PPORT_EYF_CHILD_ELIG_CODE_ECS">
              {renderActionButton("Submit ECS Check", checkEcs, "fa-solid fa-circle-question", !methods.watch('eligibilityCode') || parConsentReq !== 'Y' || methods.watch("eligibilityCode")?.length > 11 || methods.formState.errors.eligibilityCode?.message,"purple"
              )}
            </UserAccessComponent>
            {renderActionButton(
                "View Check History",
                () => setFundingEligibilityOpen(false),
                "fa-regular fa-clock-eight", saving
            )}
            {lastEcsCheckDate && <p>Last ECS Check Date: {`${new Date(lastEcsCheckDate).getDate()}/${new Date(lastEcsCheckDate).getMonth() + 1}/${new Date(
                lastEcsCheckDate
            ).getFullYear()}`}</p>}
            {showMessageBox(enableMessageBox,messageBoxColour,eligibilityHeader,eligibilityDescription1,eligibilityDescription2,eligibilitySubText,iconClass)}

          </Form>
        </FormProvider>
      </>
  );

  const renderEYPPForm = () => (
      <FormProvider {...methods}>
        <Form>
          <Header as="h4">
            {t(
                "An ECS check should only be carried out where you have a signed mandate from the parent or carer consenting to their details being verified by the Department for Work and Pensions (DWP), HMRC, and the Home office."
            )}
          </Header>

          <Dropdown
              id="eyfEYPPparConsentReq"
              label={t("Has the parent consented to eligibility check?")}
              name="parConsentReq"
              options={yesNoOptions}
              cb={(val) => {
                setParConsentReq(val);
              }}
          />

          {carer?.forename && (
              <p style={{ color: "gray" }}>
                Consenting Parent or Carer: {carer.forename} {carer.surname} DOB:- {carer.birthdate ? (
                  `${new Date(carer.birthdate).getDate()}/${new Date(carer.birthdate).getMonth() + 1}/${new Date(carer.birthdate).getFullYear()}`
              ) : null},
                {carer.niNo ? `NI: ${carer.niNo}` : carer.nassNo ? `NASS: ${carer.nassNo}` : "NI/NASS: - "}
              </p>
          )}
          <UserAccessComponent requires="PPORT_EYF_CHILD_ELIG_EYPP_ECS">
            {renderActionButton("Submit ECS Check", checkEcs, 'fa-solid fa-circle-question',
                parConsentReq !== 'Y',"purple"
            )}
            {lastEcsCheckDate && <p>Last ECS Check Date: {`${new Date(lastEcsCheckDate).getDate()}/${new Date(lastEcsCheckDate).getMonth() + 1}/${new Date(
                lastEcsCheckDate
            ).getFullYear()}`}</p>}
            {showMessageBox(enableMessageBox,messageBoxColour,eligibilityHeader,eligibilityDescription1,eligibilityDescription2,eligibilitySubText,iconClass)}
          </UserAccessComponent>

        </Form>
      </FormProvider>
  );

  const renderDAFForm = () => (
      <FormProvider {...methods}>
        <Form>
          <Dropdown
              id="eyfDlaLetter"
              label={t("Has the child got DLA letter?")}
              name="dlaLetter"
              options={yesNoOptions}
              cb={(val) => {
                setDlaLetter(val);
              }}
          />

          <Form.Group widths="equal">
            <Dropdown
                id="eyfExtendedHours"
                label={t("Confirm child claiming funded/extended hours")}
                name="extendedHours"
                options={yesNoOptions}
                cb={(val) => {
                  setExtendedHours(val);
                }}
            />

            <Dropdown
                id="eyfParentDecInd"
                label={t("Has the parent consented to eligibility check?")}
                name="parConsentReq"
                options={yesNoOptions}
                cb={(val) => {
                  setParConsentReq(val);
                }}
            />
          </Form.Group>

          {isEmpty(FilesList) ? (
              <Form.Field style={{ color: "red" }}>
                <Form.Input
                    label={t("Please Upload File required document")}
                    type="file"
                    name={"file"}
                    onChange={(e) => {
                      methods.setValue("file", e.target.files[0]);
                      methods.trigger("file"); // Trigger validation for the 'file' field
                    }}
                />
                {methods.formState.errors.file && (
                    <div style={{ color: "red" }}>
                      {methods.formState.errors.file.message}
                    </div>
                )}
              </Form.Field>
          ) : (
              <FilesGrid
                  customStyles={{ height: "70px" }}
                  noCSV
                  actionsCell={({ row }) => (
                      <div style={{ textAlign: "center" }}>
                        <Popup
                            content={t("Download")}
                            trigger={
                              <Icon
                                  circular
                                  name="download"
                                  className={"dark-blue-border"}
                                  color="black"
                                  onClick={() => handleDownload(row.id, row.filename)}
                              />
                            }
                        />
                      </div>
                  )}
                  columns={[
                    {
                      key: "id",
                      name: t("File ID"),
                      sortable: true,
                      filterable: true,
                      filterRenderer: DefaultFilter,
                    },
                    {
                      key: "filename",
                      name: t("File Name"),
                      sortable: true,
                      filterable: true,
                      filterRenderer: DefaultFilter,
                    },
                    {
                      key: "application",
                      name: t("File Type"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DefaultFilter,
                    },
                    {
                      key: "docSize",
                      name: t("File Size"),
                      filterable: true,
                      sortable: true,
                      filterRenderer: DefaultFilter,
                    },
                  ]}
                  rows={FilesList}
              />
          )}
          <Message
              hidden={!uploadError}
              onDismiss={() => setUploadError("")}
              header={t("Upload Failed")}
              content={uploadError}
              negative
          />
          <br />
          <Form.Group widths="equal">
            <DateInput
                id="eyfEligStartDate"
                name="eligStartDate"
                label={t("Start Date")}
                cb={(val) => {
                  methods.setValue("eligEndDate", calculateDateToOneYear(val));
                }}
            />
            <DateInput
                id="eyfEligEndDate"
                name="eligEndDate"
                label={t("End Date")}
                readOnly
            />
          </Form.Group>
          <Form.Input
              name="laApproved"
              value={lookupCodeToValue(
                  yesNoOptionsLookUp,
                  methods.getValues()?.laApproved
              )}
              label={t("Approved by Local Authority")}
              id="eyfLaApproved"
              readOnly
          />
        </Form>
      </FormProvider>
  );

  const renderDISForm = () => (
      <>
        <FormProvider {...methods}>
          <Form>
            <Form.Group widths="equal">
              <Input
                  id="eyfClaimRefNo"
                  label={t("Reference Number")}
                  name="claimRefNo"
                  cb={(val) => {
                    methods.setValue('claimRefNo', val, { shouldValidate: true });
                  }}
              />
              <Dropdown
                  id="eyfDISParConsentReq"
                  label={t("Has the parent consented to eligibility check?")}
                  name="parConsentReq"
                  options={yesNoOptions}
                  cb={(val) => {
                    setParConsentReq(val);
                  }}
              />
            </Form.Group>

            <Header as="h4">
              {t(
                  "By clicking submit, a check will be carried out to validate this claim"
              )}
            </Header>

            <Form.Input
              width={8}
              name="laApproved"
              label={t("Approved by Local Authority")}
              value={lookupCodeToValue(
                yesNoOptionsLookUp,
                methods.getValues()?.laApproved
              )}
              id="eyfDisLaApproved"
              readOnly
            />
          </Form>
        </FormProvider>
      </>
  );
  return (
      <div>
        <Segment attached={"bottom"}>
          <DataGrid
              id={"eyfEligiblityLGrid"}
              t={t}
              actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <Popup
                        trigger={
                          <Icon
                              circular
                              link
                              color="dark-blue-border"
                              id={"eyfChildParentLEdit"}
                              name={"pencil"}
                              onClick={() => handleEdit(row)}
                          />
                        }
                        content={t("Edit")}
                    />
                  </div>
              )}
              columns={[
                {
                  key: "claimBasis",
                  name: t("Eligibility Type"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: eYFundingClaimBasis,
                },
                {
                  key: "claimRefNo",
                  name: t("Code"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "eligStartDate",
                  name: t("Start Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
                {
                  key: "eligEndDate",
                  name: t("End Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
                {
                  key: "gracePeriodDate",
                  name: t("Grace Period Date"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
                {
                  key: "claimCodeChecked",
                  name: t("Checked?"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: yesNoOptionsLookUp,
                },
                {
                  key: "laApproved",
                  name: t("Approved by LA?"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: yesNoOptionsLookUp,
                },
                {
                  key: "proofSeenDate",
                  name: t("Date Proof Seen?"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DateFilter,
                  formatter: DateFormatter,
                },
              ]}
              rows={rows}
          />
          <br />
          <UserAccessComponent requires="PPORT_EYF_CHILD_ELIG_NEW">
            <Button
                id={"eyfEligibilityAddBtn"}
                style={{ marginTop: ".5rem" }}
                onClick={() => setFundingEligibilityOpen(true)}
                disabled={eypChildId === "new" || (!!rows.length && !filterClaimBasis(eYFundingClaimBasis, rows).length)}
                className={"purple"}
            >
              {t("Add Eligibility")}
            </Button>
          </UserAccessComponent>
        </Segment>

        <Modal className={"dark-modal"} open={isFundingEligibilityOpen}>
          <Modal.Header className="modal-header">
            {renderEligibilityFormHeader()}
          </Modal.Header>
          <Modal.Content className="modal-content">
            {renderEligibilityForm(eYFundingClaimBasis)}
          </Modal.Content>
          <Modal.Actions className="modal-actions">
            <Button
              loading={saving}
              disabled={disableBack}
              id={"MlDBack"}
              onClick={() => {
                if (!!methods.getValues()?.id) {
                  handleCancel(false);
                  getFundingEligibility();
                } else if (claimBasis === "") {
                  handleCancel(false);
                  getFundingEligibility();
                } else {
                  methods.reset();
                  handleCancel(true);
                }
              }}
              className="orange"
            >
              <Icon name="arrow left circle" />
              {t('Back')}
            </Button>
            <UserAccessComponent requires={renderUserAccess()}>
              <ButtonSubmit
                id="EYFFEligibilityD"
                type="submit"
                useForm={false}
                loading={saving}
                content={t("Submit")}
                disabled={isDisabled}
                onClick={methods.handleSubmit(onSubmit)}
              />
            </UserAccessComponent>
          </Modal.Actions>
        </Modal>
      </div>
  );
}

export default EYFFundingEligibility;
