import { useEffect, useState } from "react";
import { Form, Grid, Menu, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { lookupCodeToValue } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import axios from "axios";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import moment from "moment";
import { isEmpty } from 'ramda';
import { useToasts } from "react-toast-notifications";

const yesNoValues = [
  { codeValue: "Y", codeDescription: "Yes" },
  { codeValue: "N", codeDescription: "No" }
];

function EarlyAssessmentDetailsTabs({ rows, fetchAssessmentDetails }) {
  const { lookups: eeeTermsAndConditions } = useLookup(6097);
  const { lookups: twoYearOldRegistration } = useLookup(6098);
  const { lookups: threeFourYearOldRegistration } = useLookup(6099);
  const { lookups: usingThePortalAttendance } = useLookup(6100);

  const { lookups: eeeClaimed } = useLookup(6101);
  const { lookups: earlyYearsTerm } = useLookup(6113);
  const { lookups: eeeAudit } = useLookup(6102);
  const { lookups: safeguardingDiscussed } = useLookup(6103);

  const { lookups: SENDLocalOfferUnderstood } = useLookup(6104);
  const { lookups: claimingDisabilityAccessFundDAF } = useLookup(6105);
  const { lookups: claimingEarlyYearsPupilPremiumEYPPFunding } = useLookup(
    6106
  );
  const { lookups: claimingFreeSchoolMealsFunding } = useLookup(6107);
  const { lookups: earlyStaffRoles } = useLookup(907);

  const {
    lookups: claimingInclusionSupportInEarlyYearsISEYFunding
  } = useLookup(6108);

  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState("eee");
  const [providerAgreementText, setProviderAgreementText] = useState("");
  const [laAgreementText, setLaAgreementText] = useState([]);
  const [infoSettingRoles, setInfoSettingRoles] = useState([]);
  const [earlyStaff, setEarlyStaff] = useState([]);
  const { addToast } = useToasts();

  const getPortalContent = () => {
    axios

      .get(
        `api/portal-content/PROV_PORTAL/Assessment Agreement Signature/Provider Agreement Text`
      )
      .then(({ data }) => {
        setProviderAgreementText(data);
      })
      .catch(console.log);
    axios
      .get(
        `api/portal-content/PROV_PORTAL/Assessment Agreement Signature/Provider Agreement Text`
      )
      .then(({ data }) => {
        setLaAgreementText(data);
      })
      .catch(console.log);
    axios
      .get(
        `/api/impulse-config/AGREE_SIGN`
      )
      .then(({ data }) => {
        if(data.active === 'Y'){
          setInfoSettingRoles(data);
        }
      })
      .catch(console.log);
  };
  useEffect(() => {
    if(rows.agreeEycPersonId) {
      axios
        .get(
          `/api/early-assessment-staff-role/${rows.agreeEycPersonId}/${rows.providerId}`
        )
        .then(({ data }) => {
          const agreeTypes = infoSettingRoles.infoText.split(",");
          const matchingRoles = data.filter(role => agreeTypes.includes(role.type));
          setEarlyStaff(matchingRoles);
        })
        .catch(console.log);
    }
  }, [rows]);

  const onSubmit = () => {

    const data = {
      agreeStaffId : earlyStaff[0].staffId,
      agreeStaffRole : earlyStaff[0].type,
      agreeEycPersonId : rows.agreeEycPersonId,
      agreementDate : moment().format("YYYY-MM-DD"),
      agreeEycDate : rows.agreeEycDate ? moment(rows.agreeEycDate).toJSON() : null

    }

    axios
      .put(`/api/early-assessment/${rows.id}`, data)
      .then(() => {
        addToast(`${t("Confirm Agreement")} ${t("has been successfully updated")}`, {
          appearance: "success",
        });
        fetchAssessmentDetails();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Confirm Agreement")} ${t("has not been successfully updated")}`, {
          appearance: "error",
        });
      });
  };

  useEffect(() => {
    getPortalContent();
  }, []);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item
          name="eee"
          active={activeItem === "eee"}
          onClick={() => setActiveItem("eee")}
        >
          {t("Early Education Entitlement")}
        </Menu.Item>
        <Menu.Item
          name="safe"
          active={activeItem === "safe"}
          onClick={() => setActiveItem("safe")}
        >
          {t("Safeguarding")}
        </Menu.Item>
        <Menu.Item
          name="inclusion"
          active={activeItem === "inclusion"}
          onClick={() => setActiveItem("inclusion")}
        >
          {t("Inclusion")}
        </Menu.Item>
        <Menu.Item
          name="agree"
          active={activeItem === "agree"}
          onClick={() => setActiveItem("agree")}
        >
          {t("Agreement Signatures")}
        </Menu.Item>
      </Menu>
      {activeItem === "eee" && (
        <Form>
          <Segment attached="bottom">
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={8}
                  className={"padding-left-0 padding-right-10"}
                >
                  <Form.Input
                    label={t("EEE Terms and Conditions read and understood")}
                    readOnly
                    value={lookupCodeToValue(
                      eeeTermsAndConditions,
                      rows.eeeTAndC
                    )}
                    name="eeeTAndC"
                  />
                  <Form.Input
                    label={t("Registered for 2-year olds")}
                    readOnly
                    value={lookupCodeToValue(
                      twoYearOldRegistration,
                      rows.twoYearOlds
                    )}
                    name="twoYearOlds"
                  />
                  <Form.Input
                    label={t("Registered for 3- & 4-year olds")}
                    readOnly
                    value={lookupCodeToValue(
                      threeFourYearOldRegistration,
                      rows.threeFourYearOlds
                    )}
                    name="threeFourYearOlds"
                  />
                  <Form.Input
                    label={t(
                      "Have you attended a ‘Using the Portal – How to claim EEE’ session?"
                    )}
                    readOnly
                    value={lookupCodeToValue(
                      usingThePortalAttendance,
                      rows.eeeAttend
                    )}
                    name="eeeAttend"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    label={t("Have you claimed EEE")}
                    readOnly
                    value={lookupCodeToValue(eeeClaimed, rows.eeeClaimed)}
                    name="eeeClaimed"
                  />
                  {"YES" === rows.eeeAudit && (
                    <Form.Input
                      label={t("Term last claimed EEE")}
                      readOnly
                      value={lookupCodeToValue(earlyYearsTerm, rows.termCode)}
                      name="termCode"
                    />
                  )}
                  <Form.Input
                    label={t("Have you had a EEE Audit?")}
                    readOnly
                    value={lookupCodeToValue(eeeAudit, rows.eeeAudit)}
                    name="eeeAudit"
                  />
                  {"YES" === rows.eeeAudit && (
                    <Form.Input
                      label={t("Date of last EEE Audit")}
                      readOnly
                      value={rows.lastAuditDate}
                      name="lastAuditDate"
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      )}
      {activeItem === "safe" && (
        <Form>
          <Segment attached="bottom">
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Input
                    label={t("Safeguarding assessment discussed")}
                    readOnly
                    value={lookupCodeToValue(
                      safeguardingDiscussed,
                      rows.sgAssess
                    )}
                    name="sgAssess"
                  />
                  <Form.Input
                    label={t("Safeguarding Assessment Undertaken")}
                    readOnly
                    value={
                      rows.sgAssessDate
                          ? moment(rows.sgAssessDate).format("DD/MM/YYYY")
                          : ""
                    }
                    name="sgAssessDate"
                  />
                  <Form.Input
                    label={t("Safeguarding Assessment audit visit undertaken")}
                    readOnly
                    value={
                      rows.sgAuditVisitDate
                        ? moment(rows.sgAuditVisitDate).format("DD/MM/YYYY")
                        : ""
                    }
                    name="sgAuditVisitDate"
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    label={t("Right help, Right time explained and understood")}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.rhrtInd)}
                    name="rhrtInd"
                  />
                  <Form.Input
                    label={t("Named DSL identified")}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.dslNamedInd)}
                    name="dslNamedInd"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      )}
      {activeItem === "inclusion" && (
        <Form>
          <Segment attached="bottom">
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Input
                    label={t("SEND local offer read and understood?")}
                    readOnly
                    value={lookupCodeToValue(
                      SENDLocalOfferUnderstood,
                      rows.sendRAndU
                    )}
                    name="sendRAndU"
                  />
                  <Form.Input
                    label={t(
                      "Will you be claiming Disability Access Fund (DAF)?"
                    )}
                    readOnly
                    value={lookupCodeToValue(
                      claimingDisabilityAccessFundDAF,
                      rows.dafClaim
                    )}
                    name="sgAssessDate"
                  />
                  <Form.Input
                    label={t(
                      "Will you be claiming Early Years Pupil Premium (EYPP) funding?"
                    )}
                    readOnly
                    value={lookupCodeToValue(
                      claimingEarlyYearsPupilPremiumEYPPFunding,
                      rows.eyppClaim
                    )}
                    name="sgAuditVisitDate"
                  />
                  <Form.Input
                    label={t("Will you be claiming Free School Meals funding?")}
                    readOnly
                    value={lookupCodeToValue(
                      claimingFreeSchoolMealsFunding,
                      rows.fsmClaim
                    )}
                  />
                  <Form.Input
                    label={t(
                      "Will you be claiming Inclusion Support in Early Years (ISEY) funding?"
                    )}
                    readOnly
                    value={lookupCodeToValue(
                      claimingInclusionSupportInEarlyYearsISEYFunding,
                      rows.iseyClaim
                    )}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    label={t(
                      "Are you aware of the role of the setting based Special Educational Needs Coordinator (SENCO)?"
                    )}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.awareSencoInd)}
                  />
                  <Form.Input
                    label={t(
                      "Have you identified a named SENCO for your setting?"
                    )}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.sencoInd)}
                  />
                  <Form.Input
                    label={t(
                      "Awareness & implementation of responsibilities under the Equality Act 2010"
                    )}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.act2010Ind)}
                  />
                  <Form.Input
                    label={t(
                      "Narrowing the Gap of achievement of local/city wide position explained and understood"
                    )}
                    readOnly
                    value={lookupCodeToValue(yesNoValues, rows.achieveGapInd)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      )}
      {activeItem === "agree" && (
        <Form>
          <Segment attached="bottom">
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>{t(providerAgreementText.contentValue)}</label>
                  </Form.Field>
                  <Form.Input
                    label={t("Name")}
                    readOnly
                    value={
                      rows.agreeStaffPerson
                        ? rows.agreeStaffPerson.fullName
                        : ""
                    }
                  />
                  <Form.Input
                    label={t("Role at Provider")}
                    readOnly
                    value={lookupCodeToValue(
                      earlyStaffRoles,
                      rows.agreeStaffRole
                    )}
                  />
                  <Form.Input
                    label={t("Date")}
                    readOnly
                    value={
                      rows.agreementDate
                        ? moment(rows.agreementDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field>
                    <label>{t(laAgreementText.contentValue)}</label>
                  </Form.Field>
                  <Form.Input
                    label={t("Name of EYC")}
                    readOnly
                    value={
                      rows.agreeEycPerson
                        ? rows.agreeEycPerson.fullName +
                          ` (${rows.agreeEycPerson.personId})`
                        : null
                    }
                  />
                  <Form.Input
                    label={t("Date")}
                    readOnly
                    value={
                      rows.agreeEycDate
                        ? moment(rows.agreeEycDate).format("DD/MM/YYYY")
                        : ""
                    }
                  />
                </Grid.Column>
              </Grid.Row>
              <br />
              {!rows.agreementDate && !isEmpty(infoSettingRoles) && !isEmpty(earlyStaff) && (
                <DeleteConfirmationModal
                  message={t(
                    "Are you sure you wish to confirm this agreement message"
                  )}
                  onDelete={() => onSubmit()}
                  buttonTitle={t("Confirm Agreement")}
                  content={"Confirm"}
                  buttonColor="green"
                  color="white"
                  iconName="check circle"
                  confirmTitle={t("Confirm Agreement")}
                  yes={"Yes"}
                  no={"No"}
                />
              )}
            </Grid>
          </Segment>
        </Form>
      )}
    </>
  );
}

export default EarlyAssessmentDetailsTabs;
