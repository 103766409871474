import { Container, Grid, Icon, Menu, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import EYFChildDetails from "./EYFChildDetails";
import EYFParentDetails from "./EYFParentDetails";
import EYFChildDocs from "./EYFChildDocs";
import EYFFundingEligibility from "./EYFFundingEligibility";
import EarlyYearsSendScreen from "./EarlyYearsSendScreen";
import UserAccessComponent from "../../components/UserAccessComponent";
import {useParams} from "react-router-dom";

const EYFChildDetailsScreen = ({tab}) => {
  const { t } = useTranslation();

  const [activeItem, setActiveItem] = useState("parents");
  const [carerList, setCarerList] = useState([]);
  const [childDob, setChildDob] = useState("");
  const [eySenCode, setEySenCode] = useState("");
  const { eypChildId } = useParams();

 const updateCarer = (carerList) => {
   setCarerList(carerList);
  }

  useEffect(() => {
    setEySenCode(eySenCode);
  }, [eySenCode]);

  return (
    <div>
      <Container fluid>
        <Grid stackable>
          <Grid.Row className={"padding-top-0"}>
            <Grid.Column
              width={4}
              className={"padding-left-0 padding-right-10"}
            >
              <EYFChildDetails
                setChildDob={setChildDob}
                setEySenCode={setEySenCode}
                activeTab={tab}/>
            </Grid.Column>
            <Grid.Column
              width={12}
              className={"padding-left-0 padding-right-10"}
            >
              <Menu attached="top" className={"border-bottom"}>
                <Menu.Item
                  id={"eyfParentTab"}
                  active={activeItem === "parents"}
                  onClick={() => setActiveItem("parents")}
                >
                  <Icon>
                    <i className="fa-solid fa-person"></i>
                  </Icon>
                  {t("Parent/Carer Details")}
                </Menu.Item>
                <UserAccessComponent requires="PPORT_EYF_CHILD_ELIG">
                <Menu.Item
                    id={"eyfFundingTab"}
                    active={activeItem === "funding"}
                    onClick={() => setActiveItem("funding")}
                >
                  <Icon>
                    <i className="fa-solid fa-clipboard-check"/>
                  </Icon>
                  {t("Funding Eligibility")}
                </Menu.Item>
                </UserAccessComponent>
                <Menu.Item
                  id={"eyfDocumentsTab"}
                  active={activeItem === "documents"}
                  onClick={() => setActiveItem("documents")}
                >
                  <Icon>
                    <i className="fa-regular fa-file-magnifying-glass"></i>
                  </Icon>
                  {t("Documents")}
                </Menu.Item>
              </Menu>
              {activeItem === "parents" && <EYFParentDetails updateCarer={updateCarer}/>}
              {activeItem === "funding" && <EYFFundingEligibility childDob={childDob} carer={carerList.find(x=> x?.primaryCarerInd === 'Y')} Dropdowns={Dropdown} childId={eypChildId}/>}
              {activeItem === "documents" && <EYFChildDocs />}
              <br/>
              {eySenCode === 'Y' && (
                <EarlyYearsSendScreen
                  birthdate={childDob}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default EYFChildDetailsScreen;
