import moment from "moment";

export const handleFormValidationError = setError => error => {
  if (
    error.response.status === 400 &&
    error.response.data.type === "server" &&
    !!error.response.data.fieldName &&
    !!error.response.data.message
  ) {
    setError(error.response.data.fieldName, {
      type: error.response.data.type,
      message: error.response.data.message
    });
    return true;
  }
  return false;
};

export const lookupCodeToValue = (lookupCodes = [], lookupCode = "") => {
  const found = lookupCodes.find(code => code.codeValue === lookupCode);
  return !!found ? found.codeDescription : "";
};

export const yesNoOptions = [
  { key: "Y", value: "Y", text: "Yes" },
  { key: "N", value: "N", text: "No" }
];
export const yesNoFilterOptions = [
  { key: "All", value: "All", text: "All" },
  { key: "Y", value: "Y", text: "Yes" },
  { key: "N", value: "N", text: "No" }
];
export const fundingFlagOptions = [
  { key: "All", value: "All", text: "All" },
  { key: "EYPP", value: "EYPP", text: "EYPP" },
  { key: "DAF", value: "DAF", text: "DAF" },
  { key: "EAL", value: "EAL", text: "EAL" }
];
export const yesNoOptionsLookUp = [
  { codeValue: "Y", codeDescription:"Yes" },
  { codeValue: "N", codeDescription: "No" },
];
export const lookupToOptionsTranslation = t => ({
  codeValue: value,
  codeDescription: text
}) => ({ value, text: t(text) });

export const formatDate = date => {
  if (!date) return "";

  return moment(date).format("DD/MM/YYYY");
};

export const getBrowserInfo = () => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${tem[1] || ""}`;
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(" ")
        .replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" v");
};

export const getValidationFlag = (validationFlag, defaultValue = "U") => {
  if (defaultValue === "D") return "D";

  switch (validationFlag) {
    case "1":
      return "I";
    case "2":
      return "D";
    case "3":
      return "U";
    default:
      return defaultValue;
  }
};

export const createSyncTrackPutObject = (trackId, validationFlag, additionalParams = {}) => ({
  headers: {
    "Content-Type": "application/json-patch+json",
  },
  params: {
    trackId: trackId,
    validationFlag: validationFlag,
    ...additionalParams
  },
});


export const coerceDateToMoment = date => {
  if (!date) return null;

  if (moment.isMoment(date)) return date;

  try {
    return moment(date);
  } catch (e) {
    console.log(e);
  }

  return moment(date, ["YYYY-MM-DD", "DD/MM/YYYY"]);
};

export const setValueHelper = (row = {}, setValue = () => {}, options = {}) => {
  const ObjectEntries = Object.entries(row);
  ObjectEntries.forEach(([key, value]) => setValue(key, value, options));
};

export const stripHtml = (html) => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};

/**
 * Adds trailing zeroes to a floating point number.
 *
 * @param num - the number to add zeroes to
 * @param length - number of zeroes to add
 */
export const addZeroes = (num, length) => {
  if (num) {
    const dec = String(num).split('.')[1];
    const len = dec && dec.length > length ? dec.length : length;
    return Number(num).toFixed(len);
  } else {
    return null;
  }
};

export const processFileUploadError = (error) => {
  if (error.response.status === 400) {
    return error.response.data;
  }
};

export const calculateDateToOneYear =(dateString) =>{
  // Convert the provided date string to a JavaScript Date object
  const startDate = new Date(dateString);

  // Check if the provided date is valid
  if (isNaN(startDate.getTime())) {
    throw new Error('Invalid date');
  }

  // Copy the start date to avoid modifying the original date
  const endDate = new Date(startDate);

  // Add one year to the start date
  endDate.setFullYear(startDate.getFullYear() + 1);

  // Format the end date to YYYY-MM-DD format
  const year = endDate.getFullYear();
  let month = endDate.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  let day = endDate.getDate();
  if (day < 10) {
    day = '0' + day;
  }

  // Return the formatted end date
  return `${year}-${month}-${day}`;
}


