import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as FipGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import useLookup from "../consumer/LookupConsumer";
import UserAccessComponent from "./UserAccessComponent";
import { Icon, Popup, Segment } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import FipHeaderCounts from "./FipHeaderCounts";

const group1Filter = val => ({ completedDate, obsoleteDate }) => {
  if (val === "open") return !completedDate && !obsoleteDate;
  if (val === "completed") return !!completedDate || !!obsoleteDate;
  return true;
};

const group2Filter = val => ({ actionArea }) => actionArea === val;

const group3Filter = val => ({ actionType }) => actionType === val;

const initialGroupState = {
  group1: {
    filterFunc: group1Filter,
    selected: "open" // default
  },
  group2: {
    filterFunc: group2Filter
  },
  group3: {
    filterFunc: group3Filter
  }
};

export const applyGroupFilter = (rows, groups = {}) => {
  const filters = Object.values(groups);
  return (
    filters.reduce((acc, f) => {
      if (!f.selected) return acc;

      return acc.filter(f.filterFunc(f.selected));
    }, rows) || []
  );
};

function FipGridScreen({ rows, setRow }) {
  const { t } = useTranslation();
  const { lookups: actionTypeLookups } = useLookup(6111);
  const { lookups: actionAreaLookups } = useLookup(6114);
  const history = useHistory();
  const { pathname } = useLocation();
  const [groups, setGroups] = useState(initialGroupState);

  return (
    <>
      <FipHeaderCounts
        rows={rows}
        setGroups={setGroups}
        groups={groups}
        actionTypes={actionTypeLookups}
        actionAreas={actionAreaLookups}
      />
      <Segment className="grid-container-550">
        <FipGrid
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <UserAccessComponent requires="PPORT_PM_FIP_L_EDIT">
                <Popup
                  content={t("Edit")}
                  trigger={
                    <Icon
                      circular
                      link
                      className={"dark-blue-border"}
                      color="black"
                      onClick={() => {
                        setRow(row);
                        history.push(`${pathname}/${row.id}`);
                      }}
                    ><i className="fas fa-search"/></Icon>
                  }
                />
              </UserAccessComponent>
            </div>
          )}
          columns={[
            {
              key: "id",
              name: t("Action ID"),
              sortable: true,
              filterable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "actionArea",
              name: t("Action Area"),
              sortable: true,
              filterable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: actionAreaLookups
            },
            {
              key: "actionType",
              name: t("Action Type"),
              sortable: true,
              filterable: true,
              filterRenderer: LookupFilter,
              formatter: LookupFormatter,
              lookups: actionTypeLookups
            },
            {
              key: "actionDate",
              name: t("Action Date"),
              sortable: true,
              filterable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            },
            {
              key: "actionText",
              name: t("Action"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "owner",
              name: t("Action Owner"),
              sortable: true,
              filterable: true,
              filterRenderer: DefaultFilter
            },
            {
              key: "agreedInpDate",
              name: t("Agreed Implementation Date"),
              sortable: true,
              filterable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            },
            {
              key: "completedDate",
              name: t("Completed Date"),
              sortable: true,
              filterable: true,
              filterRenderer: DateFilter,
              formatter: DateFormatter
            }
          ]}
          rows={applyGroupFilter(rows, groups)}
        />
      </Segment>
    </>
  );
}

export default FipGridScreen;
