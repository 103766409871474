import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Segment,
  Header,
} from "semantic-ui-react";
import { Input,  TextArea } from "@caci/react-form-fields";
import {
  createSyncTrackPutObject,
  getValidationFlag,
  setValueHelper,
} from "../utils";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import React from "react";
import { useTranslation } from "react-i18next";
import LinkAddressModal from "./LinkAddressModal";
import LinkAddressSearchModal from "./LinkAddressSearchModal";
import PersonModal from "./PersonModal";

const addressTextFormatter = (row) =>
  (row.unitName ? row.unitName + "\n" : "") +
  (row.houseNo ? row.houseNo + "\n" : "") +
  (row.street ? row.street + "\n" : "") +
  (row.mainRoad ? row.mainRoad + "\n" : "") +
  (row.district ? row.district + "\n" : "") +
  (row.town ? row.town + "\n" : "") +
  (row.postcode ? row.postcode + "\n" : "");

function ProviderContactDetails(row, setRow) {
  const { selectedProvider: provider, updateSelectedProvider: updateProvider } =
    useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [isAddressSearchModalOpen, setAddressSearchModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const schema = yup.object({});

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const setAddressId = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId}`)
      .then(({ data: address }) => {
        addToast(`${t("Address")} ${t("has been successfully Linked")}`, {
          appearance: t("success"),
        });
        setAddressSearchModalOpen(false);
        setValueHelper(
          { addressId2: addressId, addressText: addressTextFormatter(address) },
          methods.setValue
        );
      })
      .then(console.log);
  };
  const onSubmit = ({ personId2, addressId2, validationFlag, trackId }) => {
    const put = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag),
      },
      { op: "add", path: "/personId2", value: personId2 },
      { op: "add", path: "/addressId2", value: addressId2 },
    ];

    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));

    axios
      .put(`/api/pport-early-provider/${provider.providerId}`, put, putObject)
      .then(({ data }) => {
        addToast(
          `${t("Contact Name & Address")} ${t(
            "has been successfully updated"
          )}`,
          {
            appearance: t("success"),
          }
        );
        methods.setValue("id", data.id);
        if (data && Object.keys(data).length > 0) updateProvider(data);
        setSaving(false);
      })
      .catch(console.log);
  };

  const fetchAddress = () => {
    if (provider.addressId2) {
      axios
        .get(`/api/pport-address/${provider.addressId2}`)
        .then(({ data: row }) => {
          setValueHelper(
            {
              addressId2: row.addressId,
              addressText: addressTextFormatter(row),
            },
            methods.setValue
          );
        })
        .catch(console.log);
    }
  };

  const { reset } = methods;

  useEffect(() => {
    if (provider && Object.keys(provider).length > 0) {
      setValueHelper(provider, methods.setValue);
    }
    fetchAddress();
  }, [provider, provider.providerId]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Contact Details")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row stretched>
                <Grid.Column width={8}>
                  <Segment style={{ backgroundColor: "#eee" }}>
                    <Header as="h5" textAlign="center">
                      {t("Contact Name")}
                    </Header>
                    <Input label={t("Impulse ID")} name="personId2" />
                    <Input label={t("First Name")} name="forename" />
                    <Input label={t("Surname")} name="surname" />
                    <Button
                      type={"button"}
                      className={"light-blue"}
                      onClick={() => {
                        setIsNew(true);
                        setPersonModalOpen(true);
                      }}
                    >
                      <Icon name="fas fa-male" />
                      {t("New Person")}
                    </Button>
                    <Button
                      type={"button"}
                      className={"light-blue"}
                      onClick={() => {
                        setIsNew(false);
                        setPersonModalOpen(true);
                      }}
                    >
                      <Icon name="th" />
                      {t("View")}
                    </Button>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment style={{ backgroundColor: "#eee" }}>
                    <Header as="h5" textAlign="center">
                      {t("Contact Address")}
                    </Header>
                    <Form>
                      <Input
                        label={t("Address ID")}
                        name="addressId2"
                        readOnly
                      />
                      <TextArea
                        label={t("Address")}
                        name="addressText"
                        readOnly
                      />
                      <br />
                      <Button
                        type={"button"}
                        className={"light-blue"}
                        onClick={() => setAddressSearchModalOpen(true)}
                      >
                        <Icon name="fas fa-map-marker-alt" />
                        {t("Link Address")}
                      </Button>
                      <Button
                        type={"button"}
                        className={"light-blue"}
                        onClick={() => setAddressModalOpen(true)}
                      >
                        <Icon name="th" />
                        {t("View")}
                      </Button>
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <UserAccessComponent requires="PPORT_PM_PR_D_SUB">
                    <Button disabled={!methods.getValues()?.personId2 || !methods.getValues()?.addressId2} className={"green"} type="submit">
                      <Icon name="check circle" />
                      {t("Submit")}
                    </Button>
                  </UserAccessComponent>
                  <Button
                    className={"red"}
                    onClick={() => methods.reset()}
                  >
                    <Icon name="times circle" />
                    {t("Clear")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
      </Segment>
      <PersonModal
        isPersonModalOpen={personModalOpen}
        closePersonModal={() => setPersonModalOpen(false)}
        personId={provider.personId2}
        isNew={isNew}
        cb={({ personId, forename, surname, trackId }) =>
          setValueHelper(
            { personId2: personId, forename, surname, trackId },
            methods.setValue,
            {
              shouldValidate: true,
            }
          )
        }
      />
      <LinkAddressModal
        isAddressModalOpen={isAddressModalOpen}
        closePersonModal={() => {
          setAddressModalOpen(false);
          fetchAddress();
        }}
        addressId={provider.addressId2}
        cb={({ addressId }) =>
          setValueHelper({ addressId2: addressId }, methods.setValue, {
            shouldValidate: true,
          })
        }
      />
      <LinkAddressSearchModal
        isAddressSearchModalOpen={isAddressSearchModalOpen}
        closeAddressSerchModal={() => setAddressSearchModalOpen(false)}
        fetchAddress={fetchAddress}
        setAddressId={setAddressId}
      />
    </>
  );
}

export default ProviderContactDetails;
