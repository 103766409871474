import { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  autoPopulateWeeksOnUpdatedRow, calcTotalFundedHours,
  fundingValidations,
  submitEstimateValidations, validateFundingAges
} from "./FundingUtils";
import useFundingFilters from "./useFundingFilters";
import useLookup from "../../consumer/LookupConsumer";

const useFundingGrid = (providerId, getApi, rowKeyGetter, filters, submissionType) => {
  const [rows, setRows] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [validationErrors] = useState({});
  const [configValues, setConfigValues] = useState({});
  const [searching, setSearching] = useState(true);
  const myConfigRef = useRef(null);
  const [rowsChanged, setRowsChanged] = useState(false);
  const [edits, setEdits] = useState({});
  const { columns, filteredRows } = useFundingFilters(rows, filters);
  const [comments, setComments] = useState(null);
  const {lookups: eyPeriodDesc } = useLookup(6202);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (dataFetched) {
      getSubmissionStatus();
      setDataFetched(false);  // Reset dataFetched to false
      if(rows.length > 0){
        rows.forEach((row) => {
          validateFundingAges(row, validationErrors, row.id);
        })
      }
    }
  }, [dataFetched]);

  const handleGridRowsUpdated = (updatedRows, data) => {
    const updatedRow = updatedRows[data.indexes[0]];
    // const updatedColumn = data.column;
    const updatedRowId = rowKeyGetter(updatedRow);

    // Updating the row with the calculated funded weeks and funded hours
    updatedRow.fundedWeeks = autoPopulateWeeksOnUpdatedRow(myConfigRef.current, updatedRow);
    updatedRow.totalFundedHours = calcTotalFundedHours(updatedRow);

    fundingValidations(
      validationErrors,
      updatedRow,
      myConfigRef.current
    );

    myConfigRef.current && submitEstimateValidations(updatedRow, validationErrors);

    // Update state after computing new rows
    setRowsChanged(true);

    setRows((prevRows) => {
      return prevRows.map((row) => {
        return rowKeyGetter(row) === updatedRowId ? updatedRow : row;
      });
    });

    setEdits({ ...edits, [updatedRowId]: updatedRow });
  };

  const fetchGridData = () => {
    axios
      .get(`${getApi}${providerId}`)
      .then((response) => {
        const updatedRows = response.data.rowData.map(row => {
          return { ...row, disableInclude: row.includeChild === 'Y' };
        });
        setRows(updatedRows);
        setReadOnly(response.data.readOnly);
        setComments(response.data.comments);
        if (response.data && response.data.validationValues) {
          const configValues = response.data.validationValues;
          setConfigValues({
            ...configValues,
            fundedWeeks: moment(configValues.configPeriodEndDate).diff(
              moment(configValues.configPeriodStartDate),
              "weeks"
            ),
          });
          myConfigRef.current = {
            ...configValues,
            fundedWeeks: moment(configValues.configPeriodEndDate).diff(
              moment(configValues.configPeriodStartDate),
              "weeks"
            ),
          };
        }
        setSearching(false);
        setDataFetched(true);  // Set dataFetched to true after rows are updated
      })
      .catch((error) => {
        console.log(error);
        setSearching(false);
      });
  };

  const getSelectedFundSubIds = () =>{
    // Extract the eypFundSubIds from the rowData
    const selectedEypFundSubIdsSet = new Set();
    rows.forEach(row => {
      if (row.eypFundSubId && row.eypFundSubId !== 0) {
        selectedEypFundSubIdsSet.add(row.eypFundSubId);
      }
    });
    return selectedEypFundSubIdsSet;
  }
  const getSubmissionStatus = () => {
    const selectedEypFundSubIds = Array.from(getSelectedFundSubIds());
    if(selectedEypFundSubIds.length > 0 && !isNaN(selectedEypFundSubIds[0])){
      axios
        .get(`/api/ey-funding-submission-status/${submissionType}/${selectedEypFundSubIds[0]}`)
        .then(({data}) => {
          setSubmissionStatus(data.submissionStatus);
          setComments(data.comments);
        })
        .catch((error) => {
          console.log(error);
        });

    }

  }

  return {
    rows,
    setRows,
    edits,
    setEdits,
    readOnly,
    comments,
    submissionStatus,
    validationErrors,
    configValues,
    rowsChanged,
    setRowsChanged,
    columns,
    filteredRows,
    searching,
    setSearching,
    eyPeriodDesc,
    handleGridRowsUpdated,
    fetchGridData,
  };
};

export default useFundingGrid;
