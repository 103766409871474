import { useEffect, useState } from "react";
import { Button, Form, Icon, Modal } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import {
  getValidationFlag,
  lookupToOptionsTranslation,
  setValueHelper
} from "../utils";
import { useTranslation } from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import { Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { isEmpty } from "ramda";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "./UserAccessComponent";
import styled from "styled-components";
import RejectCommentsModal from "./RejectCommentsModal";
import { validationFlagsList } from "../private/StaffScreen";
import moment from "moment";

const ModalHeaderWrapper = styled(Modal.Header)`
  display: flex !important;
`;

const FlexItems = styled.div`
  flex: 1;
`;

const FlexItemsWrapper = styled(FlexItems)`
  display: flex;
  justify-content: flex-end;
`;

function PersonModal({
  isPersonModalOpen,
  closePersonModal,
  personId,
  cb,
  isNew
}) {
  const { t } = useTranslation();
  const { lookups: titles } = useLookup(103);
  const { lookups: genders } = useLookup(5007);
  const { lookups: languages } = useLookup(102);
  const { lookups: ethnicities } = useLookup(101);
  const [saving, setSaving] = useState(false);
  const [person, setPerson] = useState({});
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);

  const schema = yup.object({
    forename: yup.string().required(t("Please enter a value")),
    surname: yup.string().required(t("Please enter a value")),
    gender: yup.string().required(t("Please enter a value")),
    email: yup
      .string()
      .email("Please enter a valid email")
      .nullable()
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = ({
    personId,
    title,
    forename,
    surname,
    birthdate,
    gender,
    language,
    ethnicity,
    email,
    mobile,
    workPhone,
    validationFlag,
    trackId
  }) => {
    const postData = {
      personId,
      title,
      forename,
      surname,
      birthdate,
      gender,
      language,
      ethnicity,
      email: email ? email : null,
      mobile: mobile ? mobile : null,
      workPhone: workPhone ? workPhone : null,
      validationFlag: personId ? getValidationFlag(validationFlag) : "I"
    };

    const params = {
      trackId: trackId,
      validationFlag: personId ? getValidationFlag(validationFlag) : "I"
    };

    const message = personId ? "updated" : "created";

    setSaving(true);

    axios
      .post(`/api/pport-person/track`, postData, { params })
      .then(({ data }) => {
        const cData = { ...data, birthdate: moment(data.birthdate).format("YYYY-MM-DD") };
        methods.setValue("personId", data?.personId);
        setSaving(false);
        addToast(`${t("Person")} ${t(`has been successfully ${message}`)}`, {
          appearance: "success"
        });
        if (!!cb) cb(cData);
        setPerson(cData);
        if (validationFlagsList.includes(data.validationFlag))
          setShowExclamation(true);
        else setShowExclamation(false);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Person")} ${t(`has not been ${message}`)}`, {
          appearance: "error"
        });
        setSaving(false);
      });
  };

  useEffect(() => {
    if (!isNew) {
      if (personId === person.personId || (!personId && !!person.personId)) {
        setValueHelper(person, methods.setValue);
      } else {
        axios
          .get(` /api/pport-person/${personId}/pport-person-contact`)
          .then(({ data }) => {
            if (!isEmpty(data)) {
              const cData = { ...data, birthdate: data.birthdate ? moment(data.birthdate).format("YYYY-MM-DD") : '', gender: data.gender === null ? "" :data.gender };
              setValueHelper(cData, methods.setValue);
              if (!!cb) cb(cData);
              setPerson(cData);
              if (validationFlagsList.includes(data.validationFlag))
                setShowExclamation(true);
            }
          })
          .catch(console.log);
      }
    } else if (isNew) {
      methods.reset({
        personId: "",
        title: "",
        forename: "",
        surname: "",
        birthdate: "",
        gender: "",
        language: "",
        ethnicity: "",
        email: "",
        mobile: "",
        workPhone: "",
        validationFlag: ""
      });
    }
  }, [personId, isNew, isPersonModalOpen]);

  return (
    <Modal
      open={isPersonModalOpen}
      size="small"
      centered={false}
      className="dark-modal"
    >
      <ModalHeaderWrapper className={"modal-header"}>
        <FlexItems>{t("Person Details")}</FlexItems>
        <FlexItemsWrapper>
          {showExclamation && (
            <>
              <UserAccessComponent requires="PPORT_PERSON_EDIT">
                <RejectCommentsModal
                  rejectComments={person.rejectComments}
                  rejectedDate={person.rejectedDate}
                  rejectedBy={person.rejectedBy}
                  color={"orange"}
                />
              </UserAccessComponent>
              <Icon color={"red"} style={{ paddingLeft: 5 }}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </>
          )}
        </FlexItemsWrapper>
      </ModalHeaderWrapper>
      <Modal.Content className={"modal-content"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Dropdown
              label={t("Title")}
              name="title"
              options={titles.map(lookupToOptionsTranslation(t))}
            />
            <Input label={t("First Name")} name="forename" />
            <Input label={t("Surname")} name="surname" />
            <Input name="birthdate" label={t("Birthdate")} type="date" />
            <Dropdown
              label={t("Gender")}
              name="gender"
              options={genders.map(lookupToOptionsTranslation(t))}
            />
            <Dropdown
              label={t("Language")}
              name="language"
              options={languages.map(lookupToOptionsTranslation(t))}
            />
            <Dropdown
              label={t("Ethnicity")}
              name="ethnicity"
              options={ethnicities.map(lookupToOptionsTranslation(t))}
            />
            <Input label={t("Email")} name="email" />
            <Input label={t("Mobile")} name="mobile" />
            <Input label={t("Work Phone")} name="workPhone" />
            <br />
            <Button
              onClick={() => {
                closePersonModal();
                methods.reset();
              }}
              className={"orange"}
            >
              <Icon name="times circle" />
              {t("Back")}
            </Button>
            <UserAccessComponent requires="PPORT_PERSON_SUB">
              <Button className={"green"} type="submit" disabled={saving}>
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Modal.Content>
    </Modal>
  );
}

export default PersonModal;
