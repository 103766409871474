import { useState, useEffect } from "react";
import { Menu, Segment, Icon, Popup, Button, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Grid as PickupDropOffGrid,
  DefaultFilter,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import { getValidationFlag, lookupToOptionsTranslation } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "@caci/react-form-fields";
import { setValueHelper } from "../utils";

const validationFlagsList = ["1", "2", "3"];


function TransportRouteScreen({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [rows, setRows] = useState([]);
  const [showExclamation, setShowExclamation] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { lookups: methodLookups } = useLookup(1095);
  const { addToast } = useToasts();
  const [nameLookupCodeType, setNameLookupCodeType] = useState("");
  const [saving, setSaving] = useState(false);

  const { lookups: nameLookups } = useLookup(nameLookupCodeType);

  const schema = yup.object({
    method: yup.string().required(t("Please enter a value")),
    name: yup.string().required(t("Please enter a value"))
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const handleDelete = row => {
    const data = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag, "D")
      }
    ];
    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      },
      params: {
        trackId: row.trackId,
        validationFlag: getValidationFlag(row.validationFlag, "D")
      }
    };

    axios
      .put(
        `/api/pport-early-public-route/${row.publicRouteId}`,
        data,
        putObject
      )
      .then(() => {
        addToast(
          `${t("Transport Route")} ${t("has been successfully removed")}`,
          {
            appearance: "success"
          }
        );
        setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Transport Route")} ${t("has not been removed")}`, {
          appearance: "error"
        });
      });
  };


  const onSubmit = ({ method, name, validationFlag, publicRouteId, trackId }) => {
    const postData = {
      method,
      name,
      validationFlag: "I",
      siteId: provider.siteId
    };

    const putData = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag)
      },
      { op: "add", path: "/method", value: method },
      { op: "add", path: "/name", value: name }
    ];
    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      },
      params: {
        trackId: trackId,
        validationFlag: getValidationFlag(validationFlag)
      }
    };

    setSaving(true);

    if (publicRouteId) {
      axios
        .put(`/api/pport-early-public-route/${publicRouteId}`, putData, putObject)
        .then(() => {
          addToast(
            `${t("Transport Route")} ${t(
              "has been successfully updated"
            )}`,
            {
              appearance: "success"
            }
          );
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Transport Route")} ${t(
              "has not been updated"
            )}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-public-route`, postData)
        .then(({ data: { publicRouteId } }) => {
          addToast(
            `${t("Transport Route")} ${t(
              "has been successfully created"
            )}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("publicRouteId", publicRouteId);
          setSaving(false);
        })
        .catch(error => {
          console.log(error);
          addToast(
            `${t("Transport Route")} ${t(
              "has not been created"
            )}`,
            {
              appearance: "error"
            }
          );
          setSaving(false);
        });
    }
  };
  const fetchPickups = () => {
    if (!showDetail)
      axios
          .get(`/api/pport-early-public-route-list/${provider.siteId}`)
          .then(({ data: rows }) => {
            setRows(rows);
            const validationFlagIndicator = rows.some(row =>
                validationFlagsList.includes(row.validationFlag)
            );
            setValidationFlag(urlSlug, {
              name: "transportRoute",
              value: validationFlagIndicator
            });
            setShowExclamation(validationFlagIndicator);
          })
          .then(console.log);
  };

  useEffect(() => {
    if (provider.siteId) fetchPickups();
  }, [provider.siteId, showDetail, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Transport Routes")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <>
            <PickupDropOffGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_DT_TRN_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setShowDetail(true);
                            setValueHelper(row, methods.setValue);
                            setNameLookupCodeType(row.method);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_TRN_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_TRN_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Transport Route?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "method",
                  name: t("Method"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: methodLookups
                },
                {
                  key: "details",
                  name: t("Details"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter
                }
              ]}
              rows={rows}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_DT_TRN_L_ADD">
                <Button
                  onClick={() => setShowDetail(true)}
                  className={"purple"}
                >
                  <Icon>
                    <i className="fas fa-route" />
                  </Icon>
                  {t("New Transport Route")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Dropdown
                label={t("Method")}
                options={methodLookups.map(lookupToOptionsTranslation(t))}
                name="method"
                cb={val => {
                  setNameLookupCodeType(val);
                  methods.setValue('name', '');
                }}
              />
              <Dropdown
                label={t("Details")}
                options={nameLookups.map(lookupToOptionsTranslation(t))}
                name="name"
              />
              <br />
              <Button
                onClick={() => {
                  setShowDetail(false);
                  methods.reset();
                  fetchPickups();
                }}
                color="orange"
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_PM_DT_TRN_D_SUB">
                <Button className={"green"} type="submit" disabled={saving}>
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            </Form>
          </FormProvider>
        )}
      </Segment>
    </>
  );
}

export default TransportRouteScreen;
