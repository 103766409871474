import React from "react";
import FilterRenderer from "./FilterRenderer";
import { TextFilterInput } from "@caci/react-grid";

const FilterHeaderRenderer = (props) => {
  return (
    <FilterRenderer {...props}>
      {(filters, updateFilter) => (
        <TextFilterInput
          value={filters[props.columnKey]}
          onInputChange={(value) => {
            updateFilter(props.columnKey, value);
          }}
        />
      )}
    </FilterRenderer>
  );
};

export default FilterHeaderRenderer;

