import React from "react";
import {
  DefaultFilter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import useLookup from "../../consumer/LookupConsumer";

const AggReadOnlyGridView = ({ rows, t }) => {
  const { lookups: eyAgeRange } = useLookup(6214);
  return (
    <>
      <DataGrid
        id="_AggregateReadOnlyView"
        columns={[
          {
            key: "ageRange",
            name: t("Age"),
            filterable: true,
            sortable: true,
            filterRenderer: LookupFilter,
            lookups: eyAgeRange,
            formatter: LookupFormatter,
          },
          {
            key: "totalChild",
            name: t("No. of Children"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter,
          },
          {
            key: "totalExpHrs",
            name: t("Total Expanded Hours"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter,
          },
          {
            key: "totalUniHrs",
            name: t("Total Funded Hours"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter,
          },
          {
            key: "totalExtHrs",
            name: t("Total Extended Hours"),
            filterable: true,
            sortable: true,
            filterRenderer: DefaultFilter,
          },
        ]}
        rows={rows}
      />
    </>
  );
};

export default AggReadOnlyGridView;
