import React, { useState, useEffect } from "react";
import {
  Menu,
  Segment,
  Icon,
  Popup,
  Button,
  Form,
  Grid,
  Header,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Grid as AdditionalContactGrid,
  DefaultFilter,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import { createSyncTrackPutObject, getValidationFlag, lookupToOptionsTranslation } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import * as yup from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setValueHelper } from "../utils";
import LinkAddressModal from "./LinkAddressModal";
import LinkAddressSearchModal from "./LinkAddressSearchModal";
import { Input, Dropdown, TextArea } from "@caci/react-form-fields";
import PersonModal from "./PersonModal";

const validationFlagsList = ["1", "2", "3"];


const transformData = (rows) => {
  return rows.map((row) => ({
    ...row,
    PersonName: row.personId
      ? row.pportPerson.forename +
        " " +
        row.pportPerson.surname +
        " (" +
        row.pportPerson.personId +
        ")"
      : "",
    addressName: row.addressId
      ? row.pportAddress.town +
        " ," +
        row.pportAddress.street +
        "," +
        row.pportAddress.district +
        " ," +
        row.pportAddress.postcode +
        "," +
        row.pportAddress.mainRoad
      : " ",
  }));
};

function AdditionalContactsList({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [rows, setRows] = useState([]);
  const [personId, setPersonId] = useState({});
  const [showExclamation, setShowExclamation] = useState(false);
  const { selectedProvider: provider } = useSession();
  const { lookups: providerAdditionalContactType } = useLookup(6088);
  const { addToast } = useToasts();
  const [saving, setSaving] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [isAddressSearchModalOpen, setAddressSearchModalOpen] = useState(false);
  const { selectedProvider: address } = useSession();
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);

  const schema = yup.object({
    contactType: yup.string().required(t("Please enter a value")),
    personId: yup.string().required(t("Please enter a value")),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleDelete = (row) => {
    const data = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag, "D"),
      },
    ];
    const putObject = createSyncTrackPutObject(row.trackId, getValidationFlag(row.validationFlag, "D"));

    axios
      .put(`/api/pport-early-provider-contact/${row.id}`, data, putObject)
      .then(() => {
        addToast(
          `${t("Additional Contact")} ${t("has been successfully removed")}`,
          {
            appearance: "success",
          }
        );
        setRefetch(!refetch);
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Additional Contact")} ${t("has not been removed")}`, {
          appearance: "error",
        });
      });
  };

  const fetchAddress = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId ? addressId : provider.addressId}`)
      .then(({ data: rows }) => {
        methods.setValue(`addressId`, rows.addressId);
        methods.setValue(
          `addressText`,
          (rows.unitName ? rows.unitName + "\n" : "") +
            (rows.houseNo ? rows.houseNo + "\n" : "") +
            (rows.street ? rows.street + "\n" : "") +
            (rows.mainRoad ? rows.mainRoad + "\n" : "") +
            (rows.district ? rows.district + "\n" : "") +
            (rows.town ? rows.town + "\n" : "") +
            (rows.postcode ? rows.postcode + "\n" : "")
        );
      })
      .catch(console.log);
  };

  const onSubmit = ({
    id,
    personId,
    addressId,
    validationFlag,
    contactType,
    trackId
  }) => {
    const postData = {
      personId,
      addressId,
      providerId: provider.providerId,
      contactType: contactType,
      validationFlag: "I",
    };

    const putData = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag),
      },
      { op: "add", path: "/personId", value: personId },
      { op: "add", path: "/addressId", value: addressId },
      { op: "add", path: "/contactType", value: contactType },
    ];

    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));

    setSaving(true);

    if (id) {
      axios
        .put(`/api/pport-early-provider-contact/${id}`, putData, putObject)
        .then(() => {
          addToast(
            `${t("Additional Contact")} ${t("has been successfully updated")}`,
            {
              appearance: "success",
            }
          );

          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
          addToast(`${t("Additional Contact")} ${t("has not been updated")}`, {
            appearance: "error",
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`/api/pport-early-provider-contact`, postData)
        .then(({ data: { id } }) => {
          addToast(
            `${t("Additional Contact")} ${t("has been successfully created")}`,
            {
              appearance: "success",
            }
          );
          methods.setValue("id", id);
          setSaving(false);
        })
        .catch((error) => {
          console.log(error);
          addToast(`${t("Additional Contact")} ${t("has not been created")}`, {
            appearance: "error",
          });
          setSaving(false);
        });
    }
  };

  const setAddressId = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId}`)
      .then(({ data: address }) => {
        addToast(`${t("Address")} ${t("has been successfully Linked")}`, {
          appearance: t("success"),
        });
        setAddressSearchModalOpen(false);
        methods.setValue(`addressId`, address.addressId);
        methods.setValue(
          `addressText`,
          (address.unitName ? address.unitName + "\n" : "") +
            (address.houseNo ? address.houseNo + "\n" : "") +
            (address.street ? address.street + "\n" : "") +
            (address.mainRoad ? address.mainRoad + "\n" : "") +
            (address.district ? address.district + "\n" : "") +
            (address.town ? address.town + "\n" : "") +
            (address.postcode ? address.postcode + "\n" : "")
        );
      })
      .then(console.log);
  };

  const { reset } = methods;
  useEffect(() => {
    const fetchContacts = () => {
      axios
        .get(`/api/pport-early-provider-contact-list/${provider.providerId}`)
        .then(({ data: rows }) => {
          setRows(rows);
          const validationFlagIndicator = rows.some((row) =>
            validationFlagsList.includes(row.validationFlag)
          );
          setValidationFlag(urlSlug, {
            name: "additionalContact",
            value: validationFlagIndicator,
          });
          setShowExclamation(validationFlagIndicator);
        })
        .then(console.log);
    };
    if (provider.providerId && !showDetail) fetchContacts();
  }, [provider, provider.providerId, showDetail, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Additional Contact")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <>
            <AdditionalContactGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_PR_ADC_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setShowDetail(true);
                            const { pportPerson, actions, ...rest } = row;
                            setValueHelper(
                              {
                                ...rest,
                                forename: row.pportPerson
                                  ? row.pportPerson.forename
                                  : "",
                                surname: row.pportPerson
                                  ? row.pportPerson.surname
                                  : "",
                                addressText:
                                  (row.pportAddress?.unitName
                                    ? row.pportAddress.unitName + "\n"
                                    : "") +
                                  (row.pportAddress?.houseNo
                                    ? row.pportAddress.houseNo + "\n"
                                    : "") +
                                  (row.pportAddress?.street
                                    ? row.pportAddress.street + "\n"
                                    : "") +
                                  (row.pportAddress?.mainRoad
                                    ? row.pportAddress.mainRoad + "\n"
                                    : "") +
                                  (row.pportAddress?.district
                                    ? row.pportAddress.district + "\n"
                                    : "") +
                                  (row.pportAddress?.town
                                    ? row.pportAddress.town + "\n"
                                    : "") +
                                  (row.pportAddress?.postcode
                                    ? row.pportAddress.postcode + "\n"
                                    : ""),
                              },
                              methods.setValue
                            );
                            setPersonId(row);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_PR_ADC_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_PR_ADC_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Additional Contact?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "contactType",
                  name: t("Type"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: providerAdditionalContactType,
                },
                {
                  key: "PersonName",
                  name: t("Name"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "email",
                  name: t("Email"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "mobile",
                  name: t("Mobile"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "workPhone",
                  name: t("Work"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
                {
                  key: "addressName",
                  name: t("Address"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter,
                },
              ]}
              rows={transformData(rows)}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_PR_ADC_L_ADD">
                <Button
                  onClick={() => {
                    setIsNew(true);
                    setShowDetail(true);
                  }}
                  className={"purple"}
                  type="button"
                >
                  <Icon>
                    <i className="far fa-address-book" />
                  </Icon>
                  {t("New Contact")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <Segment attached={"bottom"}>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      <Dropdown
                        fluid
                        search
                        selection
                        label={t("Contact Type")}
                        name="contactType"
                        options={providerAdditionalContactType.map(
                          lookupToOptionsTranslation(t)
                        )}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row stretched>
                    <Grid.Column width={8}>
                      <Segment style={{ backgroundColor: "#eee" }}>
                        <Header as="h5" textAlign="center">
                          {t("Additional Contact")}
                        </Header>
                        <Input label={t("Impulse ID")} name="personId" />
                        <Input label={t("First Name")} name="forename" />
                        <Input label={t("Surname")} name="surname" />
                        <Button
                          className={"light-blue"}
                          onClick={() => {
                            setIsNew(true);
                            setPersonModalOpen(true);
                          }}
                          type="button"
                        >
                          <Icon name="fas fa-male" />
                          {t("New Person")}
                        </Button>
                        <Button
                          className={"light-blue"}
                          onClick={() => {
                            setIsNew(false);
                            setPersonModalOpen(true);
                          }}
                          type="button"
                          disabled={!methods.getValues()?.personId}
                        >
                          <Icon name="th" />
                          {t("View")}
                        </Button>
                      </Segment>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Segment style={{ backgroundColor: "#eee" }}>
                        <Header as="h5" textAlign="center">
                          {t("Additional Contact Address")}
                        </Header>
                        <Form>
                          <Input
                            label={t("Address ID")}
                            name="addressId"
                            readOnly
                          />
                          <TextArea
                            label={t("Address")}
                            name="addressText"
                            readOnly
                          />
                          <br />
                          <Button
                            className={"light-blue"}
                            onClick={() => setAddressSearchModalOpen(true)}
                            type="button"
                          >
                            <Icon name="fas fa-map-marker-alt" />
                            {t("Link Address")}
                          </Button>
                          <Button
                            className={"light-blue"}
                            onClick={() => setAddressModalOpen(true)}
                            type="button"
                          >
                            <Icon name="th" />
                            {t("View")}
                          </Button>
                        </Form>
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <Button
                        onClick={() => {
                          setShowDetail(false);
                          setPersonId({});
                          methods.reset();
                        }}
                        type="button"
                        color="orange"
                      >
                        <Icon name="arrow circle left" />
                        {t("Back")}
                      </Button>
                      <UserAccessComponent requires="PPORT_PM_PR_ADC_D_SUB">
                        <Button
                          disabled={
                            !methods.getValues()?.personId ||
                            !methods.getValues()?.addressId
                          }
                          className={"green"}
                          type="submit"
                        >
                          <Icon name="check circle" />
                          {t("Submit")}
                        </Button>
                      </UserAccessComponent>
                      <Button
                        onClick={() =>
                          methods.reset({
                            personId: "",
                            forename: "",
                            surname: "",
                            addressId: "",
                            addressText: "",
                          })
                        }
                        className={"red"}
                        type="button"
                      >
                        <Icon name="times circle" />
                        {t("Clear")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </FormProvider>
          </Segment>
        )}
        <PersonModal
          isPersonModalOpen={personModalOpen}
          closePersonModal={() => setPersonModalOpen(false)}
          personId={personId.personId}
          isNew={isNew}
          cb={({ personId, forename, surname, trackId }) =>
            setValueHelper({ personId, forename, surname, trackId }, methods.setValue, {
              shouldValidate: true,
            })
          }
        />
        <LinkAddressModal
          isAddressModalOpen={isAddressModalOpen}
          closePersonModal={() => {
            setAddressModalOpen(false)
          }}
          addressId={personId.addressId}
          cb={({ addressId }) =>
            setValueHelper({ addressId }, methods.setValue, {
              shouldValidate: true,
            })
          }
        />
        <LinkAddressSearchModal
          isAddressSearchModalOpen={isAddressSearchModalOpen}
          closeAddressSerchModal={() => setAddressSearchModalOpen(false)}
          fetchAddress={fetchAddress}
          setAddressId={setAddressId}
        />
      </Segment>
    </>
  );
}

export default AdditionalContactsList;
