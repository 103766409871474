import { Menu, Icon } from "semantic-ui-react";
import { useSession } from "../consumer/SessionConsumer";
import ProviderHeader from "../components/ProviderHeader";
import LoadingMessage from "../components/LoadingMessage";
import { useEffect, useContext, useReducer, useCallback } from "react";
import { NavLink, withRouter, Switch, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAccessContext } from "../context";
import UserAccessComponent from "../components/UserAccessComponent";
import ProviderScreen from "./ProviderScreen";

import providerTabsReducer, {
  initialState,
  SET_VALIDATION_FLAG,
  SET_VALIDATION_FLAG_DATA,
  SET_REJECTION_FLAG_LOADED,
} from "../reducer/providerTabsReducer";
import HoursScreen from "./HoursScreen";
import DetailsScreen from "./DetailsScreen";
import EarlyAssessmentTabScreen from "./EarlyAssessmentTabScreen";
import EarlyAssessmentDetailsScreen from "./EarlyAssessmentDetailsScreen";
import FipScreen from "./FipScreen";
import NotesOfVisitDetailsScreen from "./NotesOfVisitDetailsScreen";
import StaffScreen from "./StaffScreen";
import WellcommAssessmentScreen from "./WellcommAssessmentScreen";
import axios from "axios";
import TimelineScreen from "./TimelineScreen";
import EYFundingView from "./EYFunding/EYFundingView";

function ProviderView({ location, match, history }) {
  const { t } = useTranslation();
  const { loadingProviders } = useSession();
  const [state, dispatch] = useReducer(providerTabsReducer, initialState);
  const { userGroupAccess } = useContext(UserAccessContext);

  const { tabs, entriesRejectedTab } = state;

  const fetchRejectionFlags = useCallback(
    ({ urlSlug }) => {
      return axios
        .get(
          `/api/pport-early-provider/has-rejection-flag/${match.params.providerId}`,
          {
            params: {
              providerPath: urlSlug.replaceAll("-", "_").toUpperCase(),
            },
          }
        )
        .then(({ data: payload }) =>
          dispatch({ type: SET_VALIDATION_FLAG, urlSlug, payload })
        );
    },
    []
  );

  const setValidationFlag = useCallback((urlSlug, payload = {}) => {
    dispatch({type: SET_VALIDATION_FLAG_DATA, urlSlug, payload});
  }, []);

  useEffect(() => {
    let urlContainsSlug = false;
    const slugs = [];
    const ugCodes = userGroupAccess.map((ug) => ug.code);

    tabs.forEach((tab) => {
      if (ugCodes.includes(tab.userAccessCode)) slugs.push(tab.urlSlug);
    });

    for (let i = 0; i < slugs.length; i++) {
      if (location.pathname.includes(tabs[i].urlSlug)) {
        urlContainsSlug = true;
      }
    }

    if (!urlContainsSlug) {
      history.push(`${location.pathname}/${slugs.length > 0 ? slugs[0] : ""}`);
    }
  }, []);

  useEffect(() => {
    const ugCodes = userGroupAccess.map((ug) => ug.code);
    const promisesCollection = [];

    tabs.forEach((t) => {
      if (t.exclamation === "Y" && ugCodes.includes(t.userAccessCode)) {
        promisesCollection.push(fetchRejectionFlags({ urlSlug: t.urlSlug }));
      }
    });

    Promise.all(promisesCollection)
      .catch(console.log)
      .finally(() => dispatch({ type: SET_REJECTION_FLAG_LOADED }));
  }, [fetchRejectionFlags]);


  const fetchEYFHeader = () => {
    if (match.params.providerId) {
      axios
        .get(`/api/pport-eyf-header/${match.params.providerId}`)
        .then(({ data }) => {
          if (data.submissionStatus === "REJ") {
            const fundingTab = tabs.find((tab) => tab.tabName === "Funding");
            if (fundingTab) {
              dispatch({
                type: SET_VALIDATION_FLAG,
                urlSlug: fundingTab.urlSlug,
                payload: true,
              });
            }
          }
        })
        .catch(console.log);
    }
  };


  useEffect(() => {
    fetchEYFHeader();
  }, [match.params.providerId]);

  if (loadingProviders || !entriesRejectedTab.loaded) {
    return <LoadingMessage />;
  }

  return (
    <>
      <ProviderHeader />
      <Menu className={"tabs-menu"}>
        {tabs.map((tab, index) => (
          <UserAccessComponent requires={tab.userAccessCode} key={index}>
            <Menu.Item
              key={tab.id}
              as={NavLink}
              activeClassName="active"
              to={`/portal/${match.params.providerId}/provider/${tab.urlSlug}`}
            >
              <Icon>
                <i className={tab.icon} />
              </Icon>
              {t(tab.tabName)}
              {tab.exclamation === "Y" && entriesRejectedTab[tab.urlSlug] && (
                <i className="fas fa-exclamation-triangle tab-exclamation" />
              )}
            </Menu.Item>
          </UserAccessComponent>
        ))}
      </Menu>

      <Switch>
        <Route path={`${match.path}/provider-details-ext`}>
          <DetailsScreen
            setValidationFlag={setValidationFlag}
            urlSlug={"provider-details-ext"}
          />
        </Route>
        <Route path={`${match.path}/provider-hcp`} exact>
          <HoursScreen
            setValidationFlag={setValidationFlag}
            urlSlug={"provider-hcp"}
          />
        </Route>
        <Route path={`${match.path}/provider-details`} exact>
          <ProviderScreen
            setValidationFlag={setValidationFlag}
            urlSlug={"provider-details"}
          />
        </Route>
        <Route path={`${match.path}/provider-assessment`} exact>
          <EarlyAssessmentTabScreen />
        </Route>
        <Route path={`${match.path}/provider-assessment/:id`} exact>
          <EarlyAssessmentDetailsScreen />
        </Route>
        <Route path={`${match.path}/provider-assessment/:id/:visitId`}>
          <NotesOfVisitDetailsScreen setValidationFlag={setValidationFlag} />
        </Route>
        <Route path={`${match.path}/provider-fip`}>
          <FipScreen />
        </Route>
        <Route path={`${match.path}/provider-staff`}>
          <StaffScreen
            setValidationFlag={setValidationFlag}
            urlSlug={"provider-staff"}
          />
        </Route>
        <Route path={`${match.path}/provider-wellcomm`}>
          <WellcommAssessmentScreen />
        </Route>
        <Route path={`${match.path}/provider-timeline`}>
          <TimelineScreen />
        </Route>
        <Route path={`${match.path}/provider-funding`}>
          <EYFundingView />
        </Route>
      </Switch>
    </>
  );
}

export default withRouter(ProviderView);
