import { useState, useEffect } from "react";
import { Menu, Segment, Icon, Popup, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  Grid as PickupDropOffGrid,
  DefaultFilter,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import UserAccessComponent from "./UserAccessComponent";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { useSession } from "../consumer/SessionConsumer";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import RejectCommentsModal from "./RejectCommentsModal";
import { createSyncTrackPutObject, getValidationFlag } from "../utils";
import useLookup from "../consumer/LookupConsumer";
import EstablishmentPickupDropOffDetails from "./EstablishmentPickupDropOffDetails";

const validationFlagsList = ["1", "2", "3"];

function EstablishmentPickupDropOffScreen({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const [showDetail, setShowDetail] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [rows, setRows] = useState([]);
  const [showExclamation, setShowExclamation] = useState(false);
  const [row, setRow] = useState({});
  const { selectedProvider: provider } = useSession();
  const { lookups: leaNoLookups } = useLookup(308);
  const { addToast } = useToasts();

  const transformData = rows => {
    return rows.map(row => ({
      id: row.id,
      providerId: row.providerId,
      leaNo: row.leaNo?.toString(),
      dfeeNo: row.dfeeNo,
      estabName: row.pportEstablishment?.estabName,
      estabNameWithLeaNoAndDfeeNo: `${row.pportEstablishment?.estabName} (LA:${row.leaNo} DfE:${row.dfeeNo})`,
      validationFlag: row.validationFlag,
      rejectComments: row.rejectComments,
      rejectedDate: row.rejectedDate,
      rejectedBy: row.rejectedBy
    }));
  };

  const handleDelete = row => {
    const data = [
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(row.validationFlag, "D")
      }
    ];

    const putObject = createSyncTrackPutObject(row.trackId, getValidationFlag(row.validationFlag, "D"));

    axios
      .put(`/api/pport-early-pickup-drop-sch/${row.id}`, data, putObject)
      .then(() => {
        addToast(
          `${t("Establishment Pickup & Drop Off")} ${t(
            "has been successfully removed"
          )}`,
          {
            appearance: "success"
          }
        );
        setRefetch(!refetch);
      })
      .catch(error => {
        console.log(error);
        addToast(
          `${t("Establishment Pickup & Drop Off")} ${t(
            "has not been removed"
          )}`,
          {
            appearance: "error"
          }
        );
      });
  };

  useEffect(() => {
    const fetchPickups = () => {
      if (!showDetail)
        axios
          .get(`/api/pport-early-pickup-drop-sch-list/${provider.providerId}`)
          .then(({ data: rows }) => {
            setRows(rows);
            const validationFlagIndicator = rows.some(row =>
              validationFlagsList.includes(row.validationFlag)
            );
            setValidationFlag(urlSlug, {
              name: "establishmentPickupDropOff",
              value: validationFlagIndicator
            });
            setShowExclamation(validationFlagIndicator);
          })
          .then(console.log);
    };
    if (provider.providerId) fetchPickups();
  }, [provider.providerId, showDetail, refetch]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Establishment Pickup & Drop Off")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <>
            <PickupDropOffGrid
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  <UserAccessComponent requires="PPORT_PM_DT_PUD_E_L_EDIT">
                    <Popup
                      content={t("Edit")}
                      trigger={
                        <Icon
                          circular
                          name="pencil"
                          className={"dark-blue-border"}
                          color="black"
                          onClick={() => {
                            setRow(row);
                            setShowDetail(true);
                          }}
                        />
                      }
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_PUD_E_L_EDIT">
                    <RejectCommentsModal
                      rejectComments={row.rejectComments}
                      rejectedDate={row.rejectedDate}
                      rejectedBy={row.rejectedBy}
                      disabled={
                        !validationFlagsList.includes(row.validationFlag)
                      }
                      circular
                    />
                  </UserAccessComponent>

                  <UserAccessComponent requires="PPORT_PM_DT_PUD_E_L_DEL">
                    <DeleteConfirmationModal
                      message={t(
                        "Are you sure you want to delete this Establishment Pickup & Drop Off?"
                      )}
                      onDelete={() => handleDelete(row)}
                      iconName="trash alternate outline"
                      color="red-border"
                      circular
                    />
                  </UserAccessComponent>
                </div>
              )}
              columns={[
                {
                  key: "leaNo",
                  name: t("LA"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: LookupFilter,
                  formatter: LookupFormatter,
                  lookups: leaNoLookups
                },
                {
                  key: "dfeeNo",
                  name: t("DfE"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter
                },
                {
                  key: "estabName",
                  name: t("Establishment Name"),
                  filterable: true,
                  sortable: true,
                  filterRenderer: DefaultFilter
                }
              ]}
              rows={transformData(rows)}
            />
            <div className={"gap-btw-grid-buttons"}>
              <UserAccessComponent requires="PPORT_PM_DT_PUD_E_L_ADD">
                <Button
                  onClick={() => setShowDetail(true)}
                  className={"purple"}
                >
                  <Icon>
                    <i className="fas fa-school" />
                  </Icon>
                  {t("New Establishment")}
                </Button>
              </UserAccessComponent>
            </div>
          </>
        ) : (
          <EstablishmentPickupDropOffDetails
            row={row}
            onBack={() => {
                setShowDetail(false);
                setRow({});
            }}
            estabValid={transformData(rows).map(r => r.estabNameWithLeaNoAndDfeeNo)}
          />
        )}
      </Segment>
    </>
  );
}

export default EstablishmentPickupDropOffScreen;
