import React from 'react';
import { Modal, Button, Icon } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';

const SaveOrContinueModal = ({ isModalOpen, onClose, modalHeader, message, goBack, onSaveAndContinue, onContinueWithoutSaving, saveAndContinueLabel,  continueWithoutSaveLabel}) => {
  const { t } = useTranslation();

  return (
    <Modal open={isModalOpen} onClose={onClose} className={'dark-modal'}>
      <Modal.Header className={'modal-header'}>{!modalHeader ? t('Save') : t(modalHeader)}</Modal.Header>
      <Modal.Content image className="modal-content">
        <Modal.Description>
          <p className={'text-white-color'} style={{ whiteSpace: 'pre-line' }}>{t(message)}</p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modal-actions" style={{ textAlign: 'left' }}>
        <Button id={'childDBackBtn'} onClick={goBack} className={'orange'}>
          <Icon name="arrow circle left" />
          {t('Back')}
        </Button>
        <Button color="green" onClick={onSaveAndContinue}>
          <Icon name="checkmark" />{t(saveAndContinueLabel)}
        </Button>
        <Button color="green" onClick={onContinueWithoutSaving} >
          <Icon name="fa-solid fa-circle-arrow-right" />{t(continueWithoutSaveLabel)}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SaveOrContinueModal;
