import { useFilters } from "./FilterContext";
import React from 'react';

const  FilterRenderer = ({ column, children }) => {
  const { filters, updateFilter } = useFilters();

  return (
    <>
      <div>{column.name}</div>
      {filters.enabled && <div>{children(filters, updateFilter)}</div>}
    </>
  );
}
export default FilterRenderer;