import React, { useEffect, useState } from "react";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import UserAccessComponent from "../../components/UserAccessComponent";
import { ButtonNewChild, ButtonSubmit } from "@caci/react-form-fields";
import { useTranslation } from "react-i18next";
import { Grid as DataGrid } from "@caci/react-grid";
import {
  autoPopulateWeeksOnUpdatedRow, calcTotalFundedHours,
  ESTIMATE_USER_MESSAGES, FUNDING_ERROR_MESSAGES, fundingValidations,
  submitEstimateValidations, transformRowDataForPayload
} from "./FundingUtils";
import { useToasts } from "react-toast-notifications";
import ApplyConfirmationModal from "./ApplyConfirmationModal";
import SaveOrContinueModal from "./SaveOrContinueModal";
import EstimateBulkEditModal from "./EstimateBulkEditModal";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/styles/customEditor.css";
import axios from "axios";
import AggregateGridView from "./AggregateGridView";
import ReadOnlyGridView from "./ReadOnlyGridView";
import useFundingGrid from "./useFundingGrid";
import { useSession } from "../../consumer/SessionConsumer";
import { useFilters } from "../../filters/FilterContext";
import CommentsModal from "./CommentsModal";
import LoadingMessage from "../../components/LoadingMessage";
import { lookupCodeToValue } from "../../utils";

const Estimates = () => {
  const [isOpenSubmitEstimateConfirmModal, setIsOpenSubmitEstimateConfirmModal] = useState(false);
  const { fetchProviders } = useSession();
  const [isOpenSaveSubmissionModal, setIsOpenSaveSubmissionModal] = useState(false);
  const [isEstimateAggregate, setIsEstimateAggregate] = useState(false);
  const [showBulkAction, setShowBulkAction] = useState(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const [saving, setSaving] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState(new Set());
  const [modalType, setModalType] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [ commentsModal, setCommentsModal ] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();
  const { providerId } = useParams();
  const { filters } = useFilters();

  const handleRowKeyGetter = (row) => {
    return row.id;
  }

  const {
    rows,
    setRows,
    edits,
    setEdits,
    readOnly,
    searching,
    setSearching,
    comments,
    submissionStatus,
    validationErrors,
    configValues,
    rowsChanged,
    setRowsChanged,
    columns,
    filteredRows,
    eyPeriodDesc,
    handleGridRowsUpdated,
    fetchGridData
  } = useFundingGrid(providerId, `/api/ey-funding-estimates/`, handleRowKeyGetter, filters, 'EST')

  const checkEstimateScreenLevel = () => {
    axios
      .get(`/api/impulse-config/EYF_EST_AGG`)
      .then(({ data }) => {
        if(data.active === 'Y'){
          setIsEstimateAggregate(data.active === "Y");
        }
        else {
          fetchGridData();
        }
        setSearching(false);
      })
      .catch((error) => {
        setSearching(false);
        console.log(error);
      });
  };

  useEffect(() => {
    checkEstimateScreenLevel();
    }, [providerId]);


  useEffect(() => {
    if (!rowsChanged) {
      onContinueWithoutSaving();
    }
  }, [modalType, rowsChanged]);

  // Function to update the state variable to true
  const onSaveEstimate = () => {
    setSaveTriggered(true);
      let includeChildExists = false; // Variable to track if includeChild is 'Y' in any row

      for (let row of rows) {
        if (row.includeChild === 'Y') {
          includeChildExists = true;
          break;
        }
      }

      if (!includeChildExists) {
        // Show error message if no rows have includeChild as 'Y'
        addToast(
          t(FUNDING_ERROR_MESSAGES.saveError),{
          appearance: "error",
      });
        toggleModal("isOpenSaveSubmissionModal", false);
        return;
      }
    rows.forEach((row) => {
      fundingValidations(validationErrors, row, configValues );
      submitEstimateValidations(row, validationErrors);
    });

    if (Object.keys(validationErrors).length === 0) {
      sendRequestTo("SAVE");
    } else {
      addToast(t(ESTIMATE_USER_MESSAGES.saveError), {
        appearance: "error",
      });
      setSaveTriggered(true);
      setSaving(false);
    }
    toggleModal("isOpenSaveSubmissionModal", false);
  };

  const sendRequestTo = (type) => {
    let successMsg;
    let failureMsg;
    switch (type) {
      case "SAVE":
        successMsg = ESTIMATE_USER_MESSAGES.saveSuccess;
        failureMsg = ESTIMATE_USER_MESSAGES.saveError;
        break;
      case "SUBMIT":
        successMsg = ESTIMATE_USER_MESSAGES.submitSuccess;
        failureMsg = ESTIMATE_USER_MESSAGES.submitError;
        break;
      default:
        break;
    }
    const estimateEditData = transformRowDataForPayload(rows);
    const postObject = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setSaving(true);
    axios
      .post(
        `/api/ey-funding-estimates/${type}/${providerId}`,
        estimateEditData,
        postObject
      )
      .then(() => {
        addToast(t(successMsg), {
          appearance: "success",
        });
        fetchProviders();
        setSaving(false);
        setRowsChanged(false);
      })
      .catch((error) => {
        console.log(error);
        addToast(
          t(failureMsg),
          {
            appearance: "error",
          }
        );
        checkEstimateScreenLevel();
        setSaving(false);
      });
  };

  const getRowClassName = (row) => {
    return Object.keys(validationErrors[row.id] || {}).length > 0 &&
      saveTriggered
      ? "row-style-me-grid"
      : "";
  };

  const toggleModal = (name, value) => {
    switch (name) {
      case "isOpenSubmitEstimateConfirmModal":
        setIsOpenSubmitEstimateConfirmModal(value);
        break;
      case "isOpenSaveSubmissionModal":
        setIsOpenSaveSubmissionModal(value);
        break;
      case "showBulkAction":
        setShowBulkAction(value);
        break;
      default:
        break;
    }
  };

  const handleAddEditViewChildClick = (type, row = null) => {
    setModalType(type);
    setCurrentRow(row);
    if (rowsChanged) {
      toggleModal("isOpenSaveSubmissionModal", true);
    }
  };

  const onContinueWithoutSaving = () => {
    let navigateUrl = "";

    switch (modalType) {
      case "ADD_CHILD":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/estimates/new`;
        break;
      case "EDIT_CHILD":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/estimates/${currentRow.id}`;
        break;
      case "ADD_SIBLING":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/estimates/sib${currentRow.id}`;
        break;
      default:
        return;
    }

    if (navigateUrl) {
      navigateToRoute(navigateUrl);
    }
  };

  const navigateToRoute = (route) => {
    history.push(route);
  };

  const handleToggle = (row) => {
    let updatedRow;
    const updatedRows = rows.map((rowObj) => {
      if (rowObj.id === row.id) {
        updatedRow = {
          ...rowObj,
          includeChild: rowObj.includeChild === "Y" ? "N" : "Y",
        };
        updatedRow.fundedWeeks = autoPopulateWeeksOnUpdatedRow(configValues, updatedRow);
        updatedRow.totalFundedHours = calcTotalFundedHours(updatedRow);
        fundingValidations(
          validationErrors,
          updatedRow,
          configValues
        );
        submitEstimateValidations(updatedRow, validationErrors);

        return updatedRow;
      }
      return rowObj;
    });
    setRows(updatedRows);
    setRowsChanged(true);
    setEdits({ ...edits, [row.id]: updatedRow });
  };

  const handleRowsSelected = (selectedRows) => {
    setSelectedIndexes(selectedRows);
  };

  const handleBulkEditUpdate = (updatedData) => {
    let editedRows = [];

    const updatedRows = rows.map((row) => {
      if (selectedIndexes.has(row.id)) {
        let bulkEditUpdatedRow = {
          ...row,
          expHrsPerWeek:
            updatedData.expHrsPerWeek !== undefined && updatedData.expHrsPerWeek !== ""
              ? parseFloat(updatedData.expHrsPerWeek)
              : row.expHrsPerWeek,
          uniHrsPerWeek:
            updatedData.uniHrsPerWeek !== undefined && updatedData.uniHrsPerWeek !== ""
              ? parseFloat(updatedData.uniHrsPerWeek)
              : row.uniHrsPerWeek,
          extHrsPerWeek:
            updatedData.extHrsPerWeek !== undefined && updatedData.extHrsPerWeek !== ""
              ? parseFloat(updatedData.extHrsPerWeek)
              : row.extHrsPerWeek,
          childFundEnd: updatedData.childFundEnd !== undefined ? updatedData.childFundEnd : row.childFundEnd,
          childFundStart: updatedData.childFundStart !== undefined ? updatedData.childFundStart : row.childFundStart,
          stretchedInd: updatedData.stretchedInd !== undefined ? updatedData.stretchedInd : row.stretchedInd,
          includeChild: updatedData.includeChild !== undefined ? updatedData.includeChild : row.includeChild,
        };
        bulkEditUpdatedRow.fundedWeeks = autoPopulateWeeksOnUpdatedRow(configValues, bulkEditUpdatedRow);
        bulkEditUpdatedRow.totalFundedHours = calcTotalFundedHours(bulkEditUpdatedRow);

        fundingValidations(
          validationErrors,
          bulkEditUpdatedRow,
          configValues
        );

        submitEstimateValidations(bulkEditUpdatedRow, validationErrors);

        editedRows.push(bulkEditUpdatedRow);
        return bulkEditUpdatedRow;
      } else {
        return row;
      }
    });

    const updatedEdits = editedRows.reduce(
      (acc, record) => {
        acc[record.id] = record;
        return acc;
      },
      { ...edits }
    );

    setRowsChanged(true);
    setRows(updatedRows);
    setEdits(updatedEdits);
  };


  const onSaveAndContinue = async () => {
    await onSaveEstimate();
    if (Object.keys(validationErrors).length === 0) {
      onContinueWithoutSaving();
    }
  };

  const onSubmitEstimate = () => {
    setSaveTriggered(true);
    let includeChildExists = false; // Variable to track if includeChild is 'Y' in any row

    for (let row of rows) {
      if (row.includeChild === 'Y') {
        includeChildExists = true;
        break;
      }
    }

    if (!includeChildExists) {
      // Show error message if no rows have includeChild as 'Y'
      addToast(
        t(FUNDING_ERROR_MESSAGES.submitError),{
          appearance: "error",
        });
      toggleModal("isOpenSubmitEstimateConfirmModal", false);
      return;
    }
    rows.forEach((row) => {
      fundingValidations(validationErrors, row, configValues );
      submitEstimateValidations(row, validationErrors);
    });
    let isError = Object.keys(validationErrors).length !== 0;
    if (isError) {
      addToast(t(ESTIMATE_USER_MESSAGES.submitError), {
        appearance: "error",
      });
    } else {
      sendRequestTo("SUBMIT");
    }

    toggleModal("isOpenSubmitEstimateConfirmModal", false);
  };


  const getDynamicApplyConfirmMsg = (periodDescription) => {
    return `You are about to Submit your Estimate for ${periodDescription}, once this has been submitted, you will not be able to edit this record.`;
  };

  const confirmMsg = getDynamicApplyConfirmMsg(lookupCodeToValue(eyPeriodDesc, configValues?.periodDescription));
  if (searching) return <LoadingMessage />;

  return (
    <div>
      <Segment attached="bottom">
        {isEstimateAggregate ? (
          <AggregateGridView providerId={providerId} t={t} configPeriodMsg={confirmMsg}/>
        ) : readOnly ? (
          <ReadOnlyGridView rows={rows} t={t} />
        ) : (
          <>
            {!searching && (
            <DataGrid
              id="_EstimateTabScreen"
              columns={columns}
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  {saveTriggered && validationErrors[row.id] && (
                    <Popup
                      trigger={
                        <Icon>
                          <i
                            className="fa-solid fa-circle-exclamation"
                            style={{ color: "#CF2A27" }}
                          />
                        </Icon>
                      }
                      content={Object.values(validationErrors[row.id]).join(
                        "\n"
                      )}
                    />
                  )}
                  <Popup
                    trigger={
                      <Icon
                        circular
                        link
                        color="dark-blue-border"
                        disabled={readOnly}
                        name={
                          row.includeChild === "Y"
                            ? "check circle"
                            : "remove circle"
                        }
                        onClick={() => handleToggle(row)}
                      />
                    }
                    content={t("Include in Estimate?")}
                  />
                  <UserAccessComponent
                    requires={"PPORT_EYF_EST_L_CHILD_SIB_ADD"}
                  >
                    <Popup
                      trigger={
                        <Icon
                          circular
                          link
                          style={{ border: "1px solid #8AC040" }}
                          onClick={() =>
                            handleAddEditViewChildClick("ADD_SIBLING", row)
                          }
                        >
                          <i
                            className="fa-solid fa-children"
                            style={{ color: "#8AC040" }}
                          />
                        </Icon>
                      }
                      content={t("Add Sibling")}
                    />
                  </UserAccessComponent>
                  <UserAccessComponent requires={"PPORT_EYF_EST_L_CHILD_EDIT"}>
                    <Popup
                      trigger={
                        <Icon
                          circular
                          link
                          name="pencil"
                          className={"dark-blue-border"}
                          onClick={() =>
                            handleAddEditViewChildClick("EDIT_CHILD", row)
                          }
                        />
                      }
                      content={t("Edit Child")}
                    />
                  </UserAccessComponent>
                </div>
              )}
              rows={filteredRows}
              enableRowSelection={true}
              enableFilters={filters.enabled}
              onRowsUpdated={handleGridRowsUpdated}
              rowClassNameGetter={getRowClassName}
              handleRowsSelected={handleRowsSelected}
              handleRowKeyGetter={handleRowKeyGetter}
            />
            )}
            <br />
            <UserAccessComponent requires="PPORT_EYF_EST_L_CHILD_ADD">
              <ButtonNewChild
                id={"estimateTabAddChildBtn"}
                as={Link}
                disabled={readOnly}
                useForm={false}
                content={t("Add Child")}
                onClick={() => handleAddEditViewChildClick("ADD_CHILD")}
                className={readOnly ? "grey" : "purple"}
                iconClassName={"fa-solid fa-child-reaching"}
              />
            </UserAccessComponent>

            <UserAccessComponent requires={"PPORT_EYF_EST_L_BULK_EDIT"}>
              <Button
                onClick={() => {
                  setShowBulkAction(true);
                }}
                disabled={selectedIndexes.size === 0 || readOnly}
                className={readOnly ? "grey" : "light-blue"}
              >
                <Icon>
                  <i className="fa-solid fa-people-group" />
                </Icon>
                {t("Bulk Edit")}
              </Button>
            </UserAccessComponent>
            {rowsChanged && (
              <UserAccessComponent requires={"PPORT_EYF_EST_L_SAVE"}>
                <Button onClick={onSaveEstimate} className={"green"} loading={saving}>
                  <Icon name="fa-solid fa-floppy-disk" />
                  {t("Save")}
                </Button>
              </UserAccessComponent>
            )}
            {rowsChanged && (
              <UserAccessComponent requires={"PPORT_EYF_EST_L_SUB"}>
                <Button
                  onClick={() =>
                    toggleModal("isOpenSubmitEstimateConfirmModal", true)
                  }
                  loading={saving}
                  className={readOnly ? "grey" : "green"}
                  disabled={readOnly}
                >
                  <Icon name="fa-solid fa-circle-check" />
                  {t("Submit Estimate")}
                </Button>
              </UserAccessComponent>
            )}
            <ApplyConfirmationModal
              modalHeader={"Submit Estimate"}
              message={confirmMsg}
              continueMsg={t(ESTIMATE_USER_MESSAGES.continueMsg)}
              isModalOpen={isOpenSubmitEstimateConfirmModal}
              onClose={() =>
                toggleModal("isOpenSubmitEstimateConfirmModal", false)
              }
              onConfirm={onSubmitEstimate}
              confirmText={t("Submit")}
            />
            <SaveOrContinueModal
              modalHeader={"Save Submission"}
              message={t(ESTIMATE_USER_MESSAGES.saveOrContinueMsg)}
              isModalOpen={isOpenSaveSubmissionModal}
              onClose={() => toggleModal("isOpenSaveSubmissionModal", false)}
              goBack={() => toggleModal("isOpenSaveSubmissionModal", false)}
              onSaveAndContinue={onSaveAndContinue}
              onContinueWithoutSaving={onContinueWithoutSaving}
              confirmText={t("Submit")}
              saveAndContinueLabel={"Save And Continue"}
              continueWithoutSaveLabel={"Continue Without Saving"}
            />
            <EstimateBulkEditModal
              isOpen={showBulkAction}
              closeBulkEditModal={() => toggleModal("showBulkAction", false)}
              configValues={configValues}
              onUpdate={handleBulkEditUpdate}
              saving={saving}
            />
          </>
        )}
        <>
        {comments && submissionStatus === "REJ" &&
          <UserAccessComponent requires="PPORT_EST_L_COMM">
            <ButtonSubmit
              id="providerSubEstimates"
              useForm={false}
              content={t('Comments')}
              buttonClassName={'red'}
              iconClassName={'fa-regular fa-message-lines'}
              disabled={false}
              loading={false}
              onClick={()=> setCommentsModal(true)}
            />
          </UserAccessComponent>
        }
        <CommentsModal
          modalHeader={'Reason for rejection'}
          message={comments}
          isModalOpen={commentsModal}
          onClose={()=> setCommentsModal(false)}
        />
        </>
      </Segment>
    </div>
  );
};

export default Estimates;
