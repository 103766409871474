import moment from "moment/moment";
import { Icon } from "semantic-ui-react";
export const FUNDING_ERROR_MESSAGES = {
  saveError: "Failure - Unable to Save - No rows are included for submission.",
  submitError: "Failure - Unable to Submit - No rows are included for submission."
}
export const ESTIMATE_USER_MESSAGES = {
  saveSuccess: "Success - Estimate Saved",
  saveError: "Failure - Unable to Save Estimate - validation error.",
  submitSuccess: "Success - Estimate Submitted",
  submitError: "Failure - Unable to Submit Estimate - validation error",
  applyConfirmMsg: "You are about to Submit your Estimate for (Funding Submission Period), once this has been submitted, you will not be able to edit this record.",
  continueMsg: "Do you wish to continue?",
  saveOrContinueMsg: "You are about to navigate away from the page without saving. Do you wish to continue ?",
};
export const HEADCOUNT_USER_MESSAGES = {
  saveSuccess: "Success - Headcount Saved",
  saveError: "Failure - Unable to Save Headcount - validation error.",
  submitSuccess: "Success - Headcount Submitted",
  submitError: "Failure - Unable to Submit Headcount - validation error",
  applyConfirmMsg: "You are about to Submit your Headcount for (Funding Submission Period), once this has been submitted, you will not be able to edit this record.",
  continueMsg: "Do you wish to continue?",
  saveOrContinueMsg: "You are about to navigate away from the page without saving. Do you wish to continue ?",
};

export const AMENDMENT_USER_MESSAGES = {
  saveSuccess: "Success - Amendment Saved",
  saveError: "Failure - Unable to Save Amendment - validation error.",
  submitSuccess: "Success - Amendment Submitted",
  submitError: "Failure - Unable to Submit Amendment - validation error",
  applyConfirmMsg: "You are about to Submit your Amendment for (Funding Submission Period), once this has been submitted, you will not be able to edit this record.",
  continueMsg: "Do you wish to continue?",
  saveOrContinueMsg: "You are about to navigate away from the page before submitting. Do you wish to continue ?",
};


// Define initial filters for each component
export const INITIAL_FILTERS = {
  'LIVE-REGISTER':{
    address: '' ,
    fundingType: 'All' ,
    childId: '' ,
    childName: '' ,
    dateOfBirth: '' ,
    fundingAge: '' ,
    childStartDate: '' ,
    childEndDate: '' ,
    ethnicity: '' ,
    language: '' ,
    deprivationIndex: '' ,
    responsibleLa: '' ,
    parentCarerName: '' ,
    personId: '' ,
    contactNo: '' ,
    email: '' ,
    stretchedInd: '',
    expHrsPerWeek: undefined,
    uniHrsPerWeek: undefined,
    extHrsPerWeek: undefined,
    fundedWeeks: undefined,
    totalFundingHours: undefined,
    flags: '',
    enabled: true
  },
  ESTIMATES: {
    id: '',
    childName: '',
    dob: '',
    fundingAge: '',
    stretchedInd: '',
    expHrsPerWeek: undefined,
    uniHrsPerWeek: undefined,
    extHrsPerWeek: undefined,
    fundedWeeks: undefined,
    totalFundedHours: undefined,
    childStartDate: '',
    childFundStart: '',
    childEndDate: '',
    childFundEnd: '',
    flags: '',
    includeChild:'All',
    enabled: true
  },
  HEADCOUNT: {
    id: '',
    childName: '',
    dob: '',
    fundingAge: '',
    stretchedInd: '',
    expHrsPerWeek: undefined,
    uniHrsPerWeek: undefined,
    extHrsPerWeek: undefined,
    fundedWeeks: undefined,
    totalFundedHours: undefined,
    childStartDate: '',
    childFundStart: '',
    childEndDate: '',
    childFundEnd: '',
    flags: '',
    includeChild:'All',
    enabled: true
  },
  AMENDMENT: {
    id: '',
    childName: '',
    dob: '',
    fundingAge: '',
    stretchedInd: '',
    expHrsPerWeek: undefined,
    uniHrsPerWeek: undefined,
    extHrsPerWeek: undefined,
    fundedWeeks: undefined,
    totalFundedHours: undefined,
    childStartDate: '',
    childFundStart: '',
    childEndDate: '',
    childFundEnd: '',
    flags: '',
    includeChild:'All',
    enabled: true
  },
  HISTORY: {
    enabled: false
  }
  // Add initial filters for other components as needed
};

export const fundingTypeList = [
  {
    "codeValue": "DIS2",
    "description": "DIS2",
    "color": "#45818E"
  },
  {
    "codeValue": "CODE",
    "description": "CODE",
    "color": "#BF9000"
  },
  {
    "codeValue": "WPE",
    "description": "WPE",
    "color": "#6AA84F"
  },
  {
    "codeValue": "EYPP",
    "description": "EYPP",
    "color": "#FF9900"
  },
  {
    "codeValue": "DLA",
    "description": "DLA",
    "color": "#9900FF"
  },
  {
    "codeValue": "DAF",
    "description": "DAF",
    "color": "#2B78E4"
  },
  {
    "codeValue": "MNS",
    "description": "MNS",
    "color": "#000000"
  },
  {
    "codeValue": "RUR",
    "description": "RUR",
    "color": "#EA9999"
  },
  {
    "codeValue": "FLEX",
    "description": "FLEX",
    "color": "#C27BA0"
  },
  {
    "codeValue": "QUA",
    "description": "QUA",
    "color": "#674EA7"
  },
  {
    "codeValue": "EAL",
    "description": "EAL",
    "color": "#CCCCCC"
  },
  {
    "codeValue": "DEP1",
    "description": "DEP1",
    "color": "#CF2A27"
  },
  {
    "codeValue": "DEP2",
    "description": "DEP2",
    "color": "#FF9900"
  },
  {
    "codeValue": "DEP3",
    "description": "DEP3",
    "color": "#F1C232"
  },
  {
    "codeValue": "DEP4",
    "description": "DEP4",
    "color": "#93C47D"
  }
];

export const fundingValidations = (
  validationErrors,
  updatedRow,
  configValues = {}
) => {
  if (configValues)
    performValidations(validationErrors,  updatedRow, configValues);
  else performAggGridValidations(validationErrors,  updatedRow);
};

export const performValidations = (
  validationErrors,
  updatedRow,
  configValues
) => {
  const { id: rowId } = updatedRow;

  const stretchFundHours = configValues?.stretchFundList?.find(stretchFund => stretchFund.stretchedFund === updatedRow.stretchedInd)?.hours;
  const stretchFundWeeks = configValues?.stretchFundList?.find(stretchFund => stretchFund.stretchedFund === updatedRow.stretchedInd)?.weeks;
  const configExpHrsPerWeek = stretchFundHours ? stretchFundHours : configValues.expHrsPerWeek;
  const configExtHrsPerWeek = stretchFundHours ? stretchFundHours : configValues.extHrsPerWeek;
  const configUniHrsPerWeek = stretchFundHours ? stretchFundHours : configValues.uniHrsPerWeek;

  // Validating expHrsPerWeek
  const condition1 = isNaN(updatedRow.expHrsPerWeek) || updatedRow.expHrsPerWeek > configExpHrsPerWeek;
  applyValidation(validationErrors, condition1, `Expanded Hours must be less than or equal to ${ configExpHrsPerWeek }.`, 'expHrsPerWeek', rowId);

  // Validating extHrsPerWeek
  const condition2 = isNaN(updatedRow.extHrsPerWeek) || updatedRow.extHrsPerWeek > configExtHrsPerWeek;
  applyValidation(validationErrors, condition2, `Extended Hours must be less than or equal to ${configExtHrsPerWeek}.`, 'extHrsPerWeek', rowId);

  // Validating uniHrsPerWeek
  const condition3 = isNaN(updatedRow.uniHrsPerWeek) || updatedRow.uniHrsPerWeek > configUniHrsPerWeek;
  applyValidation(validationErrors, condition3, `Funded Hours must be less than or equal to ${configUniHrsPerWeek}.`, 'uniHrsPerWeek', rowId);

  // Validating fundedWeeks
    const condition4 = isNaN(updatedRow.fundedWeeks) || updatedRow.fundedWeeks > stretchFundWeeks;
    applyValidation(validationErrors, condition4, `The child’s funded weeks must be less than or equal to ${stretchFundWeeks}.`, 'fundedWeeks', rowId);

  // Validating childEndDate greater than childStartDate
    const condition5 = moment(updatedRow.childEndDate).isSameOrBefore(moment(updatedRow.childStartDate));

    if (updatedRow.childEndDate && updatedRow.childStartDate) {
      applyValidation(validationErrors, condition5, 'The End Date must not precede the Start Date.', 'childFundStartG', rowId)


  }

  // Validating childFundEnd after childFundStart
    const condition7 = moment(updatedRow.childFundEnd).isSameOrBefore(moment(updatedRow.childFundStart));
    if (updatedRow.childFundEnd && updatedRow.childFundStart) {
      applyValidation(validationErrors, condition7, 'The Funding End Date must not precede the Funding Start Date.', 'childFundStartA', rowId);
    }

  // Validating childFundStart not before childStartDate
    const condition8 = moment(updatedRow.childFundStart).isBefore(moment(updatedRow.childStartDate));
    if (updatedRow.childFundStart && updatedRow.childStartDate) {
      applyValidation(validationErrors, condition8, 'The Funding Start Date cannot precede the Start Date.', 'childFundStartB', rowId);
    }

  // Validating childEndDate not before childFundEnd
    const condition9 = moment(updatedRow.childEndDate).isBefore(moment(updatedRow.childFundEnd));
    if (updatedRow.childEndDate && updatedRow.childFundEnd) {
      applyValidation(validationErrors, condition9, 'The End Date cannot precede the Funding End Date', 'childEndDate', rowId)
    }

  validateFundingAges(updatedRow, validationErrors, rowId);
};

export const submitEstimateValidations = (row, validationErrors) => {
  const condition1 = row.includeChild === "Y" && (row.fundingAge === null);
  applyValidation(validationErrors, condition1, 'The child must be within the valid age range for Early Years Funding.', 'includeChildFundingAge', row.id);

  const condition2 = row.includeChild === "Y" && ( row.totalFundedHours === 0);
  applyValidation(validationErrors, condition2, 'Total funded hours must be greater than 0 when Include in Submission is Yes.', 'includeChildTotalFundedHours', row.id);

  const condition3 =
    row.includeChild === "N" &&
    Boolean(
      parseFloat(row.expHrsPerWeek) ||
      parseFloat(row.extHrsPerWeek) ||
      parseFloat(row.uniHrsPerWeek) ||
      parseFloat(row.fundedWeeks)
    );
  applyValidation(validationErrors, condition3, 'You must select records that do not have either Expanded hours, Extended hours, Funded hours or Funded weeks submitted.', 'includeChildChildFundEndN', row.id);

  let condition4 = row.includeChild === "Y" && row.childEndDate;

  if(row.disableInclude !== undefined){
    condition4 = condition4 && !row.disableInclude
  }

  applyValidation(validationErrors, condition4, 'There is a funding end date or child end date associated with this child, so they cannot be included in this submission.', 'includeChildChildEndDate', row.id);

  const condition6 = row.includeChild === 'Y' && !Boolean(row.childFundStart);
  applyValidation(validationErrors, condition6, 'The Funding Start Date must be populated if the Child has funding data recorded against them.', 'includeChildChildFundEndP', row.id);

  const condition7 = row.includeChild === 'Y' && Boolean(row.childEndDate) && !Boolean(row.childFundEnd);
  applyValidation(validationErrors, condition7, 'The Funding End Date must be populated if the Child has funding data recorded against them.', 'includeChildChildFundEnd', row.id);

  const condition8 =  row.fundingAge == null && Boolean(Number(row.expHrsPerWeek ||
    row.extHrsPerWeek ||
    row.uniHrsPerWeek ||
    row.fundedWeeks));
  applyValidation(validationErrors, condition8, 'Funded hours/weeks not allowed when there is no Funding Age', 'hoursChildFundingAge', row.id);

};

export const calcTotalFundedHours = (cellEditUpdatedRow) => {
  const calcTotalFundedHours =
    (Number(cellEditUpdatedRow.expHrsPerWeek) +
      Number(cellEditUpdatedRow.uniHrsPerWeek) +
      Number(cellEditUpdatedRow.extHrsPerWeek)) *
    Number(cellEditUpdatedRow.fundedWeeks);
  return isNaN(calcTotalFundedHours) ? "" : calcTotalFundedHours;
};

export const performAggGridValidations = (
  validationErrors,
  updatedRow
) => {
  const { ageRange: rowId } = updatedRow;
  const { totalChild, totalExpHrs, totalUniHrs, totalExtHrs } = updatedRow;

  // Validation 2: Hours must exceed zero
  const condition1 =
    Number(totalChild) > 0 &&
    Number(totalExpHrs) + Number(totalUniHrs) + Number(totalExtHrs) === 0;
  applyValidation(
    validationErrors,
    condition1,
    "If No. of Children is not zero, then either Total Expanded Hours, Total Extended Hours, or Total Funded Hours must exceed zero.",
    "totalChild",
    rowId
  );

  // Validation 2: Total children must exceed zero
  const condition2 =
    Number(totalChild) === 0 &&
    Number(totalExpHrs) + Number(totalUniHrs) + Number(totalExtHrs) > 0;
  applyValidation(
    validationErrors,
    condition2,
    "If any of total hours is not zero, then total children must exceed zero.",
    "totalHours",
    rowId
  );
};

export const validateFundingAges = (updatedRow, validationErrors, rowId) => {
  // Additional validations based on fundingAge
  switch (updatedRow.fundingAge) {
    case 'LOW':
    case '<2':
      // Cannot have any Funded or Extended Hours recorded-can only have Expanded Hours
      const condition10 = updatedRow.uniHrsPerWeek > 0;
      applyValidation(validationErrors, condition10, 'Child with funding age <2 cannot have any Funded Hours recorded.', 'uniHrsPerWeekFH', rowId);

      const condition11 = updatedRow.extHrsPerWeek > 0;
      applyValidation(validationErrors, condition11, 'Child with funding age <2 cannot have any Extended Hours recorded.', 'extHrsPerWeekEH', rowId);
      break;

    case 'TWO':
    case '2':
      // Cannot have any Extended Hours recorded-can have either Expanded Hours or Funded Hours recorded but not both
      const condition12 = updatedRow.extHrsPerWeek > 0;
      applyValidation(validationErrors, condition12, 'Child with funding age 2 cannot have any Extended Hours recorded.', 'extHrsPerWeekEXH', rowId);

      const condition13 = updatedRow.expHrsPerWeek > 0 && updatedRow.uniHrsPerWeek > 0;
      applyValidation(validationErrors, condition13, 'Child with funding age 2 can have either Expanded Hours or Funded Hours recorded but not both.', 'expHrsPerWeekEUH', rowId);
      break;

    case 'THREE':
    case '3':
      // Cannot have any Expanded Hours recorded-can only have Funded Hours, and Extended Hours
      const condition14 = updatedRow.expHrsPerWeek > 0;
      applyValidation(validationErrors, condition14, 'Child with funding age 3 cannot have any Expanded Hours recorded.', 'expHrsPerWeekEXPH', rowId);
      break;

    case 'FOUR':
    case '4':
      // Cannot have any Expanded Hours recorded-can only have Funded Hours, and Extended Hours
      const condition15 = updatedRow.expHrsPerWeek > 0;
      applyValidation(validationErrors, condition15, 'Child with funding age 4 cannot have any Expanded Hours recorded.', 'expHrsPerWeekEPH', rowId);
      break;

    default:
      break;
  }
}

export const autoPopulateWeeksOnUpdatedRow = (configValues, updatedRow) => {
  let fundedWeeks = 0;
  if(configValues && updatedRow.fundingAge && updatedRow.includeChild === 'Y') {
    fundedWeeks = configValues?.stretchFundList?.find(stretchFund => stretchFund.stretchedFund === updatedRow.stretchedInd)?.weeks || 0;
    const configPeriodStartDate = moment(configValues.configPeriodStartDate);
    const configPeriodEndDate = moment(configValues.configPeriodEndDate);
    const childFundStart = updatedRow.childFundStart ? moment(updatedRow.childFundStart) : null;
    const childFundEnd = updatedRow.childFundEnd ? moment(updatedRow.childFundEnd) : null;

    // Condition 1
    if (childFundStart && childFundStart.isBetween(configPeriodStartDate, configPeriodEndDate)) {
      fundedWeeks = calculateWeeksBetween(childFundStart, configPeriodEndDate);
    }

    // Condition 2
    if (childFundEnd && childFundEnd.isBetween(configPeriodStartDate, configPeriodEndDate)) {
      fundedWeeks = calculateWeeksBetween(configPeriodStartDate, childFundEnd);
    }

    // Condition 3
    if (childFundStart && childFundEnd && childFundStart.isBetween(configPeriodStartDate, configPeriodEndDate)
      && childFundEnd.isBetween(configPeriodStartDate, configPeriodEndDate)) {
      fundedWeeks = calculateWeeksBetween(childFundStart, childFundEnd);
    }
  }
  return fundedWeeks;
}

const applyValidation = (
  validationErrors,
  condition,
  errorMessage,
  cellKey,
  rowId
) => {
  if (condition) {
    if (!validationErrors[rowId]) {
      validationErrors[rowId] = {};
    }
    validationErrors[rowId][cellKey] = errorMessage;
  } else {
    if (validationErrors[rowId] && validationErrors[rowId][cellKey]) {
      delete validationErrors[rowId][cellKey];
      if (Object.keys(validationErrors[rowId]).length === 0) {
        delete validationErrors[rowId];
      }
    }
  }
};

export const renderIncludeChildIcon = ({ row, column }) => {
  const color = row[column.key] === "Y" ? "green" : "red";
  const iconName =
    row[column.key] === "Y" ? "fa-solid fa-check" : "fa-solid fa-xmark";
  return (
    <div style={{ textAlign: "center" }}>
      <Icon color={color} name={iconName} />
    </div>
  );
};

export const transformRowDataForPayload = (rows) => {
  return Object.entries(rows).reduce((acc, [, value]) => {
    const { actions, disableInclude, ...rest } = value;
    acc.push({ ...rest });
    return acc;
  }, []);
};

// Helper function to calculate the number of weeks between two dates using Moment.js
export const calculateWeeksBetween = (startDate, endDate) => {
  return Math.ceil(moment(endDate).diff(moment(startDate), 'weeks', true));
};