import React, { useState } from "react";
import { Button, Divider, Form, Icon, Message } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { Input } from "@caci/react-form-fields";
import { Link } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import backtoschool from "../assets/images/backtoschool.svg";
import { useTranslation } from "react-i18next";
import LanguageList from "../components/LanguageList";
import HelpText from "../components/HelpText";

const validations = yup.object().shape({
  email: yup
    .string()
    .required("Required")
    .max(500, "Character limit of 500 exceeded")
    .email("Please enter a valid email"),
});

function RequestPasswordResetScreen({ setLangKey, langKey }) {
  const [status, setStatus] = useState(3);
  const form = useForm({
    resolver: yupResolver(validations),
  });

  const { t } = useTranslation();
  const resetPassword = ({ email }) => {
    setStatus(0);
    axios
      .get(`/api/reset/${email}`)
      .then(() => setStatus(1))
      .catch(() => setStatus(-1));
  };

  return (
    <>
      {(status === 0 || status === 3) && (
        <div className="flex">
          <div className="flex items-center min-h-screen bg-white w-1/3">
            <div className="flex-grow p-16">
              <div className="text-4xl font-bold mb-6">
                {t("Providers Portal")}
              </div>
              <FormProvider {...form}>
                <Form onSubmit={form.handleSubmit(resetPassword)}>
                  <Input
                    label={t("Email")}
                    name="email"
                    placeholder={t("Please enter your email...")}
                  />
                  <Button
                    positive
                    disabled={status === 0}
                    loading={status === 0}
                  >
                    {t("Reset Password")}
                  </Button>
                </Form>
              </FormProvider>
              <div className="mt-6">
                <Link className="underline" to="/public/login">
                  {t("Back to the login screen")}
                </Link>
              </div>
              <div className="mt-6">
                <Divider />
                <LanguageList setLangKey={setLangKey} langKey={langKey} />
              </div>
            </div>
          </div>
          <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
            <img src={backtoschool} alt="" />
          </div>
        </div>
      )}
      {status !== 0 && status !== 3 && (
        <Message
          icon
          error={status === -1}
          success={status === 1}
          as={status === 1 ? Link : Message}
          to="/public/login"
        >
          {status === -1 && <Icon name="warning" />}
          {status === 1 && <Icon name="check" />}
          <Message.Content>
            <Message.Header>
              {status === -1 && t("Request Failed")}
              {status === 1 && t("Account Reset")}
            </Message.Header>
            {status === -1 && t("Please contact your administrator.")}
            {status === 1 &&
              t("Please click here to go back to the login screen.")}
          </Message.Content>
        </Message>
      )}
    </>
  );
}

export default RequestPasswordResetScreen;
