import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
} from "semantic-ui-react";
import { Input, TextArea } from "@caci/react-form-fields";
import { createSyncTrackPutObject, setValueHelper } from "../utils";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import { useTranslation } from "react-i18next";
import LinkAddressModal from "./LinkAddressModal";
import LinkAddressSearchModal from "./LinkAddressSearchModal";
import PersonModal from "./PersonModal";

function ProviderRegNameAddressDetails(row, setRow) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isAddressModalOpen, setAddressModalOpen] = useState(false);
  const [isAddressSearchModalOpen, setAddressSearchModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { selectedProvider: provider, updateSelectedProvider } = useSession();
  const [personModalOpen, setPersonModalOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const schema = yup.object({});

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ personId, addressId, trackId }) => {
    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/personId", value: personId },
      { op: "add", path: "/addressId", value: addressId },
    ];

    const putObject = createSyncTrackPutObject(trackId, "U");
    axios
      .put(`/api/pport-early-provider/${provider.providerId}`, put, putObject)
      .then(({ data }) => {
        addToast(
          `${t("Registered Name & Address")} ${t(
            "has been successfully updated"
          )}`,
          {
            appearance: t("success"),
          }
        );
        methods.setValue("id", data.id);
        if (data && Object.keys(data).length > 0) updateSelectedProvider(data);
        setSaving(false);
      })
      .catch(console.log);
  };

  const fetchAddress = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId ? addressId : provider.addressId}`)
      .then(({ data: rows }) => {
        methods.setValue(`addressId`, rows.addressId);
        methods.setValue(
          `addressText`,
          (rows.unitName ? rows.unitName + "\n" : "") +
            (rows.houseNo ? rows.houseNo + "\n" : "") +
            (rows.street ? rows.street + "\n" : "") +
            (rows.mainRoad ? rows.mainRoad + "\n" : "") +
            (rows.district ? rows.district + "\n" : "") +
            (rows.town ? rows.town + "\n" : "") +
            (rows.postcode ? rows.postcode + "\n" : "")
        );
      })
      .catch(console.log);
  };

  const setAddressId = (addressId) => {
    axios
      .get(`/api/pport-address/${addressId}`)
      .then(({ data: address }) => {
        addToast(`${t("Address")} ${t("has been successfully Linked")}`, {
          appearance: t("success"),
        });
        setAddressSearchModalOpen(false);
        methods.setValue(`addressId`, address.addressId);
        methods.setValue(
          `addressText`,
          (address.unitName ? address.unitName + "\n" : "") +
            (address.houseNo ? address.houseNo + "\n" : "") +
            (address.street ? address.street + "\n" : "") +
            (address.mainRoad ? address.mainRoad + "\n" : "") +
            (address.district ? address.district + "\n" : "") +
            (address.town ? address.town + "\n" : "") +
            (address.postcode ? address.postcode + "\n" : "")
        );
      })
      .then(console.log);
  };
  const { reset } = methods;
  useEffect(() => {
    setValueHelper(row, methods.setValue);
  }, [row]);

  useEffect(() => {
    setValueHelper(provider, methods.setValue);
    if (provider && Object.keys(provider).length > 0) {
      setValueHelper(provider, methods.setValue);
    }
    fetchAddress();
  }, [provider, provider.providerId]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Registered Name & Address")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row stretched>
                <Grid.Column width={8}>
                  <Segment style={{ backgroundColor: "#eee" }}>
                    <Header as="h5" textAlign="center">
                      {t("Registered Name")}
                    </Header>
                    <Input label={t("Impulse ID")} name="personId" />
                    <Input label={t("First Name")} name="forename" />
                    <Input label={t("Surname")} name="surname" />
                    <Button
                      className={"light-blue"}
                      type={'button'}
                      onClick={() => {
                        setIsNew(true);
                        setPersonModalOpen(true);
                      }}
                    >
                      <Icon name="fas fa-male" />
                      {t("New Person")}
                    </Button>
                    <Button
                      className={"light-blue"}
                      type={'button'}
                      onClick={() => {
                        setIsNew(false);
                        setPersonModalOpen(true);
                      }}
                    >
                      <Icon name="th" />
                      {t("View")}
                    </Button>
                  </Segment>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Segment style={{ backgroundColor: "#eee" }}>
                    <Header as="h5" textAlign="center">
                      {t("Registered Address")}
                    </Header>
                    <Form>
                      <Input
                        label={t("Address ID")}
                        name="addressId"
                        readOnly
                      />
                      <TextArea
                        label={t("Address")}
                        name="addressText"
                        readOnly
                      />
                      <br />
                      <Button
                        className={"light-blue"}
                        type={'button'}
                        onClick={() => setAddressSearchModalOpen(true)}
                      >
                        <Icon name="fas fa-map-marker-alt" />
                        {t("Link Address")}
                      </Button>
                      <Button
                        className={"light-blue"}
                        type={'button'}
                        onClick={() => setAddressModalOpen(true)}
                      >
                        <Icon name="th" />
                        {t("View")}
                      </Button>
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <UserAccessComponent requires="PPORT_PM_PR_D_SUB">
                    <Button disabled={!methods.getValues()?.personId || !methods.getValues()?.addressId} className={"green"} type="submit">
                      <Icon name="check circle" />
                      {t("Submit")}
                    </Button>
                  </UserAccessComponent>
                  <Button className={"red"} onClick={() => methods.reset()}>
                    <Icon name="times circle" />
                    {t("Clear")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </FormProvider>
      </Segment>
      <PersonModal
        isPersonModalOpen={personModalOpen}
        closePersonModal={() => setPersonModalOpen(false)}
        personId={provider.personId}
        isNew={isNew}
        cb={({ personId, forename, surname, trackId }) =>
          setValueHelper({ personId, forename, surname, trackId }, methods.setValue, {
            shouldValidate: true,
          })
        }
      />
      <LinkAddressModal
        isAddressModalOpen={isAddressModalOpen}
        closePersonModal={() => {
          setAddressModalOpen(false);
          fetchAddress();
        }}
        addressId={provider.addressId}
        cb={({ addressId }) =>
          setValueHelper({ addressId }, methods.setValue, {
            shouldValidate: true,
          })
        }
      />
      <LinkAddressSearchModal
        isAddressSearchModalOpen={isAddressSearchModalOpen}
        closeAddressSerchModal={() => setAddressSearchModalOpen(false)}
        fetchAddress={fetchAddress}
        setAddressId={setAddressId}
      />
    </>
  );
}

export default ProviderRegNameAddressDetails;
