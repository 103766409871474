import { useContext } from "react";
import { SessionContext } from "../context";

export function useSession() {
  const {
    providers,
    person,
    setSelectedProvider,
    selectedProvider,
    userDetails,
    loadingProviders,
    loadingPerson,
    loadingUserDetails,
    updateSelectedProvider,
    fetchProviders,
    syncTrackRecords,
    fetchSyncTrackRecords
  } = useContext(SessionContext);
  return {
    providers,
    person,
    setSelectedProvider,
    selectedProvider,
    userDetails,
    loadingProviders,
    loadingPerson,
    loadingUserDetails,
    updateSelectedProvider,
    fetchProviders,
    syncTrackRecords,
    fetchSyncTrackRecords
  };
}
