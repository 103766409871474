import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Popup, Segment } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import { DateInput, Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "../../components/UserAccessComponent";
import moment from "moment";
import useLookup from "../../consumer/LookupConsumer";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { lookupToOptionsTranslation } from "../../utils";
import PropTypes from "prop-types";

function EarlyPlanTypeListScreen(props) {
  const { t } = useTranslation();
  const { lookups: earlyPlanType } = useLookup(957);
  const [rows, setRows] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const { addToast } = useToasts();


  const schema = yup.object({
    planType: yup.string().required(t("Please enter a value")),
    accurateAt: yup.string().required(t("Please enter a value")),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleCancel = () => {
    methods.setValue("id", "");
    methods.setValue("planType", "");
    methods.setValue("accurateAt", "");
    setEditMode(false);
    getEarlyPlanTypeList();
  };

  const handleEditAction = (row) => {
    methods.setValue("id", row.id);
    methods.setValue("planType", row.planType);
    methods.setValue(
      "accurateAt",
      moment(row.accurateAt).format("YYYY-MM-DD")
    );
    setEditMode(true);
  };

  const handleDeleteAction = (id) => {
    axios.delete(`api/early-sen-plan/${id}`)
      .then(() => {
        addToast(`${t("SEND Plan")} ${t("has been successfully removed.")}`, {
          appearance: "success",
        });
        getEarlyPlanTypeList();
      })
      .catch((error) => {
        console.log(error);
        addToast(t(`Error occurred. Status: ${error.response.status}`), {
          appearance: "error",
        });
      });
  };

  const onSubmit = ({
                      id,
                      planType,
                      accurateAt,
                    }) => {
    const payload = {
      id,
      planType,
      accurateAt,
      earlySenId: props.earlySenId,
    };

    const data = [
      { op: "add", path: "/planType", value: planType },
      {
        op: "add",
        path: "/accurateAt",
        value: moment(accurateAt).format("YYYY-MM-DD"),
      },
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json",
      },
    };

    const postObject = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const isDupRecord = rows.some((row) => row.earlySenId === payload.earlySenId && row.planType === payload.planType);
    if(isDupRecord) {
      addToast(t("SEN Plan record already exists for Early SEND ID, SEN Plan, and Accurate At Combination"), {
        appearance: "error",
      });
      return;
    }

    setSaving(true);

    if (!id) {
      axios
        .post("/api/early-sen-plan", payload, postObject)
        .then(() => {
          setSaving(false);
          addToast(`${t("SEND Plan")} ${t("has been successfully created")}`, {
            appearance: "success",
          });
          handleCancel();
        })
        .catch((error) => {
          console.log(error);
          addToast(t(`Error occurred. Status: ${error.response.status}`), {
            appearance: "error",
          });
          setSaving(false);
        });
    } else {
      axios
        .put(`/api/early-sen-plan/${id}`, data, putObject)
        .then(() => {
          addToast(`${t("SEND Plan")} ${t("has been successfully updated")}`, {
            appearance: "success",
          });
          setSaving(false);
          handleCancel();
        })
        .catch((error) => {
          console.log(error);
          addToast(t(`Error occurred. Status: ${error.response.status}`), {
            appearance: "error",
          });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    getEarlyPlanTypeList();
  }, []);

  const getEarlyPlanTypeList = () => {
    axios
      .get(`/api/early-sen-plan/list/${props.earlySenId}`)
      .then(({ data: rows }) => {
        rows.length > 0 ? setRows(rows) : setRows([]);
      })
      .catch(console.log);
  };

  return (
    <div>
      {!editMode && (
        <Segment attached={"bottom"}>
          <DataGrid
            id={"caseEysSplGrid"}
            t={t}
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <UserAccessComponent requires="PPORT_LR_EYS_SP_L_EDIT">
                  <Popup
                    trigger={
                      <Icon
                        circular
                        link
                        color="dark-blue-border"
                        id={"caseEysSplEdit"}
                        disabled={props.restrictForm}
                        name={"pencil"}
                        onClick={() => handleEditAction(row)}
                      />
                    }
                    content={t("Edit")}
                  />
                </UserAccessComponent>
                <UserAccessComponent requires="PPORT_LR_EYS_SP_L_DEL">
                  <DeleteConfirmationModal
                    message={t(
                      "Are you sure you want to delete this SEND Plan Type record?"
                    )}
                    id={"caseEysSplDelete"}
                    disabled={props.restrictForm}
                    onDelete={() => handleDeleteAction(row.id)}
                    iconName="trash alternate outline"
                    color="red-border"
                    circular
                  />
                </UserAccessComponent>
              </div>
            )}
            columns={[
              {
                key: "planType",
                name: t("Plan Type"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: earlyPlanType,
              },
              {
                key: "accurateAt",
                name: t("Accurate At"),
                filterable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
              },
            ]}
            rows={rows}
          />
          <br />
          <UserAccessComponent requires="PPORT_LR_EYS_SP_L_ADD">
            <Button
              id={"caseEysSplAdd"}
              style={{ marginTop: ".5rem" }}
              className={"purple gap-btw-grid-buttons"}
              onClick={() => {
                setEditMode(true);
              }}
              disabled={!props.earlySenId || props.restrictForm}
            >
              <Icon>
                <i className="fas fa-file-invoice"></i>
              </Icon>

              {t("New SEND Plan Type")}
            </Button>
          </UserAccessComponent>
        </Segment>
      )}
      {editMode && (
        <Segment attached="bottom">
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Input
                      id="caseEysSpDPlanId"
                      label={t("Plan Type ID")}
                      name="id"
                      readOnly
                    />
                    <Dropdown
                      id="caseEysSpDPlanType"
                      label={t("Plan Type")}
                      name="planType"
                      options={earlyPlanType.map(lookupToOptionsTranslation(t))}
                    />
                    <DateInput
                      id="caseEysSpDAccurate"
                      name="accurateAt"
                      label={t("Accurate At")}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Button
                id="caseEysSpDBack"
                onClick={() => {
                  handleCancel();
                  methods.reset();
                }}
                className={"orange"}
              >
                <Icon name="arrow circle left" />
                {t("Back")}
              </Button>
              <UserAccessComponent requires="PPORT_LR_EYS_SP_D_SUB">
                <Button
                  id="caseEysSpDSub"
                  className={"green"}
                  type="submit"
                  disabled={saving}
                >
                  <Icon name="check circle" />
                  {t("Submit")}
                </Button>
              </UserAccessComponent>
            </Form>
          </FormProvider>
        </Segment>
      )}
    </div>
  );
}

EarlyPlanTypeListScreen.propTypes = {
  earlySenId: PropTypes.string,
  restrictForm: PropTypes.bool,
};
export default withRouter(EarlyPlanTypeListScreen);
