import React, { useEffect, useState } from "react";
import { Button, Icon, Popup, Segment } from "semantic-ui-react";
import UserAccessComponent from "../../components/UserAccessComponent";
import { ButtonNewChild,ButtonSubmit } from "@caci/react-form-fields";
import { useTranslation } from "react-i18next";
import { Grid as DataGrid } from "@caci/react-grid";
import {
  ESTIMATE_USER_MESSAGES,
  AMENDMENT_USER_MESSAGES,
  submitEstimateValidations,
  transformRowDataForPayload,
  fundingValidations,
  autoPopulateWeeksOnUpdatedRow,
  calcTotalFundedHours, FUNDING_ERROR_MESSAGES
} from "./FundingUtils";
import { useToasts } from "react-toast-notifications";
import ApplyConfirmationModal from "./ApplyConfirmationModal";
import SaveOrContinueModal from "./SaveOrContinueModal";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../assets/styles/customEditor.css";
import axios from "axios";
import ReadOnlyGridView from "./ReadOnlyGridView";
import useFundingGrid from "./useFundingGrid";
import { useFilters } from "../../filters/FilterContext";
import CommentsModal from "./CommentsModal";
import LoadingMessage from "../../components/LoadingMessage";
import { lookupCodeToValue } from "../../utils";
import { useSession } from "../../consumer/SessionConsumer";

const Amendment = () => {
  const [isOpenSubmitEstimateConfirmModal, setIsOpenSubmitEstimateConfirmModal] = useState(false);
  const [isOpenSaveSubmissionModal, setIsOpenSaveSubmissionModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modalType, setModalType] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [ commentsModal, setCommentsModal ] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const history = useHistory();
  const { providerId } = useParams();
  const { filters } = useFilters();
  const { fetchProviders } = useSession();

  const handleRowKeyGetter = (row) => {
    return row.id;
  }

  const {
    rows,
    setRows,
    edits,
    setEdits,
    readOnly,
    searching,
    comments,
    submissionStatus,
    validationErrors,
    configValues,
    rowsChanged,
    setRowsChanged,
    columns,
    filteredRows,
    eyPeriodDesc,
    handleGridRowsUpdated,
    fetchGridData
  } = useFundingGrid(providerId, `/api/ey-funding-amendments/`, handleRowKeyGetter, filters, 'AMD')

  useEffect(() => {
    fetchGridData();
  }, [providerId]);


  useEffect(() => {
    if (!rowsChanged) {
      onContinueWithoutSaving();
    }
  }, [modalType, rowsChanged]);



  const sendRequestTo = () => {
    const successMsg = AMENDMENT_USER_MESSAGES.submitSuccess;
    const failureMsg = AMENDMENT_USER_MESSAGES.submitError;

    const estimateEditData = transformRowDataForPayload(rows);
    const postObject = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setSaving(true);
    axios
      .post(
        `/api/ey-funding-amendments/${providerId}`,
        estimateEditData,
        postObject
      )
      .then(() => {
        addToast(t(successMsg), {
          appearance: "success",
        });
        fetchProviders();
        setSaving(false);
        setRowsChanged(false);
      })
      .catch((error) => {
        console.log(error);
        addToast(
          t(failureMsg),
          {
            appearance: "error",
          }
        );
        setSaving(false);
      });
  };

  const getRowClassName = (row) => {
    return Object.keys(validationErrors[row.id] || {}).length > 0 &&
    rowsChanged
      ? "row-style-me-grid"
      : "";
  };

  const toggleModal = (name, value) => {
    switch (name) {
      case "isOpenSubmitEstimateConfirmModal":
        setIsOpenSubmitEstimateConfirmModal(value);
        break;
      case "isOpenSaveSubmissionModal":
        setIsOpenSaveSubmissionModal(value);
        break;
      default:
        break;
    }
  };

  const handleAddEditViewChildClick = (type, row = null) => {
    setModalType(type);
    setCurrentRow(row);
    if (rowsChanged) {
      toggleModal("isOpenSaveSubmissionModal", true);
    }
  };

  const onContinueWithoutSaving = () => {
    let navigateUrl = "";

    switch (modalType) {
      case "ADD_CHILD":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/amendment/new`;
        break;
      case "EDIT_CHILD":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/amendment/${currentRow.id}`;
        break;
      case "ADD_SIBLING":
        navigateUrl = `/portal/${providerId}/provider/provider-funding/amendment/sib${currentRow.id}`;
        break;
      default:
        break;
    }
    if (navigateUrl) {
      navigateToRoute(navigateUrl);
    }
  };

  const navigateToRoute = (route) => {
    history.push(route);
  };

  const handleToggle = (row) => {
    let updatedRow;
    const updatedRows = rows.map((rowObj) => {
      if (rowObj.id === row.id) {
        updatedRow = {
          ...rowObj,
          includeChild: rowObj.includeChild === "Y" ? "N" : "Y",
        };
        updatedRow.fundedWeeks = autoPopulateWeeksOnUpdatedRow(configValues, updatedRow);
        updatedRow.totalFundedHours = calcTotalFundedHours(updatedRow);
        fundingValidations(
          validationErrors,
          updatedRow,
          configValues
        );
        submitEstimateValidations(updatedRow, validationErrors);
        return updatedRow;
      }
      return rowObj;
    });
    setRows(updatedRows);
    setRowsChanged(true);
    setEdits({ ...edits, [row.id]: updatedRow });
  };


  const onSaveAndContinue = async () => {
    await onSubmitEstimate();
    if (Object.keys(validationErrors).length === 0) {
      onContinueWithoutSaving();
    }
  };

  const onSubmitEstimate = () => {
    let isError = Object.keys(validationErrors).length !== 0;
    let includeChildExists = false; // Variable to track if includeChild is 'Y' in any row

    for (let row of rows) {
      if (row.includeChild === 'Y') {
        includeChildExists = true;
        break;
      }
    }

    if (!includeChildExists) {
      // Show error message if no rows have includeChild as 'Y'
      addToast(
        t(FUNDING_ERROR_MESSAGES.submitError),{
          appearance: "error",
        });
      toggleModal("isOpenSubmitEstimateConfirmModal", false);
      return;
    }
    rows.forEach((row) => {
      fundingValidations(validationErrors, row, configValues );
      submitEstimateValidations(row, validationErrors);
    });
    if (isError) {
      addToast(t(AMENDMENT_USER_MESSAGES.submitError), {
        appearance: "error",
      });
    } else {
      sendRequestTo();
    }

    toggleModal("isOpenSubmitEstimateConfirmModal", false);
  };

  const getDynamicApplyConfirmMsg = (periodDescription) => {
    return `You are about to Submit your Amendment for ${periodDescription}, once this has been submitted, you will not be able to edit this record.`;
  };

  if (searching) return <LoadingMessage />;

  return (
    <div>
      <Segment attached="bottom">
        { readOnly ? (
          <ReadOnlyGridView rows={rows} t={t} />
        ) : (
          <>
            {!searching && (
            <DataGrid
              id="_AmendmentTabScreen"
              columns={columns}
              actionsCell={({ row }) => (
                <div style={{ textAlign: "center" }}>
                  {rowsChanged && validationErrors[row.id] && (
                    <Popup
                      trigger={
                        <Icon>
                          <i
                            className="fa-solid fa-circle-exclamation"
                            style={{ color: "#CF2A27" }}
                          />
                        </Icon>
                      }
                      content={Object.values(validationErrors[row.id]).join(
                        "\n"
                      )}
                    />
                  )}
                  <Popup
                    trigger={
                      <span>
                        <Icon
                          circular
                          link
                          color={(readOnly || row.disableInclude) ? '#CCCCCC' : 'dark-blue-border'}
                          disabled={readOnly || row.disableInclude}
                          name={
                            row.disableInclude
                              ? "fa-ban"
                              : row.includeChild === "Y"
                                ? "check circle"
                                : "remove circle"
                          }
                          onClick={() => handleToggle(row)}
                        />
                      </span>
                    }
                    content={row.disableInclude
                      ? t('This child was included in the previous submission so cannot be excluded')
                      : t('Include in Amendment?')}
                  />
                  <UserAccessComponent
                    requires={"PPORT_EYF_AMD_L_CHILD_SIB_ADD"}
                  >
                    <Popup
                      trigger={
                        <Icon
                          circular
                          link
                          style={{ border: "1px solid #8AC040" }}
                          onClick={() =>
                            handleAddEditViewChildClick("ADD_SIBLING", row)
                          }
                        >
                          <i
                            className="fa-solid fa-children"
                            style={{ color: "#8AC040" }}
                          />
                        </Icon>
                      }
                      content={t("Add Sibling")}
                    />
                  </UserAccessComponent>
                  <UserAccessComponent requires={"PPORT_EYF_AMD_L_CHILD_EDIT"}>
                    <Popup
                      trigger={
                        <Icon
                          circular
                          link
                          name="pencil"
                          className={"dark-blue-border"}
                          onClick={() =>
                            handleAddEditViewChildClick("EDIT_CHILD", row)
                          }
                        />
                      }
                      content={t("Edit Child")}
                    />
                  </UserAccessComponent>
                </div>
              )}
              rows={filteredRows}
              enableFilters={filters.enabled}
              onRowsUpdated={handleGridRowsUpdated}
              rowClassNameGetter={getRowClassName}
              handleRowKeyGetter={handleRowKeyGetter}
            />
              )}
            <br />
            <UserAccessComponent requires="PPORT_EYF_AMD_L_CHILD_ADD">
              <ButtonNewChild
                id={"estimateTabAddChildBtn"}
                as={Link}
                disabled={readOnly}
                useForm={false}
                content={t("Add Child")}
                onClick={() => handleAddEditViewChildClick("ADD_CHILD")}
                className={readOnly ? "grey" : "purple"}
                iconClassName={"fa-solid fa-child-reaching"}
              />
            </UserAccessComponent>

            {rowsChanged && (
              <UserAccessComponent requires={"PPORT_EYF_AMD_L_SUB"}>
                <Button
                  onClick={() =>
                    toggleModal("isOpenSubmitEstimateConfirmModal", true)
                  }
                  loading={saving}
                  className={readOnly ? "grey" : "green"}
                  disabled={readOnly}
                >
                  <Icon name="fa-solid fa-circle-check" />
                  {t("Submit Amendment")}
                </Button>
              </UserAccessComponent>
            )}

            <ApplyConfirmationModal
              modalHeader={"Submit Amendment"}
              message={getDynamicApplyConfirmMsg(lookupCodeToValue(eyPeriodDesc, configValues?.periodDescription))}
              continueMsg={t(ESTIMATE_USER_MESSAGES.continueMsg)}
              isModalOpen={isOpenSubmitEstimateConfirmModal}
              onClose={() =>
                toggleModal("isOpenSubmitEstimateConfirmModal", false)
              }
              onConfirm={onSubmitEstimate}
              confirmText={t("Submit")}
            />
            <SaveOrContinueModal
              modalHeader={"Submit Amendment"}
              message={t(AMENDMENT_USER_MESSAGES.saveOrContinueMsg)}
              isModalOpen={isOpenSaveSubmissionModal}
              onClose={() => toggleModal("isOpenSaveSubmissionModal", false)}
              goBack={() => toggleModal("isOpenSaveSubmissionModal", false)}
              onSaveAndContinue={onSaveAndContinue}
              onContinueWithoutSaving={onContinueWithoutSaving}
              confirmText={t("Submit")}
              saveAndContinueLabel={"Submit And Continue"}
              continueWithoutSaveLabel={"Continue Without Submitting"}
            />
          </>
        )}
        <>
        {comments && submissionStatus === "REJ" &&
          <UserAccessComponent requires="PPORT_AMD_L_COMM">
            <ButtonSubmit
              id="providerSubEstimates"
              useForm={false}
              content={t('Comments')}
              buttonClassName={'red'}
              iconClassName={'fa-regular fa-message-lines'}
              disabled={false}
              loading={false}
              onClick={()=> setCommentsModal(true)}
            />
          </UserAccessComponent>
        }
        <CommentsModal
          modalHeader={'Reason for rejection'}
          message={comments}
          isModalOpen={commentsModal}
          onClose={()=> setCommentsModal(false)}
        />
        </>
      </Segment>
    </div>
  );
};

export default Amendment;
