import { Fragment, useEffect, useState } from "react";
import {
  DefaultFilter,
  Grid as EntitlementGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import axios from "axios";
import { Button, Form, Icon, Menu, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useLookup from "../consumer/LookupConsumer";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import { useSession } from "../consumer/SessionConsumer";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import { createSyncTrackPutObject, getValidationFlag, lookupToOptionsTranslation } from "../utils";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];

const schema = yup.object({
  childrenNo: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .max(3, "3 character limit exceeded"),
});


function EntitlementScreen({ setValidationFlag, urlSlug }) {
  const [rows, setRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [saving, setSaving] = useState(false);
  const { lookups: earlyYearsTerm } = useLookup(6113);
  const { lookups: entitlementTypes } = useLookup(6089);
  const { selectedProvider: provider } = useSession();
  const [selectedTerm, setSelectedTerm] = useState("");
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const handleDelete = (row) => {
    const data = [{ op: "add", path: "/validationFlag", value: "D" }];
    const putObject = createSyncTrackPutObject(row.trackId, getValidationFlag(row.validationFlag, "D"));
    axios
      .put(`/api/pport-early-entitlement/${row.id}`, data, putObject)
      .then(() => {
        addToast(`${t("Entitlement")} ${t("has been successfully removed")}`, {
          appearance: "success",
        });
        fetchEarlyEntitlements();
      })
      .catch((error) => {
        console.log(error);
        addToast(`${t("Entitlement")} ${t("has been successfully removed")}`, {
          appearance: "error",
        });
      });
  };

  const onSubmit = ({ id, entType, term, childrenNo, trackId, validationFlag }) => {
    const data = {
      entType: entType,
      term: term,
      childrenNo: childrenNo,
      providerId: provider.providerId,
      validationFlag: "I",
    };

    const put = [
      { op: "add", path: "/validationFlag", value: "U" },
      { op: "add", path: "/entType", value: entType },
      { op: "add", path: "/term", value: term },
      { op: "add", path: "/childrenNo", value: childrenNo },
    ];
    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));
    setSaving(true);
    if (id) {
      axios
        .put(`/api/pport-early-entitlement/${id}`, put, putObject)
        .then(() => {
          addToast(
            `${t("Entitlement")} ${t("has been successfully updated")}`,
            {
              appearance: "success",
            }
          );
          setSaving(false);
          fetchEarlyEntitlements();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-entitlement`, data)
        .then(({ data: { id } }) => {
          methods.setValue("id", id);
          addToast(
            `${t("Entitlement")} ${t("has been successfully created")}`,
            {
              appearance: "success",
            }
          );
          setSaving(false);
          fetchEarlyEntitlements();
        })
        .catch(console.log);
    }
  };

  const fetchEarlyEntitlements = () => {
    axios
      .get(`/api/pport-early-entitlement-list/${provider.providerId}`)
      .then(({ data: rows }) => {
        if (rows.length > 0) setRows(rows);
        const validationFlagIndicator = rows.some((row) =>
          validationFlagsList.includes(row.validationFlag)
        );
        setValidationFlag(urlSlug, {
          name: "entitlement",
          value: validationFlagIndicator,
        });
        setShowExclamation(validationFlagIndicator);
      })
      .catch(console.log);
  };

  const { reset } = methods;

  useEffect(() => {
    if (provider.providerId) {
      fetchEarlyEntitlements();
    }
    return () => {
      reset({
        id: "",
        term: "",
        entType: "",
        childrenNo: "",
      });
    };
  }, [provider.providerId]);

  const rowsData = selectedTerm
    ? rows.filter((row) => row.term === selectedTerm)
    : [];
  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Free Childcare Entitlement")}
        </Menu.Item>
        {showExclamation && (
          <Menu.Menu position="right">
              <Menu.Item>
                  <Icon color={"red"}>
                      <i className="fas fa-exclamation-triangle" />
                  </Icon>
              </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <span className={"text-white-color"}>
              <b>{t("Term")}</b>
            </span>
            <Form.Dropdown
              search
              selection
              name="selectedTerm"
              value={selectedTerm}
              options={
                earlyYearsTerm
                  ? earlyYearsTerm.map(lookupToOptionsTranslation(t))
                  : []
              }
              onChange={(_, { value }) => setSelectedTerm(value)}
            />
            <div>
              <EntitlementGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_HCP_ENT_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              methods.setValue(`id`, row.id);
                              methods.setValue(`entType`, row.entType);
                              methods.setValue(`childrenNo`, row.childrenNo);
                              methods.setValue(`term`, row.term);
                              methods.setValue(`trackId`, row.trackId);
                              methods.setValue(`validationFlag`, row.validationFlag);
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_CHARGE_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_ENT_L_DEL">
                      <DeleteConfirmationModal
                        message={t(
                          "Are you sure you want to delete this Entitlement?"
                        )}
                        onDelete={() => handleDelete(row)}
                        iconName="trash alternate outline"
                        color="red-border"
                        circular
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "entType",
                    name: t("Entitlement Type"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: LookupFilter,
                    formatter: LookupFormatter,
                    lookups: entitlementTypes,
                  },
                  {
                    key: "childrenNo",
                    name: t("Number of Children"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                ]}
                rows={rowsData}
              />
            </div>
            <div>
              <div className={"gap-btw-grid-buttons"}>
                <UserAccessComponent requires="PPORT_PM_HCP_ENT_L_ADD">
                  <Button
                    onClick={() => setShowDetail(true)}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="fas fa-list-ol" />
                    </Icon>
                    {t("New Entitlement")}
                  </Button>
                </UserAccessComponent>
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Dropdown
                  search
                  selection
                  label={t("Term")}
                  name={"term"}
                  options={earlyYearsTerm.map(lookupToOptionsTranslation(t))}
                />
                <Dropdown
                  search
                  selection
                  label={t("Entitlement Type")}
                  name={"entType"}
                  options={entitlementTypes.map(lookupToOptionsTranslation(t))}
                />
                <Input label={t("Number of Children")} name="childrenNo" />
                <br />
                <Button
                  onClick={() => {
                    setShowDetail(false);
                    reset();
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
                <UserAccessComponent requires="PPORT_PM_HCP_ENT_D_SUB">
                  <Button className={"green"} type="submit" disabled={saving}>
                    <Icon name="check circle" />
                    {t("Submit")}
                  </Button>
                </UserAccessComponent>
              </Form>
            </FormProvider>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default EntitlementScreen;
