import React, { useState, useEffect } from "react";
import { Button, Icon, Popup } from "semantic-ui-react";
import UserAccessComponent from "../../components/UserAccessComponent";
import {
  DefaultFilter,
  Grid as DataGrid,
  NumberEditor,
  NumberDecimalEditor,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import { useToasts } from "react-toast-notifications";
import useLookup from "../../consumer/LookupConsumer";
import ApplyConfirmationModal from "./ApplyConfirmationModal";
import axios from "axios";
import { ESTIMATE_USER_MESSAGES, transformRowDataForPayload } from "./FundingUtils";
import AggReadOnlyGridView from "./AggReadOnlyGridView";
import useFundingGrid from "./useFundingGrid";
import { useSession } from "../../consumer/SessionConsumer";

const AggregateGridView = ({ providerId, t, configPeriodMsg }) => {
  const [saving, setSaving] = useState(false);
  const [isOpenSubmitEstimateConfirmModal, setIsOpenSubmitEstimateConfirmModal] = useState(false);
  const [saveTriggered, setSaveTriggered] = useState(false);
  const { addToast } = useToasts();
  const { lookups: eyAgeRange } = useLookup(6214);
  const { fetchProviders } = useSession();

  const handleRowKeyGetter = (row) => {
    return row.ageRange;
  }

  const {
    rows,
    readOnly,
    validationErrors,
    rowsChanged,
    handleGridRowsUpdated,
    fetchGridData,
  } = useFundingGrid(providerId, `/api/ey-funding-estimates-agg/`, handleRowKeyGetter, null, 'EST')

  useEffect(() => {
    checkEstimateScreenLevel();
  },[providerId]);

  const toggleModal = (name, value) => {
    setIsOpenSubmitEstimateConfirmModal(value);
  };

  const isGridEmpty = () => {
    let isError = false;
    // Validation 1: At least 1 child record
    const totalChild = rows.reduce((accumulator, currentRecord) => {
      return accumulator + Number(currentRecord.totalChild);
    }, 0);
    if (totalChild === 0) {
      const totalHours = rows.reduce((accumulator, currentRecord) => {
        return (
          accumulator +
          Number(currentRecord.totalExpHrs) +
          Number(currentRecord.totalUniHrs) +
          Number(currentRecord.totalExtHrs)
        );
      }, 0);
      isError = totalHours === 0;
      return isError;
    }
    return isError;
  };

  const onSaveEstimate = () => {
    setSaveTriggered(true);
    if (isGridEmpty()) {
      addToast(
        t(
          "Failure - There must be at least 1 child recorded across all age ranges."
        ),
        {
          appearance: "error",
        }
      );
    } else {
      if (Object.keys(validationErrors).length === 0) {
        sendRequestTo("SAVE");
      } else {
        addToast(t("Failure - Unable to Save Estimate - validation error."), {
          appearance: "error",
        });
        setSaveTriggered(true);
        setSaving(false);
      }
    }
  };

  const sendRequestTo = (type) => {
    let successMsg;
    let failureMsg;
    switch (type) {
      case "SAVE":
        successMsg = "Success - Estimate Saved";
        failureMsg = "Failure - Unable to Save Estimate - validation error";
        break;
      case "SUBMIT":
        successMsg = "Success - Estimate Submitted";
        failureMsg = "Failure - Unable to Submit Estimate - validation error";
        break;
      default:
        break;
    }
    const estimateEditData = transformRowDataForPayload(rows);
    const postObject = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    setSaving(true);
    axios
      .post(
        `/api/ey-funding-estimates-agg/${type}/${providerId}`,
        estimateEditData,
        postObject
      )
      .then(() => {
        addToast(t(successMsg), {
          appearance: "success",
        });
        fetchProviders();
        setSaving(false);
      })
      .catch((error) => {
        console.log(error);
        addToast(
          t(failureMsg),
          {
            appearance: "error",
          }
        );
        checkEstimateScreenLevel();
        setSaving(false);
      });
  };

  const onSubmitEstimate = () => {
    let isError = Object.keys(validationErrors).length !== 0;
    if (isGridEmpty()) {
      addToast(
        t(
          `Failure - There must be at least 1 child recorded across all age ranges.`
        ),
        {
          appearance: "error",
        }
      );
    } else {
      if (isError) {
        addToast(t(`Failure - Unable to Submit Estimate - validation error.`), {
          appearance: "error",
        });
        setSaveTriggered(true);
        setSaving(false);
      } else {
        sendRequestTo("SUBMIT");
      }
    }
    toggleModal("isOpenSubmitEstimateConfirmModal", false);
  };

  const getRowClassName = (row) => {
    return Object.keys(validationErrors[row.ageRange] || {}).length > 0 &&
      saveTriggered
      ? "row-style-me-grid"
      : "";
  };


  const checkEstimateScreenLevel = () => {
    axios
      .get(`/api/impulse-config/EYF_EST_AGG`)
      .then(({ data }) => {
        if (data.active === "Y") {
          fetchGridData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      {readOnly ? (
        <AggReadOnlyGridView rows={rows} t={t} />
      ) : (
        <DataGrid
          id="_EstimateTotalLevelScreen"
          columns={[
            {
              key: "ageRange",
              name: t("Age"),
              filterable: true,
              sortable: true,
              filterRenderer: LookupFilter,
              lookups: eyAgeRange,
              formatter: LookupFormatter,
            },
            {
              key: "totalChild",
              name: t("No. of Children"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter,
              editor: NumberEditor,
            },
            {
              key: "totalExpHrs",
              name: t("Total Expanded Hours"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter,
              editor: NumberDecimalEditor,
            },
            {
              key: "totalUniHrs",
              name: t("Total Funded Hours"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter,
              editor: NumberDecimalEditor,
            },
            {
              key: "totalExtHrs",
              name: t("Total Extended Hours"),
              filterable: true,
              sortable: true,
              filterRenderer: DefaultFilter,
              editor: NumberDecimalEditor,
            },
          ]}
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              {saveTriggered && validationErrors[row.ageRange] && (
                <Popup
                  trigger={
                    <Icon>
                      <i
                        className="fa-solid fa-circle-exclamation"
                        style={{ color: "#CF2A27" }}
                      />
                    </Icon>
                  }
                  content={Object.values(validationErrors[row.ageRange]).join(
                    "\n"
                  )}
                />
              )}
            </div>
          )}
          rows={rows}
          onRowsUpdated={handleGridRowsUpdated}
          rowClassNameGetter={getRowClassName}
          handleRowKeyGetter={handleRowKeyGetter}
        />
      )}
      <br />
      {rowsChanged && (
        <UserAccessComponent requires={"PPORT_EYF_EST_AGG_L_SAVE"}>
          <Button
            onClick={() => onSaveEstimate()}
            loading={saving}
            className={readOnly ? "grey" : "green"}
            disabled={readOnly}
          >
            <Icon name="fa-solid fa-floppy-disk" />
            {t("Save")}
          </Button>
        </UserAccessComponent>
      )}
      {rowsChanged && (
        <UserAccessComponent requires={"PPORT_EYF_EST_AGG_L_SUB"}>
          <Button
            onClick={() =>
              toggleModal("isOpenSubmitEstimateConfirmModal", true)
            }
            loading={saving}
            className={readOnly ? "grey" : "green"}
            disabled={readOnly}
          >
            <Icon name="fa-solid fa-circle-check" />
            {t("Submit Estimate")}
          </Button>
        </UserAccessComponent>
      )}
      <ApplyConfirmationModal
        modalHeader={"Submit Estimate"}
        message={configPeriodMsg}
        continueMsg={t(ESTIMATE_USER_MESSAGES.continueMsg)}
        isModalOpen={isOpenSubmitEstimateConfirmModal}
        onClose={() => toggleModal("isOpenSubmitEstimateConfirmModal", false)}
        onConfirm={onSubmitEstimate}
        confirmText={t("Submit")}
      />
    </>
  );
};

export default AggregateGridView;
