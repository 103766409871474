import React, { useState } from "react";
import axios from "axios";
import { Button, Container, Form, Icon, Message } from "semantic-ui-react";
import { useForm, FormProvider } from "react-hook-form";
import { Input } from "@caci/react-form-fields";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { handleFormValidationError } from "../utils";
import {useTranslation} from "react-i18next";

const validations = yup.object().shape({
  password: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  ERROR: 2,
  SUCCESS: 3,
};

function PasswordResetScreen() {
  const { key } = useParams();
  const [status, setStatus] = useState(STATUS.INIT);
  const form = useForm({
    resolver: yupResolver(validations),
  });

  const { t } = useTranslation();

  return (
    <Container>
      {[STATUS.INIT, STATUS.IN_PROG].includes(status) && (
        <FormProvider {...form}>
          <Form
            onSubmit={form.handleSubmit(({ password }) => {
              setStatus(STATUS.IN_PROG);
              axios
                .post("/api/reset", {
                  key,
                  password,
                })
                .then(() => setStatus(STATUS.SUCCESS))
                .catch((error) => {
                  if (handleFormValidationError(form.setError)(error)) {
                    setStatus(STATUS.INIT);
                  } else {
                    setStatus(STATUS.ERROR);
                  }
                });
            })}
          >
            <Input label={t("New Password")} name="password" type="password" />
            <Input label={t("Confirm New Password")} name="confirmPassword" type="password" />
            <Button
              disabled={status === STATUS.IN_PROG}
              loading={status === STATUS.IN_PROG}
            >
              Submit
            </Button>
            <Button
              as={Link}
              to="/public/login"
              disabled={status === STATUS.IN_PROG}
            >
              {t("Cancel")}
            </Button>
          </Form>
        </FormProvider>
      )}
      {(status === STATUS.ERROR || status === STATUS.SUCCESS) && (
        <Message
          icon
          error={status === STATUS.ERROR}
          success={status === STATUS.SUCCESS}
          as={status === STATUS.SUCCESS ? Link : Message}
          to="/public/login"
        >
          {status === STATUS.ERROR && <Icon name="warning" />}
          {status === STATUS.SUCCESS && <Icon name="check" />}
          <Message.Content>
            <Message.Header>
              {status === STATUS.ERROR && t("Request Failed")}
              {status === STATUS.SUCCESS && t("Account Reset")}
            </Message.Header>
            {status === STATUS.ERROR && t("Please contact your administrator.")}
            {status === STATUS.SUCCESS &&
                t("Please click here to go back to the login screen.")}
          </Message.Content>
        </Message>
      )}
    </Container>
  );
}

export default PasswordResetScreen;
