import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  DatePickerCellEditor,
  DropDownEditor,
  NumberDecimalEditor,
  LookupFilter,
  LookupFormatter,
  filterDateValues,
  FlagFormatter,
  filterLookupValues,
  filterTextValues,
  filterArrayValues
} from "@caci/react-grid";
import { yesNoFilterOptions } from "../../utils";
import FilterHeaderRenderer from "../../filters/FilterHeaderRenderer";
import DropdownFilterHeaderRenderer from "../../filters/DropdownFilterHeaderRenderer";
import { fundingTypeList, renderIncludeChildIcon } from "./FundingUtils";
import useLookup from "../../consumer/LookupConsumer";

const useFundingCommon =(rows, filters={}) => {
  const { t } = useTranslation();
  const { lookups: eyAgeRange } = useLookup(6214);
  const { lookups: stretchedClaim } = useLookup(6237);
  const columns = useMemo(() => [
    {
      key: "id",
      name: t("Child ID"),
      filterable: true,
      sortable: true,
      frozen: true,
      filterRenderer: DefaultFilter,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="id" />
    },
    {
      key: "childName",
      name: t("Child Name"),
      filterable: true,
      sortable: true,
      frozen: true,
      filterRenderer: DefaultFilter,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childName" />
    },
    {
      key: "dob",
      name: t("Child DOB"),
      filterable: true,
      filterRenderer: DateFilter,
      formatter: DateFormatter,
      sortable: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="dob" />
    },
    {
      key: "fundingAge",
      name: t("Funding Age"),
      filterable: true,
      filterRenderer: DefaultFilter,
      sortable: true,
      lookups: eyAgeRange,
      formatter: LookupFormatter,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="fundingAge" />
    },
    {
      key: "stretchedInd",
      name: t("Stretched Claim"),
      filterable: true,
      sortable: true,
      filterRenderer: LookupFilter,
      lookups: stretchedClaim,
      formatter: LookupFormatter,
      allowHide: true,
      editor: DropDownEditor,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="stretchedInd" />
    },
    {
      key: "expHrsPerWeek",
      name: t("Expanded Hours"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      editor: NumberDecimalEditor,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="expHrsPerWeek" />
    },
    {
      key: "uniHrsPerWeek",
      name: t("Funded Hours"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      editor: NumberDecimalEditor,
      allowHide: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="uniHrsPerWeek" />
    },
    {
      key: "extHrsPerWeek",
      name: t("Extended Hours"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      editor: NumberDecimalEditor,
      allowHide: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="extHrsPerWeek" />
    },
    {
      key: "fundedWeeks",
      name: t("Weeks"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      allowHide: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="fundedWeeks" />
    },
    {
      key: "totalFundedHours",
      name: t("Total Funded Hours"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      allowHide: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="totalFundedHours" />
    },
    {
      key: "childStartDate",
      name: t("Start Date"),
      filterable: true,
      filterRenderer: DateFilter,
      formatter: DateFormatter,
      sortable: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childStartDate" />
    },
    {
      key: "childFundStart",
      name: t("Funding Start Date"),
      filterable: true,
      filterRenderer: DateFilter,
      formatter: DateFormatter,
      editor: DatePickerCellEditor,
      sortable: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childFundStart" />
    },
    {
      key: "childEndDate",
      name: t("End Date"),
      filterable: true,
      filterRenderer: DateFilter,
      formatter: DateFormatter,
      sortable: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childEndDate" />
    },
    {
      key: "childFundEnd",
      name: t("Funding End Date"),
      filterable: true,
      filterRenderer: DateFilter,
      formatter: DateFormatter,
      editor: DatePickerCellEditor,
      sortable: true,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childFundEnd" />
    },
    {
      key: 'flags',
      name: t('Eligibility'),
      filterable: true,
      sortable: true,
      allowHide: true,
      filterRenderer: DefaultFilter,
      lookups: fundingTypeList,
      formatter: FlagFormatter,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="flags" />
    },
    {
      key: "includeChild",
      name: t("Include in Submission"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      formatter: renderIncludeChildIcon,
      headerCellClass: 'filter-cell',
      headerRenderer: (p) => (
        <DropdownFilterHeaderRenderer
          {...p}
          columnKey="includeChild"
          options={yesNoFilterOptions}
        />
      )
    },

  ], [eyAgeRange, stretchedClaim]);

  // Generate filtered rows
  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      if (filters) {
        return (
          (filters.id ? filterTextValues(r, filters.id, "id") : true) &&
          (filters.childName ? filterTextValues(r, filters.childName, "childName") : true) &&
          (filters.dob ? filterDateValues(r, filters.dob, "dob") : true) &&
          (filters.fundingAge ? filterLookupValues(r, filters.fundingAge, "fundingAge", eyAgeRange) : true) &&
          (filters.stretchedInd ? filterLookupValues(r, filters.stretchedInd, "stretchedInd", stretchedClaim) : true) &&
          (filters.expHrsPerWeek ? filterTextValues(r, filters.expHrsPerWeek, "expHrsPerWeek") : true) &&
          (filters.uniHrsPerWeek ? filterTextValues(r, filters.uniHrsPerWeek, "uniHrsPerWeek") : true) &&
          (filters.extHrsPerWeek ? filterTextValues(r, filters.extHrsPerWeek, "extHrsPerWeek") : true) &&
          (filters.fundedWeeks ? filterTextValues(r, filters.fundedWeeks, "fundedWeeks") : true) &&
          (filters.totalFundedHours ? filterTextValues(r, filters.totalFundedHours, "totalFundedHours") : true) &&
          (filters.childStartDate ? filterDateValues(r, filters.childStartDate, "childStartDate") : true) &&
          (filters.childFundStart ? filterDateValues(r, filters.childFundStart, "childFundStart") : true) &&
          (filters.childEndDate ? filterDateValues(r, filters.childEndDate, "childEndDate") : true) &&
          (filters.childFundEnd ? filterDateValues(r, filters.childFundEnd, "childFundEnd") : true) &&
          (filters.flags ? filterArrayValues(r, filters.flags, "flags") : true) &&
          (filters.includeChild && filters.includeChild !== 'All' ? r.includeChild === filters.includeChild : true)
        );
      }
      return true;
    });
  }, [rows, filters, eyAgeRange]);

  return { columns, filteredRows };
};

export default useFundingCommon;
