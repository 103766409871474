import React from 'react';
import { Grid, Icon, List } from 'semantic-ui-react';

const valueStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

const getStatusColour = (status = '') => {
  switch (status) {
    case 'NST' :
      return '#9900FF';
    case 'INP' :
      return '#FF9900';
    case 'SMT' :
      return '#2B78E4';
    case 'REJ' :
      return '#CF2A27';
    case 'APD' :
    case 'CPT' :
      return '#009E0F';
  }
};

const getFormattedHeader = (prDesc='', subType='', submissionTypeCode) => {
  let result = prDesc + ' ';
  result += (submissionTypeCode === 'AMD') ? 'Funding Period' : subType;
  return result;
};

const FundingPeriodHeaderDetails = ({ periodDescription, submissionType, submissionStatus, daysLeft, eySubmissionStatus, t, submissionTypeCode }) => {
  return (
    <Grid className="no-border align-center padding-right-0" stretched>
      <Grid.Column width={2} verticalAlign="middle" textAlign="center" className="padding-bottom-14">
        <Icon size="large" id="fundingPeriod" style={{ color: getStatusColour(submissionStatus), marginRight: '1rem' }}>
          <i className="fa-regular fa-clock-ten" />
        </Icon>
      </Grid.Column>
      <Grid.Column width={14} verticalAlign="middle" className="padding-bottom-14">
        <List size="large">
          <List.Item id="eyfHeader">
            <List.Content>
              <List.Header id="summerEstimate">{t(getFormattedHeader(periodDescription, submissionType, submissionTypeCode))}</List.Header>
            </List.Content>
          </List.Item>
          {(daysLeft || daysLeft === 0) && (
            <List.Item id="daysLeft">
              <List.Content>{t(`${daysLeft} Days Remaining`)}</List.Content>
            </List.Item>
          )}
          <List.Item id="status">
            <List.Content>
              <List.Description style={valueStyle}>
                {eySubmissionStatus}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
      </Grid.Column>
    </Grid>
  );
};

export default FundingPeriodHeaderDetails;
