import {Fragment, useEffect, useState} from "react";
import {
    DateFilter,
    DateFormatter,
    DefaultFilter,
    Grid as AssessmentGrid,
    LookupFilter,
    LookupFormatter,
} from "@caci/react-grid";
import axios from "axios";
import {Button, Form, Grid, Icon, Menu, Popup, Segment,} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {lookupCodeToValue} from "../utils";
import useLookup from "../consumer/LookupConsumer";
import UserAccessComponent from "./UserAccessComponent";
import {withRouter} from "react-router-dom";
import moment from "moment";

function EarlyAssessmentReviewScreen({ match }) {
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState([]);
  const [outReview, setOutReview] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const { lookups: earlyYearsAssessmentReviewType } = useLookup(6112);
  const { t } = useTranslation();

  const fetchEarlyAssessmentReviews = () => {
    axios
      .get(`/api/early-assessment-review/${match.params.id}`)
      .then(({ data }) => {
        if (data.length > 0) setRows(data);
      })
      .catch(console.log);
  };

  const fetchAssessmentReviewOutcome = (id) => {
    axios
      .get(`/api/early-assessment-review-out-list/${id}`)
      .then(({ data: out }) => {
        setOutReview(out);
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchEarlyAssessmentReviews();
  }, []);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Progress Review")}
        </Menu.Item>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <div>
              <AssessmentGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_AS_REV_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            link
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              setEditData(row);
                              fetchAssessmentReviewOutcome(row.id);
                            }}
                          ><i className="fas fa-search"/></Icon>
                        }
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "id",
                    name: t("ID"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "reviewDate",
                    name: t("Progress Review Date"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DateFilter,
                    formatter: DateFormatter,
                  },
                  {
                    key: "reviewType",
                    name: t("Review Type"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: LookupFilter,
                    formatter: LookupFormatter,
                    lookups: earlyYearsAssessmentReviewType,
                  },
                  {
                    key: "orgPosIdName",
                    name: t("Completed By"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DefaultFilter,
                  },
                  {
                    key: "completedDate",
                    name: t("Completed Date"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: DateFilter,
                    formatter: DateFormatter,
                  },
                ]}
                rows={rows}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Form.Input label={t("ID")} name="id" value={editData.id} />
                    <Form.Input
                      label={t("Progress Review Date")}
                      name="reviewDate"
                      value={
                        editData.reviewDate
                          ? moment(editData.reviewDate).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                    <Form.Input
                      label={t("Review Type")}
                      name="reviewType"
                      value={lookupCodeToValue(
                        earlyYearsAssessmentReviewType,
                        editData.reviewType
                      )}
                    />
                    <Form.Input
                      label={t("Completed By")}
                      name="orgPosIdName"
                      value={editData.orgPosIdName}
                    />
                    <Form.Input
                      label={t("Completed Date")}
                      name="completedDate"
                      value={
                        editData.completedDate
                          ? moment(editData.completedDate).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Menu attached="top" className={"border-bottom"}>
                      <Menu.Item header className={"active"}>
                        {t("Review of Outcomes")}
                      </Menu.Item>
                    </Menu>
                    <Segment attached={"bottom"}>
                      {outReview.map((out) => (
                        <div className={"padding-bottom-10"}>
                          <b>
                            {" "}
                            <p
                              className={"custom-label-color padding-bottom-5"}
                            >
                              {out.outcomeArea}
                            </p>
                          </b>

                          <p>
                            {" "}
                            <b>{"progress Comments" + ":  "}</b>
                            <span>
                              {out.progressReview.replace(
                                /<\/?[^>]+(>|$)/g,
                                ""
                              )}
                            </span>
                          </p>
                        </div>
                      ))}
                    </Segment>
                  </Grid.Column>
                </Grid.Row>
                <Button
                  onClick={() => {
                    setShowDetail(false);
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
              </Grid>
            </Form>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default withRouter(EarlyAssessmentReviewScreen);
