import { useEffect, useState } from "react";
import { Button, Modal, Icon, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { addZeroes, lookupCodeToValue } from "../utils";
import CourseSessionsModal from "./CourseSessionsModal";
import useGridRows from "../hooks/useGridRows";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import UserAccessComponent from "./UserAccessComponent";
import CourseApplicationModal from "./CourseApplicationModal";
import CourseApplicationStatusModal from "./CourseApplicationStatusModal";
import CourseEvaluationProfileModal from "./CourseEvaluationProfileModal";

function CourseDetailsModal({
  isModalOpen = false,
  row = {},
  onClose,
  levelLookups,
  districtLookups,
  subjectCodeLookups
}) {
  const [courseCandidate, setCourseCandidate] = useState({});
  const [isCourseEvaluationEnable, setCourseEvaluationEnable] = useState(false);
  const { selectedProvider, userDetails } = useSession();
  const [ info, setInfo] = useState({});
  const { t } = useTranslation();
  const [isCourseSessionModalOpen, setCourseSessionModalOpen] = useState(false);
  const [
    isCourseApplicationModalOpen,
    setCourseApplicationModalOpen
  ] = useState(false);
  const [
    isCourseApplicationStatusModalOpen,
    setCourseApplicationStatusModalOpen
  ] = useState(false);
  const [
    isCourseEvaluationProfileModalOpen,
    setCourseEvaluationProfileModalOpen
  ] = useState(false);
  const [isApplyForStaff, setApplyForStaff] = useState(false);

  useEffect(() => {
    if (row.courseDatesId && !isCourseApplicationModalOpen) {
      axios
        .get(
          `api/course-candidate/${userDetails.personId}/${selectedProvider.providerId}/${row.courseDatesId}`
        )
        .then(({ data }) => setCourseCandidate(data))
        .catch(console.log);
      axios
        .get(
          `api/course-candidate-evaluation/${userDetails.personId}/${row.courseDatesId}`
        )
        .then(({ data }) => {
          const isValue = data.some((row) => (row.attendedInd === "Y" && row.courseEvaluationId !== 0));
          setCourseEvaluationEnable(isValue);
        })
        .catch(console.log);
    }
    axios
        .get("/api/impulse-config/CAND_WRN")
        .then(({ data }) => setInfo(data))
        .catch(console.log);
  }, [
    selectedProvider.providerId,
    userDetails.personId,
    row.courseDatesId,
    isCourseApplicationModalOpen
  ]);
  return (
    <>
      <CourseSessionsModal
        isModalOpen={isCourseSessionModalOpen}
        onClose={() => setCourseSessionModalOpen(false)}
        courseDatesId={row.courseDatesId}
        placeInd={courseCandidate.placeInd}
      />

      <CourseApplicationModal
        isModalOpen={isCourseApplicationModalOpen}
        isApplyForStaff={isApplyForStaff}
        course={row}
        onClose={() => {
          setCourseApplicationModalOpen(false);
          setApplyForStaff(false);
        }}
        info={info}
      />

      <CourseApplicationStatusModal
        course={row}
        courseCandidate={courseCandidate}
        isModalOpen={isCourseApplicationStatusModalOpen}
        onClose={() => setCourseApplicationStatusModalOpen(false)}
      />

      <CourseEvaluationProfileModal
        course={row}
        isModalOpen={isCourseEvaluationProfileModalOpen}
        onClose={() => setCourseEvaluationProfileModalOpen(false)}
      />

      <Modal
        open={
          isModalOpen &&
          !isCourseSessionModalOpen &&
          !isCourseApplicationModalOpen &&
          !isCourseApplicationStatusModalOpen &&
          !isCourseEvaluationProfileModalOpen
        }
        size="medium"
        onClose={onClose}
      >
        <Modal.Header>{t("Course Details")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              fluid
              label={t("Course Code")}
              value={row.courseCode}
              readOnly
            />

            <Form.Input
              fluid
              label={t("Course Name")}
              value={row.courseDesc}
              readOnly
            />

            <Form.Input
              fluid
              label={t("Course Area")}
              value={lookupCodeToValue(subjectCodeLookups, row.subjectCode)}
              readOnly
            />

            <Form.Input
              fluid
              label={t("Max Attendees")}
              value={row.maxAttend}
              readOnly
            />

            <Form.TextArea
              fluid
              label={t("Course Description")}
              value={row.courseContent}
              rows={6}
              readOnly
            />

            <Form.TextArea
              fluid
              label={t("Course Aims")}
              value={row.courseAim}
              rows={6}
              readOnly
            />

            <Form.Group>
              <Form.Input
                fluid
                label={t("District")}
                value={lookupCodeToValue(districtLookups, row.district)}
                width={8}
                readOnly
              />

              <Form.Input
                fluid
                label={t("Level")}
                value={lookupCodeToValue(levelLookups, row.level)}
                width={8}
                readOnly
              />
            </Form.Group>

            <Form.Group>
              <Form.Input
                fluid
                label={t("Cost for LA School\\Setting")}
                value={row.laCost ? addZeroes(row.laCost, 2) : "0.00"}
                width={8}
                readOnly
              />

              <Form.Input
                fluid
                label={t("Cost for Other School\\Setting")}
                value={row.othCost ? addZeroes(row.othCost, 2) : "0.00"}
                width={8}
                readOnly
              />
            </Form.Group>

            <Form.TextArea
              fluid
              label={t("Additional Information")}
              value={row.addText ? row.addText : ""}
              rows={5}
              readOnly
            />

            <Form.Input
              fluid
              type="date"
              label={t("Start Date")}
              value={row.startDate}
              readOnly
            />

            <Form.Input
              fluid
              type="date"
              label={t("End Date")}
              value={row.endDate}
              readOnly
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="orange" onClick={onClose}>
            <Icon name="remove circle" />
            {t("Close")}
          </Button>

          <Button
            onClick={() => setCourseSessionModalOpen(true)}
            className="light-blue"
          >
            <Icon>
              <i className="fal fa-calendar-week" />
            </Icon>
            {t("View Sessions")}
          </Button>

          {Object.keys(courseCandidate).length === 0 ? (
            <UserAccessComponent requires="PPORT_TR_CM_APP">
              <Button
                onClick={() => setCourseApplicationModalOpen(true)}
                className="green"
              >
                <Icon>
                  <i className="fal fa-file-edit" />
                </Icon>
                {t("Apply")}
              </Button>
            </UserAccessComponent>
          ) : (
            <Button
              onClick={() => setCourseApplicationStatusModalOpen(true)}
              className="green"
            >
              <Icon>
                <i className="fal fa-file-alt" />
              </Icon>
              {t("Application Status")}
            </Button>
          )}

          <UserAccessComponent requires="PPORT_TR_CM_APP_STAFF">
            <Button
              onClick={() => {
                setApplyForStaff(true);
                setCourseApplicationModalOpen(true);
              }}
              className="green"
            >
              <Icon>
                <i className="fal fa-file-edit" />
              </Icon>
              {t("Apply for Staff")}
            </Button>
          </UserAccessComponent>
          {isCourseEvaluationEnable && (
          <UserAccessComponent requires="PPORT_TR_CM_EVAL">
            <Button
              onClick={() => setCourseEvaluationProfileModalOpen(true)}
              className="light-blue"
            >
              <Icon>
                <i className="fal fa-sort-amount-up" />
              </Icon>
              {t("Course Evaluation")}
            </Button>
          </UserAccessComponent>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default CourseDetailsModal;
