import React, { useEffect, useMemo, useState } from "react";
import { Form, Icon, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import useLookup from "../../consumer/LookupConsumer";
import axios from "axios";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as DataGrid,
  FlagFormatter,
  LookupFilter,
  LookupFormatter,
  filterDateValues,
  filterLookupValues,
  filterTextValues,
  filterArrayValues
} from "@caci/react-grid";
import { concat, insert, insertAll } from "ramda";
import UserAccessComponent from "../../components/UserAccessComponent";
import { ButtonNewChild } from "@caci/react-form-fields";
import FilterHeaderRenderer from "../../filters/FilterHeaderRenderer";
import { useFilters } from "../../filters/FilterContext";
import LoadingMessage from "../../components/LoadingMessage";
import { fundingTypeList } from "./FundingUtils";

const LiveRegister = () => {
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { lookups: languages } = useLookup(102);
  const { lookups: ethnicities } = useLookup(101);
  const { lookups: leaCodes } = useLookup(301);
  const { lookups: eyAgeRange } = useLookup(6214);
  const { lookups: stretchedClaim } = useLookup(6237);
  const history = useHistory();

  const [includeChildren, setIncludeChildren] = useState("HPC");
  const [sectionView, setSectionView] = useState("FV");
  const [rows, setRows] = useState([]);
  const [searching, setSearching] = useState(true);
  const { filters } = useFilters();

  useEffect(() => {
    if (providerId) {
      getEarlyAssessmentList();
    }
  }, [providerId]);

  const getEarlyAssessmentList = () => {
    axios
      .get(`/api/eyp-child/live-register/${providerId}`)
      .then(({ data: earlyAssessmentList }) =>
      {
        setSearching(false);
        setRows(earlyAssessmentList);
      })
      .catch((error) => {
        console.log(error);
        setSearching(false);
      });
  };

  const handleDropdownChange = (event, { value, name }) => {
    if (name === "sectionView") {
      setSectionView(value);
    } else if (name === "includeChildren") {
      setIncludeChildren(value);
    }
  };

  const transformRows = (rows) => {
    const filteredData = rows.filter((child) => {
      if (includeChildren === "HPC") {
        return child.childEndDate === null && child.childNeverAttendInd !== "Y";
      } else if (includeChildren === "IPC") {
        return child.childNeverAttendInd !== "Y";
      }
    });

    filteredData.map((child) => {
      //childName
      const forename = child.forename || "";
      const middleName = child.middleName ? ` ${child.middleName}` : "";
      const surname = child.surname || "";
      child["childName"] = `${forename}${middleName} ${surname}`.trim();

      //responsibleLa
      const leaDescription = leaCodes.find(
        (y) => y.codeValue === child.areaCodeValue
      )?.description;
      child["responsibleLa"] = leaDescription
        ? `${leaDescription}(${child.areaCodeValue})`
        : "";

      //parentCarerName
      const parentForename = child.parentForename || "";
      const parentSurname = child.parentSurname || "";
      const parentId = child.parentId || "";

      if (parentId) {
        child["parentCarerName"] =
          `${parentForename} ${parentSurname}(${parentId})`.trim();
      } else {
        child["parentCarerName"] = "";
      }
    });
    return filteredData;
  };

  const getColumns = useMemo(() => {
    const addressCol = {
      key: "address",
      name: t("Address"),
      filterable: true,
      sortable: true,
      filterRenderer: DefaultFilter,
      allowHide: true,
      width: 200,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="address" />
    };

    const fundingTypeCol = {
      key: 'flags',
      name: t('Eligibility'),
      filterable: true,
      sortable: true,
      allowHide: true,
      filterRenderer: DefaultFilter,
      lookups: fundingTypeList,
      formatter: FlagFormatter,
      headerCellClass: 'filter-cell',
      headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="flags" />
    }

    const columns = [
      {
        key: "childId",
        name: t("EYC ID"),
        filterable: true,
        sortable: true,
        frozen: true,
        filterRenderer: DefaultFilter,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childId" />
      },
      {
        key: "childName",
        name: t("Child Name"),
        filterable: true,
        sortable: true,
        frozen: true,
        filterRenderer: DefaultFilter,
        width: 150,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childName" />
      },
      {
        key: "dateOfBirth",
        name: t("Birthdate"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        formatter: DateFormatter,
        width: 100,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="dateOfBirth" />
      },
      {
        key: "fundingAge",
        name: t("Funding Age"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookups: eyAgeRange,
        formatter: LookupFormatter,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="fundingAge" />
      },

      {
        key: "childStartDate",
        name: t("Start Date"),
        filterable: true,
        sortable: true,
        filterRenderer: DateFilter,
        formatter: DateFormatter,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childStartDate" />
      },
      {
        key: "childEndDate",
        name: t("End Date"),
        filterable: true,
        sortable: true,
        filterRenderer: DateFilter,
        formatter: DateFormatter,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="childEndDate" />
      },
    ];

    const masterViewCols = [
      {
        key: "ethnicity",
        name: t("Ethnicity"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookups: ethnicities,
        formatter: LookupFormatter,
        allowHide: true,
        width: 100,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="ethnicity" />
      },
      {
        key: "language",
        name: t("Language"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookups: languages,
        formatter: LookupFormatter,
        allowHide: true,
        width: 100,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="language" />
      },
      {
        key: "deprivationIndex",
        name: t("Deprivation Index"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        allowHide: true,
        width: 100,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="deprivationIndex" />
      },
      {
        key: "responsibleLa",
        name: t("Responsible LA"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="responsibleLa" />
      },
    ];

    const contactViewCols = [
      {
        key: "parentCarerName",
        name: t("Parent or Carer Name (ID)"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="parentCarerName" />
      },
      {
        key: "personId",
        name: t("Person ID"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="personId" />
      },
      {
        key: "contactNo",
        name: t("Parent or Carer Contact Number"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="contactNo" />

      },
      {
        key: "email",
        name: t("Parent or Carer Email"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        width: 150,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="email" />
      },
    ];



    const fundingViewCols = [
      {
        key: "stretchedInd",
        name: t("Stretched Claim"),
        filterable: true,
        sortable: true,
        filterRenderer: LookupFilter,
        lookups: stretchedClaim,
        formatter: LookupFormatter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="stretchedInd" />
      },
      {
        key: "expHrsPerWeek",
        name: t("Expanded Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="expHrsPerWeek" />
      },
      {
        key: "uniHrsPerWeek",
        name: t("Funded Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="uniHrsPerWeek" />
      },
      {
        key: "extHrsPerWeek",
        name: t("Extended Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="extHrsPerWeek" />
      },
      {
        key: "fundedWeeks",
        name: t("Weeks"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="fundedWeeks" />
      },
      {
        key: "totalFundingHours",
        name: t("Total Funded Hours"),
        filterable: true,
        sortable: true,
        filterRenderer: DefaultFilter,
        allowHide: true,
        headerCellClass: 'filter-cell',
        headerRenderer:  (p) => <FilterHeaderRenderer {...p} columnKey="totalFundingHours" />
      },
    ];

    if (sectionView === "CV") {
      const cvCols = insert(0, addressCol, contactViewCols);
      return insertAll(5, cvCols, columns);
    } else if (sectionView === "FV") {
      const fVCols = insertAll(5, [...fundingViewCols], columns);
      return concat(fVCols, [fundingTypeCol]);
    } else {
      const mvCols = insert(2, addressCol, masterViewCols);
      return insertAll(
        5,
        [...mvCols, ...fundingViewCols, ...contactViewCols, fundingTypeCol],
        columns
      );
    }
  }, [sectionView, includeChildren, eyAgeRange, stretchedClaim]);

  const filteredRows = useMemo(() => {
    return rows.filter((r) => {
      return (
        filterTextValues(r, filters.address, "address") &&
        (filters.fundingType !== 'All' ? r.fundingType === filters.fundingType : true) &&
        filterTextValues(r, filters.childId, "childId") &&
        filterTextValues(r, filters.childName, "childName") &&
        filterDateValues(r, filters.dateOfBirth, "dateOfBirth") &&
        filterLookupValues(r, filters.fundingAge, "fundingAge", eyAgeRange) &&
        filterDateValues(r, filters.childStartDate, "childStartDate") &&
        filterDateValues(r, filters.childEndDate, "childEndDate") &&
        filterLookupValues(r, filters.ethnicity, "ethnicity", ethnicities) &&
        filterLookupValues(r, filters.language, "language", languages) &&
        filterTextValues(r, filters.deprivationIndex, "deprivationIndex") &&
        filterTextValues(r, filters.responsibleLa, "responsibleLa") &&
        filterTextValues(r, filters.parentCarerName, "parentCarerName") &&
        filterTextValues(r, filters.personId, "personId") &&
        filterTextValues(r, filters.contactNo, "contactNo") &&
        filterTextValues(r, filters.email, "email") &&
        (filters.stretchedInd ? filterLookupValues(r, filters.stretchedInd, "stretchedInd", stretchedClaim) : true) &&
        filterTextValues(r, filters.expHrsPerWeek, "expHrsPerWeek") &&
        filterTextValues(r, filters.uniHrsPerWeek, "uniHrsPerWeek") &&
        filterTextValues(r, filters.extHrsPerWeek, "extHrsPerWeek") &&
        filterTextValues(r, filters.fundedWeeks, "fundedWeeks") &&
        filterTextValues(r, filters.totalFundingHours, "totalFundingHours") &&
        (filters.flags ? filterArrayValues(r, filters.flags, "flags") : true)
      );
    });
  }, [rows, filters]);

  if (searching) return <LoadingMessage />;

  return (
    <div>
      <Segment attached="bottom">
        <Form>
          <Form.Group widths={6}>
            <Form.Dropdown
              style={{ maxWidth: 300 }}
              fluid
              selection
              search
              onChange={handleDropdownChange}
              value={sectionView}
              name="sectionView"
              options={[
                { text: t("Master View"), value: "MV" },
                { text: t("Funding View"), value: "FV" },
                { text: t("Contact View"), value: "CV" },
              ]}
            />
            <Form.Dropdown
              style={{ maxWidth: 300 }}
              fluid
              selection
              search
              onChange={handleDropdownChange}
              value={includeChildren}
              name="includeChildren"
              options={[
                { text: t("Hide previous children"), value: "HPC" },
                { text: t("Include previous children"), value: "IPC" },
              ]}
            />
          </Form.Group>
        </Form>
      </Segment>
      <Segment attached="bottom">
        <DataGrid
          id="_LiveRegisterGrid"
          actionsCell={({ row }) => (
            <div style={{ textAlign: "center" }}>
              <UserAccessComponent requires="PPORT_EYF_LR_CHILD_EDIT">
                <Popup
                  trigger={
                    <Icon
                      circular
                      link
                      name="pencil"
                      className={"dark-blue-border"}
                      onClick={() =>
                        history.push(
                          `/portal/${providerId}/provider/provider-funding/live-register/${row.childId}`
                        )
                      }
                    />
                  }
                  content={t("Edit")}
                />
              </UserAccessComponent>
              <UserAccessComponent requires="PPORT_EYF_LR_CHILD_SIB_ADD">
                <Popup
                  trigger={
                    <Icon
                      className={"light-green-border"}
                      circular
                      link
                      onClick={() =>
                        history.push(
                          `/portal/${providerId}/provider/provider-funding/live-register/sib${row.childId}`
                        )
                      }
                    >
                      <i className={"fa-solid fa-children"} />
                    </Icon>
                  }
                  content={t("Add Sibling")}
                />
              </UserAccessComponent>
            </div>
          )}
          columns={getColumns}
          minHeight={550}
          filterable
          enableFilters={filters.enabled}
          rows={transformRows(filteredRows)}
        />
        <br />
        <UserAccessComponent requires="PPORT_EYF_LR_CHILD_ADD">
          <ButtonNewChild
            id={"newChildButton"}
            as={Link}
            to={`/portal/${providerId}/provider/provider-funding/live-register/new`}
            useForm={false}
            content={t("Add Child")}
            iconClassName={"fa-solid fa-child-reaching"}
          />
        </UserAccessComponent>
      </Segment>
    </div>
  );
};

export default LiveRegister;
