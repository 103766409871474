import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { DEFAULT_LANG } from "../translation/i18n";
import LoginScreen from "./LoginScreen";
import RegisterScreen from "./RegisterScreen";
import ActivateScreen from "./ActivateScreen";
import RequestPasswordResetScreen from "./RequestPasswordResetScreen";
import PasswordResetScreen from "./PasswordResetScreen";
import "../assets/tailwind/main.css";
import "../assets/fomantic/dist/semantic.min.css";
function PublicScreen() {
  const [langKey, setLangKey] = useState(DEFAULT_LANG);
  const history = useHistory();

  useEffect(() => {
    if (!!sessionStorage.getItem("jwt")) {
      history.push("/");
    }
  });

  return (
    <>
      <Switch>
        <Route path="/public/login">
          <LoginScreen setLangKey={setLangKey} langKey={langKey} />
        </Route>
        <Route path="/public/register">
          <RegisterScreen setLangKey={setLangKey} langKey={langKey} />
        </Route>
        <Route path="/public/activate/:key">
          <ActivateScreen />
        </Route>
        <Route path="/public/reset/:key">
          <PasswordResetScreen />
        </Route>
        <Route path="/public/reset">
          <RequestPasswordResetScreen
            setLangKey={setLangKey}
            langKey={langKey}
          />
        </Route>
      </Switch>
    </>
  );
}

export default PublicScreen;
