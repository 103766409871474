import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Message,
  Icon,
  Divider,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Input, Dropdown } from "@caci/react-form-fields";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { handleFormValidationError } from "../utils";
import { useTranslation } from "react-i18next";
import { merge } from "lodash/object";
import backtoschool from "../assets/images/backtoschool.svg";
import LanguageList from "../components/LanguageList";
import HelpText from "../components/HelpText";

const validations = yup.object().shape({
  forename: yup.string().required("Required"),
  surname: yup.string().required("Required"),
  login: yup
    .string()
    .required("Required")
    .matches(/^[a-zA-Z0-9]+$/, "Your username can only contain letters and numbers")
    .max(30, "30 character limit exceeded"),
  email: yup.string().required("Required").email("Please enter a valid email"),
  password: yup.string().required("Required"),
  confirmPassword: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password")], "Passwords must match"),
  providerId: yup.string().required("Required"),
  startDate: yup.string().required("Required"),
});

const STATUS = {
  INIT: 0,
  IN_PROG: 1,
  DONE: 2,
  ERROR: 3,
};

function RegisterScreen({ setLangKey, langKey }) {
  const { t } = useTranslation();
  const [earlyProviders, setEarlyProviders] = useState([]);
  const [status, setStatus] = useState(STATUS.INIT);

  const form = useForm({
    resolver: yupResolver(validations),
  });

  useEffect(() => {
    axios.get("/api/early-provider").then(({ data }) => {
      setEarlyProviders(data);
    });
  }, []);

  const onSubmit = (values) => {
    const finalValues = merge(values, {
      portalCode: "PROV_PORTAL",
    });

    axios
      .post("/api/register", finalValues)
      .then(() => setStatus(STATUS.DONE))
      .catch(handleFormValidationError(form.setError));
  };

  if (status === STATUS.DONE) {
    return (
      <Container as={Link} to="/public/login">
        <Message icon success>
          <Icon name="check" />
          <Message.Content>
            <b>{t("Thank you")}</b>,{" "}
            {t(
              "you will receive an activation email if registered successfully."
            )}
          </Message.Content>
        </Message>
      </Container>
    );
  }

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">
            {t("Providers Portal")}
          </div>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
              <Input label={t("First Name")} name="forename" />
              <Input label={t("Surname")} name="surname" />
              <Input label={t("Username")} name="login" />
              <Input label={t("Email")} name="email" />
              <Input
                label={
                  <>
                    {t("Password")}{" "}
                    <HelpText
                      contentArea="Registration"
                      contentModule="Request Access"
                      contentType="HELP"
                      contentItem="Password Rules Help Text"
                      accessLevel="Public"
                    />
                  </>
                }
                name="password"
                type="password"
              />
              <Input
                label={t("Confirm Password")}
                name="confirmPassword"
                type="password"
              />
              <Dropdown
                label={t("Provider/Setting")}
                name="providerId"
                options={earlyProviders.map((earlyProvider) => ({
                  value: earlyProvider.providerId,
                  text: `${earlyProvider.providerName} (${earlyProvider.providerId})`,
                }))}
              />
              <Input label={t("Start Date")} name="startDate" type="date" />
              <Button color="blue">{t("Send Request")}</Button>
              <Button className={"orange"} as={Link} to="/public/login">
                {t("Cancel")}
              </Button>
            </Form>
          </FormProvider>
          <div className="mt-6">
            <Divider />
            <LanguageList setLangKey={setLangKey} langKey={langKey} />
          </div>
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={backtoschool} alt="" />
      </div>
    </div>
  );
}

export default RegisterScreen;
