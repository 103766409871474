import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Icon } from 'semantic-ui-react';

const CommentsModal = ({ isModalOpen, onClose, modalHeader, message }) => {
  const { t } = useTranslation();

  return (
    <Modal open={isModalOpen} onClose={onClose} className={'dark-modal'}>
      <Modal.Header className={'modal-header'}>
        {!modalHeader ? t('Comments') : t(modalHeader)}
      </Modal.Header>

      <Modal.Content image className="modal-content">
        <Modal.Description>
          <p className={'text-white-color'}>{t(message)}</p>
        </Modal.Description>
      </Modal.Content>

      <Modal.Actions className="modal-actions" style={{ textAlign: 'left' }}>
        <Button className={'orange'} onClick={onClose}>
          <Icon name="arrow circle left" />
          {t('Back')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default CommentsModal;
