import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

 export const FilterProvider = ({ children, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters || {});

  const updateFilter = (columnKey, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [columnKey]: value,
    }));
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
  };

   const toggleFilters = () => {
     setFilters(prevFilters => ({
       ...initialFilters, // Restore initial filters
       enabled: !prevFilters.enabled, // Toggle the enabled flag
     }));
   };


   return (
    <FilterContext.Provider value={{ filters, updateFilter, updateFilters, toggleFilters, clearFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilters = () => useContext(FilterContext);

