import { Fragment, useEffect, useState } from "react";
import {
  Grid as AvailabilityGrid,
  LookupFilter,
  LookupFormatter
} from "@caci/react-grid";
import axios from "axios";
import {
  Button,
  Form,
  Icon,
  Menu,
  Modal,
  Popup,
  Segment
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  createSyncTrackPutObject,
  getValidationFlag,
  lookupCodeToValue,
  lookupToOptionsTranslation,
  setValueHelper
} from "../utils";
import useLookup from "../consumer/LookupConsumer";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Dropdown } from "@caci/react-form-fields";
import * as yup from "yup";
import { useToasts } from "react-toast-notifications";
import { useSession } from "../consumer/SessionConsumer";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserAccessComponent from "./UserAccessComponent";
import DropdownWithSegments from "./DropdownWithSegments";
import RejectCommentsModal from "./RejectCommentsModal";

const validationFlagsList = ["1", "2", "3"];


function AvailabilityScreen({ setValidationFlag, urlSlug }) {
  const [rows, setRows] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [isQuickAddModalOpen, setQuickAddModalOpen] = useState(false);
  const { lookups: availability } = useLookup(896);
  const { selectedProvider: provider } = useSession();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const [nameValid, setNameValid] = useState([]);

  const schema = yup.object({
    name: yup
      .string()
      .required(t("Please enter a value"))
      .unique({
        message: t("Already in use"),
        existing: nameValid
      })
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const handleDelete = (row) => {
    const data = [{ op: "add", path: "/validationFlag", value: getValidationFlag(row.validationFlag, "D") }];
    const putObject = createSyncTrackPutObject(row.trackId, getValidationFlag(row.validationFlag, "D"));
    axios
      .put(`/api/pport-early-care-availability/${row.id}`, data, putObject)
      .then(() => {
        addToast(`${t("Availability")} ${t("has been successfully removed")}`, {
          appearance: "success"
        });
          const updatedAvailability = rows.filter((record) => record.id !== row.id);
          setRows(updatedAvailability);
      })
      .catch(error => {
        console.log(error);
        addToast(`${t("Availability")} ${t("has been successfully removed")}`, {
          appearance: "error"
        });
      });
  };

  const addAvailability = value => {
    const data = {
      providerId: provider.providerId,
      name: value,
      validationFlag: "I"
    };
    axios
      .post(`/api/pport-early-care-availability`, data)
      .then(() => {
        addToast(`${t("Availability")} ${t("has been successfully created")}`, {
          appearance: "success"
        });
        fetchAvailability();
      })
      .catch(console.log);
  };

  const onSubmit = ({ name, id, validationFlag, trackId }) => {
    const data = {
      providerId: provider.providerId,
      name: name,
      validationFlag: "I"
    };

    const put = [
      { op: "add", path: "/validationFlag", value: getValidationFlag(validationFlag) },
      { op: "add", path: "/name", value: name }
    ];
    const putObject = createSyncTrackPutObject(trackId, getValidationFlag(validationFlag));

    if (id) {
      axios
        .put(`/api/pport-early-care-availability/${id}`, put, putObject)
        .then(() => {
          addToast(
            `${t("Availability")} ${t("has been successfully updated")}`,
            {
              appearance: "success"
            }
          );
          fetchAvailability();
        })
        .catch(console.log);
    } else {
      axios
        .post(`/api/pport-early-care-availability`, data)
          .then(({ data }) => {
          addToast(
            `${t("Availability")} ${t("has been successfully created")}`,
            {
              appearance: "success"
            }
          );
          methods.setValue("id", data.id);
          fetchAvailability();
        })
        .catch(console.log);
    }
  };

  const fetchAvailability = () => {
    axios
      .get(`/api/pport-early-care-availability-list/${provider.providerId}`)
      .then(({ data }) => {
        if (data.length > 0) setRows(data);
        const validationFlagIndicator = data.some(row =>
          validationFlagsList.includes(row.validationFlag)
        );
        setValidationFlag(urlSlug, {
          name: "availability",
          value: validationFlagIndicator
        });
        setShowExclamation(validationFlagIndicator);
      })
      .catch(console.log);
  };

  const { reset } = methods;

  useEffect(() => {
    if (provider.providerId) {
      fetchAvailability();
    }
    return () => {
      reset({
        name: "",
        id: ""
      });
    };
  }, [provider.providerId, reset]);

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Availability")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <Menu.Item>
              <Icon color={"red"}>
                <i className="fas fa-exclamation-triangle" />
              </Icon>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        {!showDetail ? (
          <Fragment>
            <div>
              <AvailabilityGrid
                actionsCell={({ row }) => (
                  <div style={{ textAlign: "center" }}>
                    <UserAccessComponent requires="PPORT_PM_HCP_AV_L_EDIT">
                      <Popup
                        content={t("Edit")}
                        trigger={
                          <Icon
                            circular
                            name="pencil"
                            className={"dark-blue-border"}
                            color="black"
                            onClick={() => {
                              setShowDetail(true);
                              setNameValid(rows.filter(f => f.name !== row.name).map(r => r.name));
                              setValueHelper(row, methods.setValue);
                            }}
                          />
                        }
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_AV_L_EDIT">
                      <RejectCommentsModal
                        rejectComments={row.rejectComments}
                        rejectedDate={row.rejectedDate}
                        rejectedBy={row.rejectedBy}
                        disabled={
                          !validationFlagsList.includes(row.validationFlag)
                        }
                        circular
                      />
                    </UserAccessComponent>
                    <UserAccessComponent requires="PPORT_PM_HCP_AV_L_DEL">
                      <DeleteConfirmationModal
                        message={t(
                          "Are you sure you want to delete this Availability?"
                        )}
                        onDelete={() => handleDelete(row)}
                        iconName="trash alternate outline"
                        color="red-border"
                        circular
                      />
                    </UserAccessComponent>
                  </div>
                )}
                columns={[
                  {
                    key: "name",
                    name: t("Availability"),
                    filterable: true,
                    sortable: true,
                    filterRenderer: LookupFilter,
                    formatter: LookupFormatter,
                    lookups: availability
                  }
                ]}
                rows={rows}
              />
            </div>
            <div>
              <div className={"gap-btw-grid-buttons"}>
                <UserAccessComponent requires="PPORT_PM_HCP_AV_L_ADD">
                  <Button
                    onClick={() => {
                        setShowDetail(true);
                        setNameValid(rows.map(r => r.name));
                    }}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="fas fa-calendar-alt" />
                    </Icon>
                    {t("New Availability")}
                  </Button>

                  <Button
                    onClick={() => setQuickAddModalOpen(true)}
                    className={"purple"}
                  >
                    <Icon>
                      <i className="fas fa-plus-circle" />
                    </Icon>
                    {t("Quick Add")}
                  </Button>
                </UserAccessComponent>
              </div>
            </div>
            <Modal open={isQuickAddModalOpen} className="dark-modal">
              <Modal.Header className="modal-header">
                {t("Quick Add")}
              </Modal.Header>
              <Modal.Content className="modal-content">
                <DropdownWithSegments
                  name={"name"}
                  fluid
                  search
                  selection
                  label={t("Availability")}
                  onChange={value => {
                    addAvailability(value);
                  }}
                  options={
                    availability
                      ? availability.map(lookupToOptionsTranslation(t))
                      : []
                  }
                  selected={rows.map(av => ({
                    text: lookupCodeToValue(availability, av.name),
                    value: av.name,
                    id: av.id
                  }))}
                  removeIcon={({ option, disabled }) => (
                    <UserAccessComponent requires="PPORT_PM_HCP_AV_L_DEL">
                      <Icon
                        name="delete"
                        style={{ float: "right" }}
                        links
                        onClick={() => {
                          if (option.id) handleDelete(option);
                        }}
                        disabled={disabled}
                      />
                    </UserAccessComponent>
                  )}
                />
              </Modal.Content>
              <Modal.Actions className={"modal-actions"} align={"left"}>
                <Button
                  onClick={() => {
                    setQuickAddModalOpen(false);
                    reset();
                  }}
                  className={"orange"}
                >
                  <Icon name="times circle" />
                  {t("Back")}
                </Button>
              </Modal.Actions>
            </Modal>
          </Fragment>
        ) : (
          <Fragment>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <Dropdown
                  fluid
                  search
                  selection
                  label={t("Availability")}
                  options={availability.map(lookupToOptionsTranslation(t))}
                  name="name"
                  disabled={methods.getValues().id}
                />
                <br />
                <Button
                  onClick={() => {
                    setShowDetail(false);
                    reset();
                  }}
                  color="orange"
                >
                  <Icon name="arrow circle left" />
                  {t("Back")}
                </Button>
                <UserAccessComponent requires="PPORT_PM_HCP_AV_D_SUB">
                  <Button className={"green"} type="submit" disabled={methods.getValues().id}>
                    <Icon name="check circle" />
                    {t("Submit")}
                  </Button>
                </UserAccessComponent>
              </Form>
            </FormProvider>
          </Fragment>
        )}
      </Segment>
    </>
  );
}

export default AvailabilityScreen;
