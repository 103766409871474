import React, { useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import i18n, { DEFAULT_NS } from "../translation/i18n";
import axios from "axios";

function LanguageList({ setLangKey, langKey }) {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchLanguages = async () => {
    try {
      const { data } = await axios.get("/api/translation-language");
      setLanguages(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const fetchTranslations = async (locale) => {
    try {
      const { data } = await axios.get(`/api/translations/${locale}`);
      const translations = data.reduce((acc, cur) => {
        acc[cur.code] = cur.translation;
        return acc;
      }, {});
      i18n.addResourceBundle(locale, DEFAULT_NS, translations);
      i18n.changeLanguage(locale);
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    fetchTranslations(langKey);
  }, [langKey]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <List horizontal divided>
          {languages.map(({ id, locale, description }) => (
            <List.Item key={id} onClick={() => setLangKey(locale)} as="a">
              <List.Content>
                {locale === langKey ? (
                  <List.Header>{description}</List.Header>
                ) : (
                  <List.Description>{description}</List.Description>
                )}
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
    </>
  );
}

export default LanguageList;
