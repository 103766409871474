import { Icon, Menu } from "semantic-ui-react";
import React, { useContext, useEffect } from "react";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserAccessContext } from "../../context";
import UserAccessComponent from "../../components/UserAccessComponent";
import Estimates from "./Estimates";
import LiveRegister from "./LiveRegister";
import Headcount from "./Headcount";
import EYFChildDetailsScreen from "./EYFChildDetailsScreen";
import Redirect from "react-router-dom/es/Redirect";
import Amendment from "./Amendment";
import { FilterProvider } from "../../filters/FilterContext";
import FundSubHistoryScreen from "./FundSubHistoryScreen";
import { INITIAL_FILTERS } from "./FundingUtils";

const tabs = [
  {
    tabName: "Live Register",
    userAccessCode: "PPORT_EYF_FUND_LR",
    urlSlug: "live-register",
    exclamation: "N",
    icon: "fas fa-users-class",
  },
  {
    tabName: "Estimates",
    userAccessCode: "PPORT_EYF_FUND_EST",
    urlSlug: "estimates",
    exclamation: "N",
    icon: "fa-regular fa-calculator-simple",
  },
  {
    tabName: "Headcount",
    userAccessCode: "PPORT_EYF_FUND_HC",
    urlSlug: "headcount",
    exclamation: "N",
    icon: "fa-solid fa-user-group",
  },
  {
    tabName: "Amendment",
    userAccessCode: "PPORT_EYF_FUND_AMD",
    urlSlug: "amendment",
    exclamation: "N",
    icon: "fa-solid fa-pencil",
  },
  {
    tabName: "History",
    userAccessCode: "PPORT_EYF_FUND_HIST",
    urlSlug: "history",
    exclamation: "N",
    icon: "fa-solid fa-clock-rotate-left",
  },
];

const routeMap = {
  'live-register': LiveRegister,
  'estimates': Estimates,
  'headcount': Headcount,
  'amendment': Amendment,
  'history': FundSubHistoryScreen
};

function EYFundingView({ location, match, history }) {
  const { t } = useTranslation();
  const { userGroupAccess } = useContext(UserAccessContext);

  useEffect(() => {
    let urlContainsSlug = false;
    const slugs = [];
    const ugCodes = userGroupAccess.map((ug) => ug.code);

    tabs.forEach((tab) => {
      if (ugCodes.includes(tab.userAccessCode)) slugs.push(tab.urlSlug);
    });

    for (let i = 0; i < slugs.length; i++) {
      if (location.pathname.includes(tabs[i].urlSlug)) {
        urlContainsSlug = true;
      }
    }

    if (!urlContainsSlug) {
      history.push(`${location.pathname}/${slugs.length > 0 ? slugs[0] : ""}`);
    }
  }, []);
  return (
    <>
      <Menu className={"tabs-menu"}>
        {tabs.map((tab, index) => (
          <UserAccessComponent requires={tab.userAccessCode} key={index}>
            <Menu.Item
              key={tab.id}
              as={NavLink}
              activeClassName="active"
              to={`/portal/${match.params.providerId}/provider/provider-funding/${tab.urlSlug}`}
            >
              <Icon>
                <i className={tab.icon} />
              </Icon>
              {t(tab.tabName)}
            </Menu.Item>
          </UserAccessComponent>
        ))}
      </Menu>
      <Switch>
        <Route path={`${match.path}/live-register/:eypChildId`} >
          <EYFChildDetailsScreen tab="live-register"/>
        </Route>
        <Route path={`${match.path}/estimates/:eypChildId`} >
          <EYFChildDetailsScreen tab="estimates"/>
        </Route>
        <Route path={`${match.path}/headcount/:eypChildId`} >
          <EYFChildDetailsScreen tab="headcount" />
        </Route>
        <Route path={`${match.path}/amendment/:eypChildId`} >
          <EYFChildDetailsScreen tab="amendment"/>
        </Route>
        {tabs.map((tab, index) => (
          <Route key={index} path={`${match.path}/${tab.urlSlug}`} exact>
            <FilterProvider initialFilters={INITIAL_FILTERS[tab.urlSlug.toUpperCase()]}>
              {React.createElement(routeMap[tab.urlSlug])}
            </FilterProvider>
          </Route>
        ))}
        <Redirect from={`${match.path}`} to={`${match.path}/live-register`} />
      </Switch>
    </>
  );
}

export default withRouter(EYFundingView);
