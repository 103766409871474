import React from "react";
import { Form, Button, Divider, Message } from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Input } from "@caci/react-form-fields";
import { useTranslation } from "react-i18next";
import backtoschool from "../assets/images/backtoschool.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePostQuery } from "../hooks/request";
import LanguageList from "../components/LanguageList";
import HelpText from "../components/HelpText";

function LoginScreen({ setLangKey, langKey }) {
  const validations = yup.object().shape({
    email: yup
      .string()
      .required("Required")
      .max(500, "Character limit of 500 exceeded"),
    password: yup
      .string()
      .required("Required")
      .max(100, "Character limit of 100 exceeded"),
  });

  const form = useForm({
    resolver: yupResolver(validations),
  });

  const history = useHistory();
  const { t } = useTranslation();

  const [loginQuery, { error, loading: isAuthenticating }] = usePostQuery(
    "/api/login",
    {
      onSuccess: (data) => {
        history.push("/");
      },
    }
  );

  const handleLogin = ({ email, password }) => {
    loginQuery({ login: email, password, langKey });
  };

  return (
    <div className="flex">
      <div className="flex items-center min-h-screen bg-white w-1/3">
        <div className="flex-grow p-16">
          <div className="text-4xl font-bold mb-6">
            {t("Providers Portal")}
          </div>
          <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleLogin)}>
              <Input
                name="email"
                label={t("Login")}
                placeholder={t("Please enter your login...")}
              />
              <Input
                name="password"
                label={t("Password")}
                placeholder={t("Please enter your password...")}
                type="password"
              />
              <Button
                positive
                loading={isAuthenticating}
                disabled={isAuthenticating}
              >
                {t("Login")}
              </Button>
            </Form>
          </FormProvider>
          <div className="mt-6">
            <Link className="underline" to="/public/reset">
              {t("Don't remember your password?")}
            </Link>
          </div>
          <div className="mt-6">
            <Link className="underline" to={"/public/register"}>
              {t("Not registered? Request an account")}
            </Link>
          </div>
          <div className="mt-6">
            <Divider />
            <LanguageList setLangKey={setLangKey} langKey={langKey} />
          </div>
        </div>
      </div>
      <div className="flex items-center bg-purple-100 w-2/3 p-16 min-h-screen">
        <img src={backtoschool} alt="" />
      </div>
    </div>
  );
}

export default LoginScreen;
