import React from "react";
import FilterRenderer from "./FilterRenderer";
import { DropdownFilterInput } from "@caci/react-grid";

const DropdownFilterHeaderRenderer = (props) => {
  const { columnKey, options } = props;

  return (
    <FilterRenderer {...props}>
      {(filters, updateFilter) => (
        <DropdownFilterInput
          options={options}
          value={filters[columnKey]}
          onInputChange={(value) => {
            updateFilter(columnKey, value);
          }}
        />
      )}
    </FilterRenderer>
  );
};

export default DropdownFilterHeaderRenderer;
