import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Modal, Popup, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useLookup from "../../consumer/LookupConsumer";
import axios from "axios";
import {
  DateFilter,
  DateFormatter,
  DefaultFilter,
  Grid as DataGrid,
  LookupFilter,
  LookupFormatter,
} from "@caci/react-grid";
import { ButtonBack } from "@caci/react-form-fields";
import UserAccessComponent from "../../components/UserAccessComponent";
import { lookupCodeToValue, lookupToOptionsTranslation } from "../../utils";
import moment from "moment";
import {isEmpty} from "ramda";

const FundSubHistoryScreen = () => {
  const { t } = useTranslation();
  const { providerId } = useParams();
  const { lookups: eySubmissionType } = useLookup(6200);
  const { lookups: eySubmissionStatus } = useLookup(6201);
  const { lookups: eyPeriodDesc } = useLookup(6202);
  const { lookups: stretchedFundingModel } = useLookup(6237);
  const { lookups: eyAgeRange } = useLookup(6214);

  const [showDetails, setShowDetails] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [eyfConfigPeriodId, setEyfConfigPeriodId] = useState("");
  const [submissionType, setSubmissionType] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [configPeriodList, setConfigPeriodList] = useState([]);
  const [rows, setRows] = useState([]);
  const [historyRows, setHistoryRows] = useState([]);
  const [paymentRatesRows, setPaymentRatesRows] = useState([]);
  const [paymentRatesModalIsOpen, setPaymentRatesModalIsOpen] = useState(false);
  const [supplementsModalModalIsOpen, setSupplementsModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [childId, setChildId] = useState('');
  const [childName, setChildName] = useState('');

  const yesNoOptions = [
    { codeValue: "Y", codeDescription: t("Yes") },
    { codeValue: "N", codeDescription: t("No") },
  ];

  useEffect(() => {
    getConfigPeriodList();
  }, []);

  const clearSearchCriteria = () => {
    setEyfConfigPeriodId("");
    setSubmissionStatus("");
    setSubmissionType("");
    setRows([]);
  };

  const getConfigPeriodList = () => {
    axios
      .get("/api/eyf-config-period-list")
      .then(({ data: configPeriodList }) =>
        setConfigPeriodList(configPeriodList)
      )
      .catch(console.log);
  };

  const getHistoryDetails = (eypFundSubId) => {
    axios
      .get(`/api/eyf-fund-sub-history-list/${eypFundSubId}/${submissionStatus}/${submissionType}`)
      .then(({ data }) => {
        setHistoryRows(data);
        setPaymentRatesRows(data[0].calculatedValues.paymentRates);
      })
      .catch((error) => console.error(error));

    setShowDetails(true);
  };

  const getFundSubs = () => {
    setIsSearching(true);
    axios
      .get(
        `api/eyf-fund-sub-history/eyp-fund-sub-list/${submissionType}/${submissionStatus}/${eyfConfigPeriodId}/${providerId}`
      )
      .then(({ data: rows }) => {
        setRows(rows);
        setIsSearching(false);
      })
      .catch((error) => {
        console.log(error);
        setIsSearching(false);
      });
  };

  const handleDropdownChange = (event, { value, name }) => {
    if (name === "eyfConfigPeriodId") {
      setEyfConfigPeriodId(value);
    } else if (name === "submissionType") {
      setSubmissionType(value);
    } else if (name === "submissionStatus") {
      setSubmissionStatus(value);
    }
  };

  const transformSearchData = (rows) =>
    rows.map((row) => ({
      ...row,
      updatedDate: moment(row.updatedDate).format("YYYY-MM-DD"),
    }));

  const transformData = (rows) =>
    rows.map((row) => ({
      ...row,
      childName: `${row.eypChild.forename || ""} ${
        row.eypChild.middlename || ""
      } ${row.eypChild.surname || ""}`,
      impulseId: row.eypChild.pupilId,
      childId: row.eypChild.childId,
      dob: moment(row.eypChild.dateOfBirth).format("YYYY-MM-DD"),
      stretchedInd: row.eypChildFundSub.stretchedInd,
      expHrsPerWeek: row.eypChildFundSub.expHrsPerWeek,
      uniHrsPerWeek: row.eypChildFundSub.uniHrsPerWeek,
      extHrsPerWeek: row.eypChildFundSub.extHrsPerWeek,
      fundedWeeks: row.eypChildFundSub.fundedWeeks,
      paymentTotal: row.eypChild.paymentTotal,
      fundingAge: row.calculatedValues.fundingAge,
      childFundStart: moment(row.eypChild.childFundStart).format("YYYY-MM-DD"),
      childFundEnd: row.eypChild.childFundEnd? moment(row.eypChild.childFundEnd).format("YYYY-MM-DD") : '',
      modalData: row.paymentSupplements,
      totalFundedHours:
        (row.eypChildFundSub.expHrsPerWeek +
          row.eypChildFundSub.uniHrsPerWeek +
          row.eypChildFundSub.extHrsPerWeek) *
        row.eypChildFundSub.fundedWeeks,
    }));

  return (
    <div>
      <Segment attached="bottom">
        {!showDetails && (
          <Form onSubmit={getFundSubs}>
            <Form.Group widths={6}>
              <Form.Dropdown
                id={"fundingPeriodDropdown"}
                label={t("Funding Period")}
                style={{ maxWidth: 300 }}
                fluid
                selection
                search
                onChange={handleDropdownChange}
                value={eyfConfigPeriodId}
                name="eyfConfigPeriodId"
                options={configPeriodList.map(lookupToOptionsTranslation(t))}
              />
              <Form.Dropdown
                id={"submissionTypeDropdown"}
                label={t("Submission Type")}
                style={{ maxWidth: 300 }}
                fluid
                selection
                search
                onChange={handleDropdownChange}
                value={submissionType}
                name="submissionType"
                options={eySubmissionType.map(lookupToOptionsTranslation(t))}
              />
              <Form.Dropdown
                id={"submissionStatusDropdown"}
                label={t("Status")}
                style={{ maxWidth: 300 }}
                fluid
                selection
                search
                onChange={handleDropdownChange}
                value={submissionStatus}
                name="submissionStatus"
                options={eySubmissionStatus.map(lookupToOptionsTranslation(t))}
              />
              <Form.Group widths={6} style={{ marginTop: "24px" }}>
                <Popup
                  trigger={
                    <Form.Button
                      id={"clearSearchButton"}
                      icon={
                        <Icon>
                          <i
                            className={"fas fa-times-circle"}
                            style={{ color: "red" }}
                          />
                        </Icon>
                      }
                      type="button"
                      disabled={isSearching}
                      loading={isSearching}
                      onClick={() => clearSearchCriteria()}
                    />
                  }
                  content={t("Clear Search")}
                />
                <Popup
                  trigger={
                    <Form.Button
                      id={"searchButton"}
                      icon={
                        <Icon className={"green"}>
                          <i className={"fas fa-search"} />
                        </Icon>
                      }
                      type="submit"
                      disabled={isSearching}
                      loading={isSearching}
                    />
                  }
                  content={t("Search")}
                />
              </Form.Group>
            </Form.Group>
          </Form>
        )}
        {!!showDetails && (
          <Form>
            <Form.Group widths={9}>
              <label>
                <b>{t("Funding Period")}:</b>
              </label>
              <Form.Input
                id={"fundingPeriodLbl"}
                labelPosition="right"
                value={lookupCodeToValue(configPeriodList, eyfConfigPeriodId)}
                transparent
              />
              <label>
                <b>{t("Submission Type")}:</b>
              </label>
              <Form.Input
                id={"submissionTypeLbl"}
                labelPosition="right"
                value={lookupCodeToValue(
                  eySubmissionType,
                  submissionType.toString()
                )}
                transparent
              />
              <label>
                <b>{t("Status")}:</b>
              </label>
              <Form.Input
                id={"submissionStatusLbl"}
                labelPosition="right"
                value={lookupCodeToValue(
                  eySubmissionStatus,
                  submissionStatus.toString()
                )}
                transparent
              />
            </Form.Group>
          </Form>
        )}
      </Segment>
      <Segment attached="bottom">
        {!showDetails && (
          <DataGrid
            id="_HistorySubListGrid"
            actionsCell={({ row }) => (
              <div style={{ textAlign: "center" }}>
                <UserAccessComponent requires="PPORT_EYF_FUND_HIST_VIEW">
                  <Popup
                    trigger={
                      <Icon
                        circular
                        link
                        className={"dark-blue-border"}
                        onClick={() => getHistoryDetails(row.eypFundSubId)}
                      ><i className="fas fa-search"/></Icon>
                    }
                    content={t("View")}
                  />
                </UserAccessComponent>
              </div>
            )}
            columns={[
              {
                key: "periodDescription",
                name: t("Period Description"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: eyPeriodDesc,
              },
              {
                key: "startDate",
                name: t("Start Date"),
                filterable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
                sortable: true,
              },
              {
                key: "endDate",
                name: t("End Date"),
                filterable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
                sortable: true,
              },
              {
                key: "submissionType",
                name: t("Submission Type"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: eySubmissionType,
              },
              {
                key: "submissionStatus",
                name: t("Submission Status"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: eySubmissionStatus,
              },
              {
                key: "updatedDate",
                name: t("Updated Date"),
                filterable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
                sortable: true,
              },
            ]}
            minHeight={550}
            filterable
            rows={transformSearchData(rows)}
          />
        )}
        {!!showDetails && (
          <DataGrid
            id="_HistoryDetailsGrid"
            actionsCell={({ row }) => (
              <div style={{ textAlign: 'center' }}>
                <Popup
                  trigger={
                    <Icon
                      circular
                      name="eye"
                      className={'light-green-border'}
                      disabled={isEmpty(row.modalData)}
                      onClick={() => {
                        setModalData(row.modalData);
                        setChildId(row.childId);
                        setChildName(row.childName);
                        setSupplementsModalIsOpen(true)
                      }}
                    />
                  }
                  content={t('View')}
                />
              </div>
            )}
            columns={[
              {
                key: "impulseId",
                name: t("Impulse ID"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "childId",
                name: t("Child ID"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "childName",
                name: t("Child Name"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "dob",
                name: t("Child DOB"),
                filterable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
                sortable: true,
              },
              {
                key: "fundingAge",
                name: t("Funding Age"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: eyAgeRange,
              },
              {
                key: "stretchedInd",
                name: t("Stretched Claim"),
                filterable: true,
                sortable: true,
                filterRenderer: LookupFilter,
                formatter: LookupFormatter,
                lookups: stretchedFundingModel,
              },
              {
                key: "expHrsPerWeek",
                name: t("Expanded Hours"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "uniHrsPerWeek",
                name: t("Funded Hours"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "extHrsPerWeek",
                name: t("Extended Hours"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "fundedWeeks",
                name: t("Weeks"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "totalFundedHours",
                name: t("Total Funded Hours"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
              {
                key: "childFundStart",
                name: t("Funding Start Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
              },
              {
                key: "childFundEnd",
                name: t("Funding End Date"),
                filterable: true,
                sortable: true,
                filterRenderer: DateFilter,
                formatter: DateFormatter,
              },
              {
                key: "paymentTotal",
                name: t("Payment Total"),
                filterable: true,
                sortable: true,
                filterRenderer: DefaultFilter,
              },
            ]}
            minHeight={550}
            filterable
            rows={transformData(historyRows)}
          />
        )}
        {!!showDetails && (
          <Form>
            <Form.Group>
              <ButtonBack
                id={"backBtn"}
                content={t("Back")}
                onClick={() => {
                  setShowDetails(false);
                  setHistoryRows([]);
                }}
              />
              <Button
                id={"viewPaymentRatesBtn"}
                className={"purple"}
                onClick={() => setPaymentRatesModalIsOpen(true)}
              >
                <Icon>
                  <i className="fas fa-pound-sign"></i>
                </Icon>
                {t("View Payment Rates")}
              </Button>
            </Form.Group>
          </Form>
        )}
        <Modal
          open={paymentRatesModalIsOpen}
          onClose={() => setPaymentRatesModalIsOpen(false)}
          className="dark-modal"
          size="tiny"
        >
          <Modal.Header className="modal-header">
            {t("Payment Rates")}
          </Modal.Header>
          <Modal.Content className="modal-content">
            <DataGrid
              id="_PaymentsModalGrid"
              columns={[
                {
                  key: "fundTypeDescription",
                  name: t("Funding Type"),
                },
                {
                  key: "payAmount",
                  name: t("Rate (£)"),
                },
              ]}
              minHeight={250}
              rows={paymentRatesRows}
            />
          </Modal.Content>
          <Modal.Actions className={"modal-actions"}>
            <div style={{ textAlign: "left" }}>
              <ButtonBack
                id={"modalBackBtn"}
                content={t("Back")}
                onClick={() => setPaymentRatesModalIsOpen(false)}
              />
            </div>
          </Modal.Actions>
        </Modal>
        <Modal
          open={supplementsModalModalIsOpen}
          onClose={() => setSupplementsModalIsOpen(false)}
          className="dark-modal"
          size="tiny"
        >
          <Modal.Header className="modal-header">
            {childId + " - " + childName}
          </Modal.Header>
          <Modal.Content className="modal-content">
            <DataGrid
              id="_SupplementsModalGrid"
              columns={[
                {
                  key: "fundingType",
                  name: t("Funding Type"),
                },
                {
                  key: "payAmount",
                  name: t("Rate (£)"),
                },
              ]}
              minHeight={250}
              rows={modalData}
            />
          </Modal.Content>
          <Modal.Actions className={"modal-actions"}>
            <div style={{ textAlign: "left" }}>
              <ButtonBack
                id={"suppModalBackBtn"}
                content={t("Back")}
                onClick={() => setSupplementsModalIsOpen(false)}
              />
            </div>
          </Modal.Actions>
        </Modal>
      </Segment>
    </div>
  );
};

export default FundSubHistoryScreen;
