import { useEffect, useState } from "react";
import { Grid, Form, Segment, Menu, Icon, Button } from "semantic-ui-react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { getValidationFlag, setValueHelper } from "../utils";
import axios from "axios";
import { useSession } from "../consumer/SessionConsumer";
import { Dropdown, TextArea } from "@caci/react-form-fields";
import RejectCommentsModal from "./RejectCommentsModal";
import UserAccessComponent from "./UserAccessComponent";

const validationFlagsList = ["1", "2", "3"];

function SpecialProvisions({ setValidationFlag, urlSlug }) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [showExclamation, setShowExclamation] = useState(false);
  const { selectedProvider: provider, updateSelectedProvider, syncTrackRecords } = useSession();
  const [saving, setSaving] = useState(false);

  const yesNoOptions = [
    { key: "Y", value: "Y", text: t("Yes") },
    { key: "N", value: "N", text: t("No") }
  ];

  const string500Validation = yup
    .string()
    .max(500, t("500 character limit exceeded"))
    .nullable();

  const schema = yup.object({
    experience: string500Validation,
    details: string500Validation,
    confidence: string500Validation
  });

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (syncTrackRecords && syncTrackRecords.length > 0) {
      const validationFlagIndicator = validationFlagsList.includes(syncTrackRecords[0].validationFlag);
      setValidationFlag(urlSlug, {
        name: "specialProvision",
        value: validationFlagIndicator,
      });
      setShowExclamation(validationFlagIndicator);
    } else if (provider && Object.keys(provider).length > 0) {
      const validationFlagIndicator = validationFlagsList.includes(provider.validationFlag);
      setValidationFlag(urlSlug, {
        name: "specialProvision",
        value: validationFlagIndicator,
      });
      setShowExclamation(validationFlagIndicator);
    }
    setValueHelper(provider, methods.setValue);
  }, [syncTrackRecords, provider]);

  const onSubmit = ({
    providerId,
    validationFlag,
    specialneeds,
    cultureInd,
    dietaryInd,
    wheelchairInd,
    experience,
    details,
    confidence
  }) => {
    const putData = [
      { op: "add", path: "/specialneeds", value: specialneeds },
      { op: "add", path: "/cultureInd", value: cultureInd },
      { op: "add", path: "/dietaryInd", value: dietaryInd },
      { op: "add", path: "/wheelchairInd", value: wheelchairInd },
      { op: "add", path: "/experience", value: experience },
      { op: "add", path: "/details", value: details },
      { op: "add", path: "/confidence", value: confidence },
      {
        op: "add",
        path: "/validationFlag",
        value: getValidationFlag(validationFlag)
      }
    ];

    const putObject = {
      headers: {
        "Content-Type": "application/json-patch+json"
      },
      params: {
        trackId: syncTrackRecords[0]?.id,
        validationFlag: getValidationFlag(validationFlag)
      }
    };

    setSaving(true);

    axios
      .put(`/api/pport-early-provider/${providerId}`, putData, putObject)
      .then(({ data }) => {
        addToast(
          `${t("Special Provision")} ${t("has been successfully updated")}`,
          {
            appearance: "success"
          }
        );
        if (data && Object.keys(data).length > 0) updateSelectedProvider(data);
        setSaving(false);
      })
      .catch(err => {
        console.log(err);
        setSaving(false);
        addToast(`${t("Special Provision")} ${t("has not been updated")}`, {
          appearance: "error"
        });
      });
  };

  return (
    <>
      <Menu attached="top" className={"border-bottom"}>
        <Menu.Item header className={"active"}>
          {t("Special Provision")}
        </Menu.Item>
        <Menu.Menu position="right">
          {showExclamation && (
            <>
              <Menu.Item>
                <UserAccessComponent requires="PPORT_PM_PR_D_EDIT">
                  {
                    syncTrackRecords && syncTrackRecords.length > 0 ?
                      <RejectCommentsModal
                        rejectComments={syncTrackRecords[0].rejectedComments}
                        rejectedDate={syncTrackRecords[0].rejectedDate}
                        rejectedBy={syncTrackRecords[0].rejectedBy}
                        color={"orange"}
                  /> :
                    <RejectCommentsModal
                      rejectComments={provider.rejectComments}
                      rejectedDate={provider.rejectedDate}
                      rejectedBy={provider.rejectedBy}
                      color={"orange"}
                    />
                  }
                </UserAccessComponent>
              </Menu.Item>
              <Menu.Item>
                <Icon color={"red"}>
                  <i className="fas fa-exclamation-triangle" />
                </Icon>
              </Menu.Item>
            </>
          )}
        </Menu.Menu>
      </Menu>
      <Segment attached={"bottom"}>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid stackable>
              <Grid.Row className={"padding-top-0 padding-bottom-0"}>
                <Grid.Column
                  width={8}
                  className={"padding-left-0 padding-right-10"}
                >
                  <Dropdown
                    label={t("Special Needs")}
                    options={yesNoOptions}
                    name="specialneeds"
                  />
                  <Dropdown
                    label={t("Cultural Provision")}
                    options={yesNoOptions}
                    name="cultureInd"
                  />
                  <Dropdown
                    label={t("Dietary Provision")}
                    options={yesNoOptions}
                    name="dietaryInd"
                  />
                  <Dropdown
                    label={t("Wheelchair Access")}
                    options={yesNoOptions}
                    name="wheelchairInd"
                  />
                </Grid.Column>
                <Grid.Column
                  width={8}
                  className={"padding-left-0 padding-right-0"}
                >
                  <TextArea label={t("Experience")} name="experience" />
                  <TextArea label={t("Details")} name="details" />
                  <TextArea label={t("Confidence")} name="confidence" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <UserAccessComponent requires="PPORT_PM_PR_D_SUB">
              <Button className={"green"} type="submit" disabled={saving}>
                <Icon name="check circle" />
                {t("Submit")}
              </Button>
            </UserAccessComponent>
          </Form>
        </FormProvider>
      </Segment>
    </>
  );
}

export default SpecialProvisions;
