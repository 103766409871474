import { Segment } from "semantic-ui-react";
import { useParams, withRouter } from "react-router-dom";
import AssociatedFiles from "../../components/AssociatedFiles";

function EYFChildDocs({ match }) {
  const { eypChildId } = useParams();
  return (
    <div>
      <Segment attached={"bottom"}>
        <AssociatedFiles
          hideMenu
          showNewButton
          getApi={`/api/eyp-child-doc/${match.params.eypChildId}`}
          tableId={match.params.eypChildId}
          providerId={match.params.providerId}
          appendedName="eypChildId"
          tableName="EYP_CHILD_DOC"
          postApi={"/api/eyp-child-doc/upload"}
          downloadAccessCode={"PPORT_EYF_CHILD_DOC_L_DOWN"}
          newUserAccessCode={"PPORT_EYF_CHILD_DOC_L_UP"}
          deleteUserAccessCode={"PPORT_EYF_CHILD_DOC_L_DEL"}
          disabled={eypChildId === "new"}
        />
      </Segment>
    </div>
  );
}

export default withRouter(EYFChildDocs);
