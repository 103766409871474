import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from "react-hook-form";
import {
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, Input } from "@caci/react-form-fields";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import UserAccessComponent from "../../../src/components/UserAccessComponent";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";

const EySendDetailsScreen = (props) => {
  const { t } = useTranslation();
  const [saving, setSaving] = useState(false);
  const [id, setId] = useState();
  const { addToast } = useToasts();
  const { eypChildId } = useParams();
  const { providerId } = useParams();

  const yesNoOptions = [
    { value: 'Y', text: t('Yes') },
    { value: 'N', text: t('No') },
  ];

  const schema = yup.object({
    senInd: yup.string().required(t("Please enter a value"))
  });

  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const getEarlyYearSEN = () => {
    axios
      .get(`api/early-sen-by-childId/${eypChildId}`)
      .then((response) => {
        methods.setValue(`id`, response.data.id);
        props.setEarlySenId(response.data.id);
        methods.setValue(`senInd`, response.data.senInd);
        methods.setValue(`planInd`, response.data.planInd);
        methods.setValue(`agenciesInvolvedInd`, response.data.agenciesInvolvedInd);
        methods.setValue(`enFundInd`, response.data.enFundInd);
        setId(response.data.id);
      })
      .catch(console.log);
  };

  const handleDelete = () => {
    axios.delete(`api/early-sen/${id}`)
      .then(() => {
        getEarlyYearSEN();
        addToast(t(`${t('Early Years SEN')} ${t('has been successfully removed.')}`), {
          appearance: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        addToast(t(`Error occurred. Status: ${error.response.status}`), {
          appearance: "error",
        });
      });
  };

  const onSubmit = ({
                      id,
                      senInd,
                      planInd,
                      agenciesInvolvedInd,
                      enFundInd
                    }) => {
    const data = [
      { op: 'add', path: '/id', value: id },
      { op: 'add', path: '/senInd', value: senInd },
      { op: 'add', path: '/enFundInd', value: enFundInd },
      { op: 'add', path: '/planInd', value: planInd },
      { op: 'add', path: '/agenciesInvolvedInd', value: agenciesInvolvedInd },
      { op: 'add', path: '/providerId', value: providerId },
      { op: 'add', path: '/eypChildId', value: eypChildId },
    ];

    const payload = {
      pupilId: props.pupilId,
      senInd: senInd,
      enFundInd: enFundInd,
      planInd: planInd,
      agenciesInvolvedInd: agenciesInvolvedInd,
      providerId: providerId,
      eypChildId: eypChildId,
    };

    setSaving(true);

    const putObject = {
      headers: {
        'Content-Type': 'application/json-patch+json',
      },
    };

    const postObject = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (id) {
      axios.put(`/api/early-sen/${id}`, data, putObject)
        .then(() => {
          addToast(t(`${t('Early Years SEN')} ${t('has been successfully updated.')}`), {
            appearance: "success",
          });
          setSaving(false);
          getEarlyYearSEN();
        })
        .catch((error) => {
          console.log(error);
          setSaving(false);
        });
    } else {
      axios.post('api/early-sen', payload, postObject)
        .then(() => {
          addToast(t(`${t('Early Years SEN')} ${t('has been successfully created.')}`), {
            appearance: "success",
          });
          setSaving(false);
          getEarlyYearSEN();
        })
        .catch((error) => {
          console.log(error);
          addToast(t(`Error occurred. Status: ${error.response.status}`), {
            appearance: "error",
          });
          setSaving(false);
        });
    }
  };

  useEffect(() => {
    getEarlyYearSEN();
  }, []);

  return (
    <Segment attached="bottom">
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Input
                  id={'eySendId'}
                  name="id"
                  label={t('SEND ID')}
                  readOnly
                />
                <Dropdown
                  id={'eySenInd'}
                  label={t('Identified SEND')}
                  name="senInd"
                  options={yesNoOptions}
                />
                <Dropdown
                  id={'planInd'}
                  label={t('SEND Plan')}
                  name="planInd"
                  options={yesNoOptions}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Dropdown
                  id={'agenciesInvolvedInd'}
                  label={t('Agencies Involved')}
                  name="agenciesInvolvedInd"
                  options={yesNoOptions}
                />
                <Dropdown
                  id={'enFundInd'}
                  label={t('Emergency Needs')}
                  name="enFundInd"
                  options={yesNoOptions}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <UserAccessComponent requires="PPORT_LR_EYS_D_SUB">
            <Button
              id="eyfParentSubmitButton"
              className={"green"}
              type="submit"
              disabled={saving || eypChildId === 'new' || props.restrictForm}
            >
              <Icon name="check circle" />
              {t("Submit")}
            </Button>
          </UserAccessComponent>
          <UserAccessComponent requires="PPORT_LR_EYS_D_DEL">
            <DeleteConfirmationModal
              message={t("Are you sure you want to delete this SEND record?")}
              onDelete={() => handleDelete()}
              title={t("Delete")}
              buttonTitle={t("Delete")}
              buttonColor="red"
              iconName="trash alternate outline"
              color="white"
              disabled={!Boolean(id) || eypChildId === 'new' || saving || props.restrictForm}
              confirmTitle={t('Confirm Delete')}
              textAlign={"right"}
              yes={"Yes"}
              no={"No"}
            />
          </UserAccessComponent>
        </Form>
      </FormProvider>
    </Segment>
  );
};

EySendDetailsScreen.propTypes = {
  setEarlySenId: PropTypes.func,
  restrictForm: PropTypes.bool,
};

export default EySendDetailsScreen;
